abstract class OperationResult<T> {
  value: T

  errorTitle?: string
  errorMessage: string = 'Unknown error'
  statusCode?: number

  constructor(value: T) {
    this.value = value
  }

  get isSuccessful(): boolean {
    return this instanceof OperationSuccess
  }

  get isUnsuccessful(): boolean {
    return !this.isSuccessful
  }

  convertToType<R>(mapper?: (value: T) => R): OperationResult<R> {
    if (this.isSuccessful && mapper) {
      return new OperationSuccess<R>(mapper(this.value))
    } else if (!this.isSuccessful) {
      return new OperationError({
        errorTitle: this.errorTitle,
        errorMessage: this.errorMessage,
      })
    } else {
      throw new Error('Cannot convert OperationSuccess without a mapper function')
    }
  }
}

class OperationSuccess<T> extends OperationResult<T> {
  constructor(value: T) {
    super(value)
  }
}

interface OperationErrorParams {
  errorTitle?: string
  errorMessage?: string
  statusCode?: number
}

class OperationError<T> extends OperationResult<T> {
  constructor({ errorTitle, errorMessage, statusCode }: OperationErrorParams) {
    super((null as unknown) as T)
    this.errorTitle = errorTitle
    if (errorMessage) {
      this.errorMessage = errorMessage
    }
    this.statusCode = statusCode
  }

  static fromError<T>(error: unknown): OperationError<T> {
    let message = 'Unknown error'
    if (error instanceof Error) {
      message = error.message
    }
    return new OperationError({ errorMessage: message })
  }
}

export { OperationResult, OperationSuccess, OperationError }
