import Vue from 'vue'

import autofocus from './autofocus-directive'
Vue.directive('autofocus', autofocus)

import clickOutside from './click-outside-directive'
Vue.directive('click-outside', clickOutside)

import visible from './visible-directive'
Vue.directive('visible', visible)
