import { ActionTree, GetterTree, Module, MutationTree, ModuleTree } from 'vuex'
import { RootState } from '../store'

function traversePrototypeChain(
  object: any,
  untilPrototype: any,
  fn: (name: string, value: any) => void
) {
  for (let o: any = object; o !== untilPrototype; o = Object.getPrototypeOf(o)) {
    for (const name of Object.getOwnPropertyNames(o)) {
      if (name !== 'constructor') {
        fn(name, o[name])
      }
    }
  }
}

export class Mutations<S> {
  toMutationTree(): MutationTree<S> {
    const tree: MutationTree<S> = {}

    traversePrototypeChain(this, Mutations.prototype, (name, value) => {
      if (!tree[name] && typeof value === 'function') {
        tree[name] = value.bind(this)
      }
    })

    return tree
  }
}

export class Actions<S> {
  toActionTree(): ActionTree<S, RootState> {
    const tree: ActionTree<S, RootState> = {}

    traversePrototypeChain(this, Actions.prototype, (name, value) => {
      if (!tree[name] && typeof value === 'function') {
        tree[name] = value.bind(this)
      }
    })

    return tree
  }
}

export class Getters<S> {
  toGetterTree(): GetterTree<S, RootState> {
    const tree: GetterTree<S, RootState> = {}

    traversePrototypeChain(this, Getters.prototype, (name, value) => {
      if (!tree[name] && typeof value === 'function') {
        tree[name] = value.bind(this)
      }
    })

    return tree
  }
}

export interface ModuleTypes {
  mutations?: Record<string, string>
  actions?: Record<string, string>
  getters?: Record<string, string>
}

export class ModuleWrapper<S> {
  state?: S
  mutations?: Mutations<S>
  actions?: Actions<S>
  getters?: Getters<S>
  modules?: { [key: string]: ModuleWrapper<any> }

  toModule(): Module<S, RootState> {
    return {
      namespaced: true,
      state: this.state,
      mutations: this.mutations ? this.mutations.toMutationTree() : void 0,
      actions: this.actions ? this.actions.toActionTree() : void 0,
      getters: this.getters ? this.getters.toGetterTree() : void 0,
      modules: this.mapModules(),
    }
  }

  private mapModules(): ModuleTree<any> | undefined {
    if (this.modules) {
      const result: ModuleTree<any> = {}
      for (const key in this.modules) {
        result[key] = this.modules[key].toModule()
      }
      return result
    }
  }
}
