import Vue from 'vue'
import 'reflect-metadata'

import './misc/polyfills'

import '@/ui/styles/_classes.scss'
import '@/ui/util/detect-popstate'
import '@/ui/config/class-component-hooks'

import App from './App.vue'
import I18n from './i18n/i18n'
import router from './ui/router/router'
import store from './store/store'

import 'pwacompat'

import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/misc/handle-apple-install-prompt'

import '@/data/firebase/firebase-init'

import '@/common/prototype-extensions'
import '@/ui/components/components'
import '@/common/plugins/plugins'
import '@/ui/directives/directives'
import '@/ui/filters/filters'

Vue.config.productionTip = false

if (window.location.hash) {
  window.location.href = window.location.href.split('#')[0]
}

new Vue({
  router,
  store,
  i18n: I18n.vueI18n,
  render: h => h(App),
}).$mount('#root')
