import Vue from 'vue'
import Vuex from 'vuex'

import auth, { AuthState } from './modules/auth.module'
import profile, { ProfileState } from './modules/profile.module'
import layout, { LayoutState } from './modules/layout.module'
import pointImprovementGuide, {
  PointImprovementGuideState,
} from './modules/point-improvement-guide.module'

Vue.use(Vuex)

export interface RootState {
  auth: AuthState
  profile: ProfileState
  layout: LayoutState
  pointImprovementGuide: PointImprovementGuideState
}

const store = new Vuex.Store({
  state: {} as RootState,
  modules: {
    auth: auth.toModule(),
    profile: profile.toModule(),
    layout: layout.toModule(),
    pointImprovementGuide: pointImprovementGuide.toModule(),
  },
})

export default store

pointImprovementGuide.init(store)
