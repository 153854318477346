
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import AppForm from '@/ui/components/form/AppForm.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class QuestionnaireProofOfFunds extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  familySizeRule = (value: string) => {
    const number = Number(value) || 0
    if (number >= 1) {
      return true
    }
    return 'Invalid value: must be at least 1'
  }

  get familySize(): string {
    return this.profile.eligibility.familySize.toString()
  }

  get funds(): string {
    return this.profile.eligibility.funds.toString()
  }

  setFamilySize(familySize: string) {
    this.profile.eligibility.familySize = Number(familySize) || 0
  }

  setFunds(funds: string) {
    this.profile.eligibility.funds = Number(funds) || 0
  }

  submit() {
    const form = this.$refs.form as AppForm
    if (form && form.validate()) {
      this.$emit('submit')
    }
  }
}
