
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import QuestionnaireButton from '../component/QuestionnaireButton.vue'

/**
 * Spouse question
 */
@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireSpouse extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  get hasSpouse(): boolean {
    return this.profile.hasSpouse
  }

  onOptionSelected(hasSpouse: boolean) {
    this.profile.hasSpouse = hasSpouse
    this.$emit('submit')
  }
}
