
import Vue from 'vue'
import Component from 'vue-class-component'
import ListUtils from '@/common/util/list-utils'
import SelectOption from '@/common/interfaces/select-option'
import dayjs, { Dayjs } from 'dayjs'
import { Prop, Watch } from 'vue-property-decorator'
import Utils from '../../common/util/utils'

const ALL_DAYS: SelectOption[] = ListUtils.range(1, 31).map(day => {
  return {
    id: day,
    title: day.toString(),
  }
})

const ALL_MONTHS: SelectOption[] = ListUtils.range(0, 11).map(month => {
  const day = dayjs().month(month)
  return {
    id: month,
    title: day.format('MMMM'),
  }
})

@Component
export default class AppDatePicker extends Vue {
  @Prop()
  value?: Dayjs | null

  @Prop()
  minDate?: Dayjs

  @Prop()
  maxDate?: Dayjs

  day: number | null = null
  month: number | null = null
  year: number | null = null

  private date = dayjs()

  get yearOptions(): SelectOption[] {
    const minYear = this.minDate ? this.minDate.year() : this.date.year() - 10
    const maxYear = this.maxDate ? this.maxDate.year() : this.date.year() + 10
    return ListUtils.range(minYear, maxYear).map(year => {
      return {
        id: year,
        title: year.toString(),
      }
    })
  }

  get monthOptions(): SelectOption[] {
    const minMonth = this.minMonth
    const maxMonth = this.maxMonth
    return maxMonth < 11
      ? ALL_MONTHS.slice(minMonth, maxMonth + 1)
      : minMonth
      ? ALL_MONTHS.slice(minMonth)
      : ALL_MONTHS
  }

  get dayOptions(): SelectOption[] {
    const minDay = this.minDay
    const maxDay = this.maxDay
    return maxDay < 31
      ? ALL_DAYS.slice(minDay - 1, maxDay)
      : minDay
      ? ALL_DAYS.slice(minDay - 1)
      : ALL_DAYS
  }

  private get minMonth(): number {
    if (this.minDate && this.year && this.minDate.year() === this.year) {
      return this.minDate.month()
    }
    return 0
  }

  private get maxMonth(): number {
    if (this.maxDate && this.year && this.maxDate.year() === this.year) {
      return this.maxDate.month()
    }
    return 11
  }

  private get minDay(): number {
    if (
      this.minDate &&
      this.month !== null &&
      this.year &&
      this.minDate.year() === this.year &&
      this.minDate.month() === this.month
    ) {
      return this.minDate.date()
    }
    return 1
  }

  private get maxDay(): number {
    if (
      this.maxDate &&
      this.month !== null &&
      this.year &&
      this.maxDate.year() === this.year &&
      this.maxDate.month() === this.month
    ) {
      return this.maxDate.date()
    }
    return this.daysInMonth
  }

  private get daysInMonth(): number {
    const month = this.month
    const year = this.year
    if (month !== null) {
      let date = dayjs().month(month)
      if (year) {
        date = date.year(year)
      }
      return date.daysInMonth()
    }
    return 31
  }

  mounted() {
    this.onValueChanged()
  }

  @Watch('value')
  private onValueChanged() {
    const value = this.value
    if (value) {
      this.day = value.date()
      this.month = value.month()
      this.year = value.year()
    } else {
      this.day = null
      this.month = null
      this.year = null
    }
  }

  onDayInput(day: number) {
    this.day = day
    this.emitInput()
  }

  onMonthInput(month: number) {
    this.month = month
    this.clampValues()
    this.emitInput()
  }

  onYearInput(year: number) {
    this.year = year
    this.clampValues()
    this.emitInput()
  }

  private clampValues() {
    if (this.month !== null) {
      this.month = Utils.clamp(this.month, this.minMonth, this.maxMonth)
    }

    if (this.day) {
      this.day = Utils.clamp(this.day, this.minDay, this.maxDay)
    }
  }

  private emitInput() {
    const { day, month, year } = this
    if (day !== null && month !== null && year !== null) {
      this.$emit(
        'input',
        dayjs()
          .year(year)
          .month(month)
          .date(day)
      )
    }
  }
}
