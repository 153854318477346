
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class SignInButton extends Vue {
  @Prop({ type: String, required: true })
  iconName!: string

  @Prop({ type: String, required: true })
  label!: string

  isLoading = false

  onClick() {
    if (!this.isLoading) {
      this.isLoading = true
      this.$emit('click')
    }
  }
}
