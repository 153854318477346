import PermissionChecker from '@/data/claims/permission-checker'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { LanguageSkillScore } from '@/data/entity/profile/language-skill-score'
import PointCalculator, { PointCalculatorResult } from '@/data/point-calculator/point-calculator'
import ProfileRepository from '@/data/repository/profile.repository'
import debounce from 'lodash.debounce'
import { LanguageTestScores, Profile, GuideProgress, StatusCenterProgress } from 'shared-entities'
import store from '../store'
import { Actions, Getters, ModuleWrapper, Mutations } from '../util/store-framework'
import StoreUtils from '../util/store-utils'
import Eligibility from '@/data/entity/programs/eligibility.entity'
import EligibilityCalculator from '@/data/eligibility-calculator/eligibility-calculator'
import OperationState from '@/common/util/operation-state'
import { GuideDateUpdate, GuideStepUpdate } from '@/data/entity/guide/guide-update'
import Vue from 'vue'
import { StatusCenterStepUpdate } from '@/data/entity/status-center/status-center-update.entity'

const types = {
  mutations: {
    // Set the state of profile saving operation.
    setSavingState: 'setSavingState',
    setProfile: 'setProfile',
    switchSpouseProfile: 'switchSpouseProfile',

    // Own properties
    setProfileField: 'setProfileField',
    setEligibilityField: 'setEligibilityField',
    setFirstLanguageSkillScore: 'setFirstLanguageSkillScore',
    setSecondLanguageSkillScore: 'setSecondLanguageSkillScore',

    // Spouse properties
    setSpouseField: 'setSpouseField',
    setSpouseLanguageSkillScore: 'setSpouseLanguageSkillScore',

    // Set the state of guide progress saving operation.
    setGuideProgressSavingState: 'setGuideProgressSavingState',
    // Set the date field of a guide.
    setGuideDate: 'setGuideDate',
    // Set the value of a single step (checkbox) item in a guide.
    setGuideStepValue: 'setGuideStepValue',
    // Update the entire GuideProgress object.
    updateGuideProgress: 'updateGuideProgress',

    // Set the state of status center progress saving operation.
    setStatusCenterProgressSavingState: 'setStatusCenterProgressSavingState',
    // Set the value of a single step in the status center checklist.
    setStatusCenterStepValue: 'setStatusCenterStepValue',
    // Update the entire StatusCenterProgress object.
    updateStatusCenterProgress: 'updateStatusCenterProgress',
  },
  actions: {},
  getters: {
    totalPoints: 'totalPoints',

    // Own properties
    getProfileField: 'getProfileField',
    firstLanguageScores: 'firstLanguageScores',
    secondLanguageScores: 'secondLanguageScores',

    // Spouse properties
    getSpouseField: 'getSpouseField',
    spouseLanguageScores: 'spouseLanguageScores',
  },
}

export const ProfileTypes = StoreUtils.exportTypes('profile', types)
const { mutations, actions, getters } = types

export class ProfileState {
  /** The state of saving the main profile */
  savingState: OperationState = 'success'

  /** The current profile object */
  profile = EditableProfile.create()
  /** The current CRS points, corresponding to the profile */
  points: PointCalculatorResult = PointCalculator.calculatePoints(this.profile)
  /** The current eligibility status, corresponding to the profile */
  eligibility: Eligibility = EligibilityCalculator.calculate(this.profile)

  /** The guide progress */
  guideProgress: GuideProgress = {}
  /** The status of saving of the guide progress */
  guideProgressSavingState: OperationState = 'success'

  /** The status center progress */
  statusCenterProgress: StatusCenterProgress = {}
  /** The status of saving the status center progress */
  statusCenterProgressSavingState: OperationState = 'success'
}

let isProfileInitialized = false
// Profile version is used to track the order of profile updates, so that only
// the latest update is recorded. Updates can be out of order if they originate
// on different devices, for example.
let lastProfileVersion = 0

const SAVE_DEBOUNCE_DURATION = 1500

/**
 * Save the profile object in a debounced manner.
 */
const debouncedSaveProfile = debounce(async (state: ProfileState) => {
  if (state.savingState !== 'progress') {
    store.commit(ProfileTypes.mutations.setSavingState, 'progress')
    const canSaveProfile = await PermissionChecker.check('saveProfile')
    if (canSaveProfile) {
      const result = await ProfileRepository.saveProfile(state.profile)
      store.commit(ProfileTypes.mutations.setSavingState, result.isSuccessful ? 'success' : 'error')
    } else {
      store.commit(ProfileTypes.mutations.setSavingState, 'error')
    }
  }
}, SAVE_DEBOUNCE_DURATION)

/**
 * Save the guide progress object in a debounced manner.
 */
const debouncedSaveGuideProgress = debounce(async (state: ProfileState) => {
  if (state.guideProgressSavingState !== 'progress') {
    store.commit(ProfileTypes.mutations.setGuideProgressSavingState, 'progress')

    const result = await ProfileRepository.updateGuideProgress(state.guideProgress)

    store.commit(
      ProfileTypes.mutations.setGuideProgressSavingState,
      result.isSuccessful ? 'success' : 'error'
    )
  }
}, SAVE_DEBOUNCE_DURATION)

const debouncedSaveStatusCenterProgress = debounce(async (state: ProfileState) => {
  if (state.statusCenterProgressSavingState !== 'progress') {
    store.commit(ProfileTypes.mutations.setStatusCenterProgressSavingState, 'progress')
    const result = await ProfileRepository.updateStatusCenterProgress(state.statusCenterProgress)

    store.commit(
      ProfileTypes.mutations.setStatusCenterProgressSavingState,
      result.isSuccessful ? 'success' : 'error'
    )
  }
}, SAVE_DEBOUNCE_DURATION)

function onProfileUpdated(state: ProfileState) {
  state.points = PointCalculator.calculatePoints(state.profile)
  state.eligibility = EligibilityCalculator.calculate(state.profile)
  saveProfile(state)
}

function onEligibilityUpdated(state: ProfileState) {
  state.eligibility = EligibilityCalculator.calculate(state.profile)
  saveProfile(state)
}

function saveProfile(state: ProfileState) {
  if (isProfileInitialized) {
    state.profile.version = incrementProfileVersion()
    debouncedSaveProfile(state)
  }
}

function incrementProfileVersion(): number {
  if (lastProfileVersion >= Number.MAX_SAFE_INTEGER) {
    lastProfileVersion = 1
  } else {
    lastProfileVersion++
  }
  return lastProfileVersion
}

function onGuideProgressUpdated(state: ProfileState, update: GuideStepUpdate | GuideDateUpdate) {
  PermissionChecker.check('guideProgress').then(isPermitted => {
    if (isPermitted) {
      debouncedSaveGuideProgress(state)
    }
  })
}

class ProfileMutations extends Mutations<ProfileState> {
  [mutations.setSavingState](state: ProfileState, savingState: OperationState) {
    state.savingState = savingState
  }
  [mutations.setProfile](state: ProfileState, profile?: Profile) {
    console.debug('Setting profile to', profile ? profile.version : null)
    if (profile) {
      const version = profile.version || 1
      if (version > lastProfileVersion || lastProfileVersion === Number.MAX_SAFE_INTEGER) {
        state.profile.applyProfile(profile)
        onProfileUpdated(state)
        lastProfileVersion = version
      }
    }
    isProfileInitialized = true
  }

  [mutations.switchSpouseProfile](state: ProfileState) {
    state.profile = ProfileRepository.switchMainAndSpouseProfiles(state.profile)
    onProfileUpdated(state)
  }

  [mutations.setProfileField](state: ProfileState, { field, value }: any) {
    const profile = state.profile as any
    profile[field] = value
    onProfileUpdated(state)
  }
  [mutations.setEligibilityField](state: ProfileState, { program, field, value }: any) {
    const profile = state.profile as any
    if (program) {
      profile.eligibility[program][field] = value
    } else {
      profile.eligibility[field] = value
    }
    onEligibilityUpdated(state)
  }

  [mutations.setFirstLanguageSkillScore](state: ProfileState, score: LanguageSkillScore) {
    state.profile.setFirstLanguageSkillScore(score)
    onProfileUpdated(state)
  }
  [mutations.setSecondLanguageSkillScore](state: ProfileState, score: LanguageSkillScore) {
    state.profile.setSecondLanguageSkillScore(score)
    onProfileUpdated(state)
  }

  [mutations.setSpouseField](state: ProfileState, { field, value }: any) {
    const spouse = state.profile.spouse as any
    spouse[field] = value
    onProfileUpdated(state)
  }
  [mutations.setSpouseLanguageSkillScore](state: ProfileState, score: LanguageSkillScore) {
    state.profile.spouse.setFirstLanguageSkillScore(score)
    onProfileUpdated(state)
  }

  [mutations.setGuideProgressSavingState](state: ProfileState, value: OperationState) {
    state.guideProgressSavingState = value
  }

  [mutations.setGuideDate](state: ProfileState, update: GuideDateUpdate) {
    const { guideName, date } = update
    let guideStepProgress = state.guideProgress[guideName]
    if (!guideStepProgress) {
      guideStepProgress = { date: date.toDate(), steps: {} }
      Vue.set(state.guideProgress, guideName, guideStepProgress)
    } else {
      guideStepProgress.date = date.toDate()
    }
    onGuideProgressUpdated(state, update)
  }

  [mutations.setGuideStepValue](state: ProfileState, update: GuideStepUpdate) {
    const { guideName, groupId, stepId, value } = update
    let guideStepProgress = state.guideProgress[guideName]
    if (!guideStepProgress) {
      guideStepProgress = {
        date: null,
        steps: {
          [groupId]: {
            [stepId]: value,
          },
        },
      }
      Vue.set(state.guideProgress, guideName, guideStepProgress)
    } else if (!guideStepProgress.steps[groupId]) {
      Vue.set(guideStepProgress.steps, groupId, {
        [stepId]: value,
      })
    } else {
      Vue.set(guideStepProgress.steps[groupId], stepId, value)
    }
    onGuideProgressUpdated(state, update)
  }

  [mutations.updateGuideProgress](state: ProfileState, progress: GuideProgress) {
    state.guideProgress = progress
  }

  [mutations.setStatusCenterProgressSavingState](state: ProfileState, value: OperationState) {
    state.statusCenterProgressSavingState = value
  }

  [mutations.setStatusCenterStepValue](state: ProfileState, update: StatusCenterStepUpdate) {
    const { stepId, value } = update
    if (state.statusCenterProgress[stepId] !== value) {
      Vue.set(state.statusCenterProgress, stepId, value)
      debouncedSaveStatusCenterProgress(state)
    }
  }

  [mutations.updateStatusCenterProgress](state: ProfileState, progress: StatusCenterProgress) {
    state.statusCenterProgress = progress
  }
}

class ProfileActions extends Actions<ProfileState> {}

class ProfileGetters extends Getters<ProfileState> {
  [getters.totalPoints](state: ProfileState): number {
    if (state.points) {
      return state.points.totalPoints
    } else {
      return 0
    }
  }

  [getters.getProfileField](state: ProfileState) {
    return (field: keyof Profile) => {
      return state.profile[field]
    }
  }

  [getters.firstLanguageScores](state: ProfileState): LanguageTestScores | null {
    return state.profile.getFirstLanguageClbScores()
  }
  [getters.secondLanguageScores](state: ProfileState): LanguageTestScores | null {
    return state.profile.getSecondLanguageClbScores()
  }

  [getters.getSpouseField](state: ProfileState) {
    return (field: keyof Profile) => {
      return state.profile.spouse[field]
    }
  }
  [getters.spouseLanguageScores](state: ProfileState): LanguageTestScores | null {
    return state.profile.spouse.getFirstLanguageClbScores()
  }
}

class ProfileModule extends ModuleWrapper<ProfileState> {
  state = new ProfileState()
  mutations = new ProfileMutations()
  actions = new ProfileActions()
  getters = new ProfileGetters()
}

export default new ProfileModule()
