
import Vue from 'vue'
import Component from 'vue-class-component'
import SvgUtils from '@/ui/util/svg-utils'
import { Prop, Watch } from 'vue-property-decorator'
import ColorUtils from '../util/color-utils'

/**
 * Display an icon from one of the SVG files in src/assets/icons/
 *
 * @param name - name of the icon file, without the .svg extension.
 * @param active - whether the icon color should be set to active (overrides `color` setting)
 * @param color - color of the icon
 * @param size - size of the icon, if width and height should be equal.
 * @param width - width of the icon, overrides `size` setting. If specified, height should
 *      also be specified.
 * @param height - height of the icon, overrides `size` setting. If specified, width
 *      should also be specified.
 */
@Component
export default class AppIcon extends Vue {
  @Prop({ type: String, required: true })
  name!: string
  @Prop({ type: Boolean, default: false })
  accent!: boolean
  @Prop(String)
  color?: string
  @Prop({ type: [String, Number] })
  size?: string | number
  @Prop({ type: [String, Number] })
  width?: string | number
  @Prop({ type: [String, Number] })
  height?: string | number
  @Prop({ type: Boolean, default: false })
  clickable!: boolean
  @Prop({ type: Boolean, default: false })
  inline!: boolean

  svg: string = ''
  viewBox: string | null = null
  viewBoxWidth: string | null = null
  viewBoxHeight: string | null = null

  // eslint-disable-next-line no-undef
  private paths?: NodeListOf<SVGElement>

  private isInitialized = false

  get containerStyle(): any {
    const size = this.size
    const w = this.width
    const h = this.height
    if (w && h) {
      return {
        width: `${w}px`,
        height: `${h}px`,
      }
    } else if (size) {
      return {
        width: `${size}px`,
        height: `${size}px`,
      }
    } else if (this.viewBoxWidth && this.viewBoxHeight) {
      return {
        width: `${this.viewBoxWidth}px`,
        height: `${this.viewBoxHeight}px`,
      }
    } else {
      return {
        width: '24px',
        height: '24px',
      }
    }
  }

  mounted() {
    this.init()
  }

  async init() {
    await SvgUtils.init()

    this.setIcon(this.name)
    this.isInitialized = true
  }

  private setIcon(name: string) {
    const symbol = SvgUtils.getSymbol(this.name)
    this.svg = `<svg viewBox="${symbol.viewBox}" fill="none" preserveAspectRatio="xMidYMid">${symbol.svg}</svg>`
    this.viewBox = symbol.viewBox
    if (this.viewBox) {
      const [_, __, width, height] = this.viewBox.split(' ')
      this.viewBoxWidth = width
      this.viewBoxHeight = height
    }

    this.paths = void 0

    if (this.color) {
      this.$nextTick(() => this.setIconColor(this.color))
    }
  }

  private setIconColor(color?: string) {
    if (!color) {
      return
    }

    let paths = this.paths

    if (!paths || !paths.length) {
      if (!this.getContainer()) {
        return
      }
      paths = this.getContainer().querySelectorAll('path, circle, rect')
      this.paths = paths
    }

    const fill = color ? ColorUtils.hexColor(color) : 'none'

    for (let i = 0, len = paths.length; i < len; i++) {
      paths[i].setAttribute('style', `fill: ${fill}`)
    }
  }

  private getContainer(): Element {
    return this.$refs.container as Element
  }

  @Watch('name')
  private onNameChanged() {
    if (this.isInitialized) {
      this.setIcon(this.name)
    }
  }

  @Watch('color')
  private onColorChanged(color?: string) {
    if (this.isInitialized) {
      this.setIconColor(color)
    }
  }
}
