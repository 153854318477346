/**
 * Defines the IDs for the guide step groups
 */
export enum GuideStepGroupId {
  IrccAccount = 1,
  ExpressEntryProfile = 2,
  PrApplication = 3,
  PrApplicationSupportingDocuments = 4,
  Visa = 5,
  Landing = 6,
  Living = 7,
}
