
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

/**
 * The progress bar shown below each question (except for the very first question)
 */
@Component
export default class QuestionnaireProgressBar extends Vue {
  @Prop({ required: true })
  progress!: number

  goBack() {
    this.$emit('back')
  }
}
