
import Vue from 'vue'
import Component from 'vue-class-component'
import AppForm from '@/ui/components/form/AppForm.vue'
import AuthRepository from '@/data/repository/auth.repository'
import ValidatableField from '../../components/form/mixins/validatable-field.mixin'

import AuthEmailSentView from './AuthEmailSentView.vue'
import FacebookSignInButton from './FacebookSignInButton.vue'
import GoogleSignInButton from './GoogleSignInButton.vue'

@Component({
  components: {
    AuthEmailSentView,
    FacebookSignInButton,
    GoogleSignInButton,
  },
})
export default class AuthForm extends Vue {
  email = ''
  isSendingEmail = false
  isEmailSent = false

  private getForm(): AppForm {
    return this.$refs.form as AppForm
  }

  async continueSignIn() {
    if (this.getForm().validate()) {
      this.sendEmail()
    }
  }

  async sendEmail() {
    this.isSendingEmail = true
    const result = await AuthRepository.sendSignInLinkToEmail(this.email)
    if (result.isSuccessful) {
      this.isEmailSent = true
    } else {
      this.$snackbar.show({ message: result.errorMessage || 'Unknown error' })
    }
    this.isSendingEmail = false
  }
}
