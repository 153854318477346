
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import WorkExpInput from '@/ui/screens/guide/point-calculator/common/WorkExpInput.vue'

@Component({
  components: {
    WorkExpInput,
  },
})
export default class QuestionnaireWorkExperience extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  @Prop({ required: true })
  title!: string

  @Prop({ required: true })
  field!: 'workExp' | 'foreignWorkExp'

  get years(): number {
    return this.profile[this.field]
  }

  onInput(years: number) {
    this.profile[this.field] = years
  }

  submit() {
    this.$emit('submit')
  }
}
