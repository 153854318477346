
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import { Prop, Watch } from 'vue-property-decorator'
import UiUtils from '../util/ui-utils'
import ResponsiveUtils from '../util/responsive-utils'
import AppDropdownDialog from './AppDropdownDialog.vue'

const FANCY_ARROW_HEIGHT = 6
const FANCY_ARROW_WIDTH = 16

@Component
export default class AppDropdown extends Vue {
  @Prop({ type: String })
  label?: string

  @Prop({ type: Array, required: true })
  options!: SelectOption[]

  @Prop({ type: Boolean })
  value?: boolean

  @Prop({ type: [Number, String] })
  selectedId?: number | string

  @Prop({ type: Boolean, default: false })
  nullable!: boolean

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  isOpen: boolean = false

  @Prop({ type: Boolean, default: false })
  rightAligned!: boolean

  @Prop({ type: Boolean, default: false })
  fancy!: boolean

  @Prop({ type: [String, Number], default: 70 })
  minWidth!: string | number

  private menuTop = 0
  private menuMaxHeight = 300

  private menuRight = 0
  private fancyArrowRight = 0

  toggle() {
    if (!this.disabled) {
      if (ResponsiveUtils.isPhone()) {
        this.$dialog.show({
          component: AppDropdownDialog,
          noPadding: true,
          primaryFn: (result: number) => {
            if (result) {
              const option = this.options.find(it => it.id === result)
              if (option) {
                this.selectOption(option)
              }
            } else {
              this.clearSelection()
            }
          },
          props: {
            options: this.options,
            selectedId: this.selectedId,
            title: this.label,
            nullable: this.nullable,
          },
        })
      } else {
        this.setOpen(!this.isOpen)
      }
    }
  }

  private getButton(): HTMLElement | undefined {
    return this.$refs.button as HTMLElement
  }

  private getOptionsContainer(): HTMLElement | undefined {
    return this.$refs.optionsContainer as HTMLElement
  }

  private calculateMenuPosition() {
    const button = this.getButton()
    const optionsContainer = this.getOptionsContainer()
    let top = 0
    let right = 0
    if (button && optionsContainer) {
      const base = button.clientHeight

      // Padding between the menu and the window bottom edge.
      const padding = 8

      const menuHeight = Math.min(optionsContainer.clientHeight, this.menuMaxHeight) + padding
      const distanceToBottom = window.innerHeight - this.$el.getBoundingClientRect().bottom
      const bottomOverflow = menuHeight - distanceToBottom

      top = bottomOverflow > 0 ? base - bottomOverflow : base

      if (this.fancy) {
        const intendedOverflow = (optionsContainer.clientWidth - button.clientWidth) / 2
        const distanceToRight = window.innerWidth - this.$el.getBoundingClientRect().right

        const didOverflow = distanceToRight < intendedOverflow

        this.menuRight = didOverflow ? 0 : -intendedOverflow
        this.fancyArrowRight = button.clientWidth / 2 - FANCY_ARROW_WIDTH / 2 - this.menuRight
      }
    }

    this.menuTop = this.fancy ? top + FANCY_ARROW_HEIGHT : top
  }

  hide() {
    this.setOpen(false)
  }

  get optionsContainerStyle() {
    return {
      top: this.menuTop + 'px',
      right: this.menuRight + 'px',
      minWidth: this.minWidth + 'px',
    }
  }

  get optionsStyle() {
    return {
      maxHeight: this.menuMaxHeight + 'px',
    }
  }

  get fancyArrowStyle() {
    return {
      right: this.fancyArrowRight + 'px',
    }
  }

  selectOption(option: SelectOption) {
    this.$emit('select', option.id)
    this.setOpen(false)
  }

  clearSelection() {
    this.$emit('select', null)
    this.setOpen(false)
  }

  @Watch('value')
  private onValueChanged() {
    this.isOpen = this.value || false
    this.updateMenuPosition()
    if (this.isOpen) {
      this.scrollToSelectedOption()
    }
  }

  private scrollToSelectedOption() {
    if (typeof this.selectedId !== 'undefined') {
      this.$nextTick(() => {
        const selectedIndex = this.options.findIndex(it => it.id === this.selectedId)
        if (selectedIndex >= 0) {
          const options = this.getOptions()
          if (options) {
            UiUtils.scrollToElement(options, selectedIndex)
          }
        }
      })
    }
  }

  private getOptions(): HTMLElement {
    return this.$refs.options as HTMLElement
  }

  private setOpen(value: boolean) {
    this.$emit('input', value)
    if (!this.value) {
      this.isOpen = value
      this.updateMenuPosition()
    }
  }

  private updateMenuPosition() {
    this.$nextTick(() => {
      this.calculateMenuPosition()
    })
  }
}
