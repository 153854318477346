
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SelectOption from '@/common/interfaces/select-option'
import NocExplanation from './NocExplanation.vue'

@Component({
  i18n: {
    messages: {
      en: {
        local: {
          lessThanOneYear: 'None or less than 1 year',
          sixOrMoreYears: '6 or more years',
        },
      },
    },
  },
})
export default class WorkExpInput extends Vue {
  @Prop({ type: String })
  label?: string

  @Prop({ type: Number, required: true })
  value!: number

  @Prop({ type: Boolean, default: false })
  hideLabel!: boolean

  @Prop({ type: Boolean, default: false })
  hideHelp!: boolean

  @Prop({ type: [String, Number] })
  minHeight?: string | number

  options: SelectOption[] = []

  created() {
    this.options = [
      {
        id: 0,
        title: this.$ts('local.lessThanOneYear'),
      },
      ...[1, 2, 3, 4, 5].map(it => ({ id: it, title: this.$tc('plural.year', it, { count: it }) })),
      {
        id: 6,
        title: this.$ts('local.sixOrMoreYears'),
      },
    ]
  }

  showNocInfo() {
    this.$dialog.show({
      component: NocExplanation,
      large: true,
    })
  }
}
