import SelectOption from '@/common/interfaces/select-option'

export default class AgeOptions {
  /**
   * Generate a list of age options to be used in the user's profile.
   */
  static generate(): SelectOption[] {
    const minAge = 17
    const maxAge = 46
    const options: SelectOption[] = []

    options.push({
      id: minAge,
      title: `${minAge} or younger`,
    })

    for (let age = minAge + 1; age <= maxAge; age++) {
      options.push({
        id: age,
        title: age.toString(),
      })
    }

    options.push({
      id: maxAge + 1,
      title: `${maxAge + 1} or older`,
    })

    return options
  }
}
