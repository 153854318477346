
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import QuestionnaireButton from '../component/QuestionnaireButton.vue'

@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireRelatives extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  get title(): string {
    if (this.profile.hasSpouse) {
      return (
        'Do you, or your spouse or common-law partner (if they are coming with you to Canada) ' +
        'have a relative in Canada?'
      )
    } else {
      return 'Do you have a relative in Canada?'
    }
  }

  get hasRelatives(): boolean {
    return this.profile.eligibility.fsw.hasRelativesInCanada
  }

  setHasRelatives(hasRelatives: boolean) {
    this.profile.eligibility.fsw.hasRelativesInCanada = hasRelatives
    if (!hasRelatives) {
      this.profile.hasCanadianSibling = false
    }
    this.$emit('submit')
  }
}
