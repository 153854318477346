import http from '@/data/api/http'
import { OperationError, OperationResult } from '@/data/api/operation-result'
import ResponseParser from '@/data/api/response-parser'
import LocalStorage from '@/data/util/local-storage'
import stripe from '@/data/util/stripe'
import RouteNames from '@/ui/router/route-names'
import router from '@/ui/router/router'
import WindowManager from '@/ui/util/window-manager'
import {
  Plan,
  Subscription,
  PlanUpdatePreview,
  SubscriptionInfo,
  PaymentSession,
} from 'shared-entities'

const LS_KEY_CHECKOUT_SESSION_ID = 'app_checkoutSessionId'

export default class PaymentRepository {
  /**
   * Select a plan for payment. This creates a payment session which is handled
   * by Stripe's checkout flow.
   */
  static async selectPlan(plan: Plan): Promise<OperationResult<PaymentSession>> {
    try {
      const response = await http.post(`/plans/${plan.id}/select`, {
        cancelUrl: window.location.href,
      })
      const result = ResponseParser.parse<PaymentSession>(response)
      if (result.isSuccessful) {
        return this.handlePaymentSession(result.value)
      }

      return result
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  static async handlePaymentSession(session: PaymentSession): Promise<OperationResult<any>> {
    // Disable the check to leave the page.
    WindowManager.disableConfirmTabClose()

    LocalStorage.putItem(LS_KEY_CHECKOUT_SESSION_ID, session.sessionId)
    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    })

    // Redirect failed.
    return new OperationError({ errorMessage: result.error.message })
  }

  /**
   * Cancel the current Customer's active subscription. The customer will retain
   * access to product features until the end of the current billing period and will
   * be able to restore the subscription.
   */
  static async cancelSubscription(): Promise<OperationResult<Subscription>> {
    try {
      const response = await http.post('/subscriptions/cancel')
      return ResponseParser.parse<Subscription>(response)
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * Restore the current Customer's subscription if it was previously cancelled.
   */
  static async restoreSubscription(): Promise<OperationResult<Subscription>> {
    try {
      const response = await http.post('/subscriptions/restore')
      return ResponseParser.parse<Subscription>(response)
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * When upgrading a Subscription, we show the user how much they will be charged
   * before proceeding with charging their default payment method.
   *
   * @param planId The ID of the Plan to which the user wishes to upgrade.
   */
  static async previewSubscriptionUpdate(
    planId: string
  ): Promise<OperationResult<PlanUpdatePreview>> {
    try {
      const response = await http.post('/subscriptions/preview-update', { planId })
      return ResponseParser.parse(response)
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  static async updateSubscription(
    planId: string,
    prorationDate: number
  ): Promise<OperationResult<SubscriptionInfo>> {
    try {
      const response = await http.post('/subscriptions/update', { planId, prorationDate })
      const content = response.data.content
      if (content.object === 'subscription') {
        return ResponseParser.parse(response)
      } else if (content.object === 'paymentSession') {
        return this.handlePaymentSession(content)
      } else {
        return new OperationError({ errorMessage: 'Invalid response type' })
      }
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /** Get the session ID for the most recently created checkout session */
  static getCheckoutSessionId(): string | null {
    return LocalStorage.getItem(LS_KEY_CHECKOUT_SESSION_ID)
  }

  /** Delete the saved session ID */
  static deleteCheckoutSessionId() {
    LocalStorage.removeItem(LS_KEY_CHECKOUT_SESSION_ID)
  }
}
