import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LocalStorage from '../data/util/local-storage'
import authRepository from '@/data/repository/auth.repository'

Vue.use(VueI18n)

const LS_KEY_LANG = 'app_lang'
const html = document.getElementsByTagName('html')[0]

const supportedLocales = { en: 1, ru: 1 }

const loadedLocales: string[] = []

class I18n {
  static vueI18n = new VueI18n({
    silentFallbackWarn: true,
    // silentTranslationWarn: Environment.isProduction(),
    silentTranslationWarn: true,
    messages: {},
  })

  static get currentLocale(): string {
    return this.vueI18n.locale
  }

  /**
   * Initialize the i18n service, with an optional initial locale passed as a parameter.
   * @param locale The initial locale.
   * @returns Promise that is resolved when the local has been initialized.
   */
  static async init(locale: string | null = null): Promise<any> {
    console.debug('Initializing with locale', locale)
    let initLocale: string
    if (locale && this.isLocaleSupported(locale)) {
      initLocale = locale
    } else {
      if (locale) {
        console.error(`Unsupported locale: ${locale}`)
      }
      initLocale = LocalStorage.getItem(LS_KEY_LANG) || process.env.VUE_APP_I18N_LOCALE || 'en'
    }
    return this.setLocale(initLocale)
  }

  static $t(key: string): string {
    return this.vueI18n.t(key) as string
  }

  static $tc(key: string, count: number, values: { [key: string]: any }): string {
    return this.vueI18n.tc(key, count, values)
  }

  private static isLocaleSupported(locale: string): boolean {
    return locale in supportedLocales
  }

  static async setLocale(locale: string): Promise<any> {
    if (!this.isLocaleSupported(locale)) {
      return Promise.reject(`Locale ${locale} is not supported`)
    }

    if (this.vueI18n.locale === locale) {
      return this.doSetLocale(locale)
    }

    if (loadedLocales.includes(locale)) {
      return this.doSetLocale(locale)
    }

    const messages: any = await import(
      /* webpackChunkName: "chunk-lang-[request]" */ `./locales/${locale}.js`
    )
    loadedLocales.push(locale)
    this.vueI18n.setLocaleMessage(locale, messages.default)
    this.doSetLocale(locale)
  }

  private static doSetLocale(locale: string) {
    this.vueI18n.locale = locale
    html.setAttribute('lang', locale)
    LocalStorage.putItem(LS_KEY_LANG, locale)
    authRepository.setLocale(locale)
  }
}

export default I18n
