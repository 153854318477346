
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { AuthTypes } from '@/store/modules/auth.module'
import PermissionChecker from '@/data/claims/permission-checker'
import { Permission } from 'shared-entities'

/**
 * Shows the content of this component if the current user has the specified permission.
 *
 * Slots:
 * default - the content of this component.
 */
@Component
export default class AppPermissionWrapper extends Vue {
  /** The permission to check */
  @Prop({ type: String, required: true })
  permission!: Permission

  /**
   * If true, then the check is inverted. That is, the content shown if the user
   * DOES NOT have the specified permission.
   */
  @Prop({ type: Boolean, default: false })
  inverted!: boolean

  isVisible = false

  mounted() {
    this.updateVisibility()
  }

  get customer() {
    return this.$store.getters[AuthTypes.getters.customer]
  }

  private async updateVisibility() {
    const hasPermission = await PermissionChecker.check(this.permission)
    this.isVisible = this.inverted ? !hasPermission : hasPermission
  }

  @Watch('customer')
  private onCustomerChanged() {
    this.updateVisibility()
  }
}
