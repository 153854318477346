
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import { Watch } from 'vue-property-decorator'
import OperationState from '@/common/util/operation-state'
import Utils from '@/common/util/utils'

/**
 * Indicator showing whether the profile is being saved or already saved.
 */
@Component
export default class ProfileSavingIndicator extends Vue {
  isVisible = false

  private hideTimeout: number | null = null

  private get savingState(): OperationState {
    const {
      savingState,
      guideProgressSavingState,
      statusCenterProgressSavingState,
    } = store.state.profile

    const states = [savingState, guideProgressSavingState, statusCenterProgressSavingState]

    if (states.includes('progress')) {
      return 'progress'
    } else if (states.includes('error')) {
      return 'error'
    } else {
      return 'success'
    }
  }

  get label(): string {
    switch (this.savingState) {
      case 'progress':
        return 'Saving'
      case 'success':
        return 'Saved'
      default:
        return 'Error'
    }
  }

  private get isSaving(): boolean {
    return this.savingState === 'progress'
  }

  private get hasError(): boolean {
    return this.savingState === 'error'
  }

  @Watch('isSaving')
  private onIsSavingChanged() {
    if (!this.isSaving) {
      this.hideTimeout = setTimeout(() => {
        this.isVisible = false
      }, 3000)
    } else {
      this.isVisible = true
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
        this.hideTimeout = null
      }
    }
  }
}
