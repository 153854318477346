
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import QuestionnaireButton from '../component/QuestionnaireButton.vue'

@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireSibling extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  get title(): string {
    if (this.profile.hasSpouse) {
      return (
        'Do you or your spouse or common-law partner have a' +
        'brother or sister living in Canada who is a citizen or permanent resident of Canada?'
      )
    } else {
      return (
        'Do you have a brother or sister living in Canada who is a citizen or permanent ' +
        'resident of Canada?'
      )
    }
  }

  get hasSibling(): boolean {
    return this.profile.hasCanadianSibling
  }

  setHasSibling(hasSibling: boolean) {
    this.profile.hasCanadianSibling = hasSibling
    this.$emit('submit')
  }
}
