
import PointCategory from '@/data/point-calculator/point-category'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class QuestionnaireResultPointsCard extends Vue {
  @Prop({ required: true })
  category!: PointCategory

  @Prop({ required: true })
  color!: string

  get style() {
    return {
      borderColor: this.color,
    }
  }

  get titleStyle() {
    return {
      borderColor: this.color,
      backgroundColor: this.color,
    }
  }

  get maxPointsStyle() {
    return {
      color: this.color,
    }
  }
}
