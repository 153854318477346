import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCategory from '@/data/point-calculator/point-category'
import { LanguageTest } from '@/data/entity/profile/language-test'
import OfficialLanguage from '@/data/entity/profile/official-language'
import { LanguageTestScoresUtils, NocGroup, EducationLevel } from 'shared-entities'

interface ICategoryD {
  points: number
  frenchBonus: PointCategory
}

export class CategoryD implements PointCategory {
  readonly title = 'D'
  readonly description = 'category.d'

  readonly points: number
  readonly maxPoints: number = 600 // Hard cap

  readonly frenchBonus: PointCategory

  constructor(values: ICategoryD) {
    this.points = Math.min(this.maxPoints, values.points)
    this.frenchBonus = values.frenchBonus
  }
}

export default class PointCalculatorCategoryD {
  static calculate(profile: EditableProfile): CategoryD {
    let points = 0
    if (profile.hasCanadianSibling) {
      points += 15
    }
    if (profile.education) {
      if (profile.education >= EducationLevel.BACHELORS_DEGREE && profile.canadianEducation >= 3) {
        points += 30
      } else if (
        profile.education >= EducationLevel.ONE_YEAR_DEGREE &&
        profile.canadianEducation >= 1
      ) {
        points += 15
      }
    }

    const jobOffer = profile.jobOffer
    if (jobOffer) {
      if (jobOffer === NocGroup.NOC_00) {
        points += 200
      } else if (
        jobOffer === NocGroup.NOC_0 ||
        jobOffer === NocGroup.NOC_A ||
        jobOffer === NocGroup.NOC_B
      ) {
        points += 50
      }
    }
    if (profile.hasProvNomination) {
      points += 600
    }

    const frenchBonus = this.computeFrenchBonusScores(profile)
    points += frenchBonus.points

    return new CategoryD({ points, frenchBonus })
  }

  private static computeFrenchBonusScores(profile: EditableProfile): PointCategory {
    let points = 0

    let frenchTest: LanguageTest | null = null
    let englishTest: LanguageTest | null = null
    const firstLanguageTest = profile.firstLanguageTest
    const secondLanguageTest = profile.secondLanguageTest
    if (firstLanguageTest && firstLanguageTest.language === OfficialLanguage.FRENCH) {
      frenchTest = profile.firstLanguageTest
      if (secondLanguageTest) {
        englishTest = secondLanguageTest
      }
    } else if (secondLanguageTest && secondLanguageTest.language == OfficialLanguage.FRENCH) {
      frenchTest = secondLanguageTest
      if (firstLanguageTest) {
        englishTest = firstLanguageTest
      }
    }

    if (frenchTest) {
      const frenchScores = profile.langLevel[frenchTest.type]
      if (frenchScores && LanguageTestScoresUtils.everySkill(frenchScores, score => score >= 7)) {
        if (englishTest) {
          const englishScores = profile.langLevel[englishTest.type]
          if (
            englishScores &&
            LanguageTestScoresUtils.everySkill(englishScores, score => score >= 5)
          ) {
            points = 50
          } else {
            points = 25
          }
        } else {
          points = 25
        }
      }
    }

    return { points, maxPoints: 50 }
  }
}
