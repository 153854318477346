
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { TheMask } from 'vue-the-mask'
import ValidatableField from './mixins/validatable-field.mixin'
import debounce from 'lodash/debounce'

let lastInputIdSuffix = 0

@Component({
  components: {
    TheMask,
  },
})
export default class AppInput extends mixins(ValidatableField) {
  @Prop({ type: String, default: '' })
  value!: string | number

  @Prop(String) label?: string
  @Prop(String) hint?: string
  @Prop(String) type?: string
  @Prop(String) autocapitalize?: string
  @Prop({ type: Boolean, default: false })
  disabled!: boolean
  @Prop({ type: Boolean, default: false })
  isPhone!: boolean
  @Prop({ type: Boolean, default: false })
  autofocus!: boolean
  @Prop(String) prefixIcon?: string

  /**
   * If the type is 'number', then restrict the value only to integers.
   */
  @Prop({ type: Boolean, default: false })
  integer!: boolean

  @Prop({ type: Boolean, default: false })
  smallLabel!: boolean

  inputId: string = `app-input-${++lastInputIdSuffix}`

  private debouncedValidate = debounce(this.validate, 100)

  created() {
    if (this.isPhone) {
      this.rules.push('phoneNumber')
    }
  }

  mounted() {
    if (this.autofocus && this.isPhone) {
      this.$nextTick(() => {
        const component = this.$refs.phoneInput as Vue
        const input = component.$el as HTMLElement
        input.focus()
      })
    }
  }

  get inputType(): string {
    return this.type || 'text'
  }

  onKeyPress(event: any) {
    if (this.type === 'number') {
      const code = event.which || event.keyCode
      if (code === 46 && this.integer) {
        return event.preventDefault()
      }
    }
  }

  onInput(event: any) {
    event.preventDefault()
    event.stopImmediatePropagation()
    if (this.isPhone) {
      this.$emit('input', '7' + event)
    } else if (event && event.target) {
      this.$emit('input', event.target.value)
    }

    if (this.shouldValidate) {
      // Use a debounced function so that a mask can be applied properly on $nextTick
      this.debouncedValidate()
    }

    this.$forceUpdate()
  }
}
