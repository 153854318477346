
import User from '@/data/entity/user.entity'
import LocalStorage from '@/data/util/local-storage'
import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import AppUtils from '@/ui/util/app-utils'
import { Permission } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

const LS_KEY_CURRENT_STEP = 'app_currentGuideStep'

interface Item {
  title: string
  routeName?: string
  permission?: Permission
  iconName?: string
  label?: string
}

const ALL_ITEMS: Item[] = [
  { title: 'label.statusCenter', routeName: RouteNames.GUIDE_STATUS_CENTER, iconName: 'list' },
  { title: 'label.eligibility', routeName: RouteNames.GUIDE_ELIGIBILITY, label: '1' },
  { title: 'label.pool', routeName: RouteNames.GUIDE_POOL, permission: 'poolGuide', label: '2' },
  { title: 'label.ita', routeName: RouteNames.GUIDE_ITA, permission: 'itaGuide', label: '3' },
  { title: 'label.pr', routeName: RouteNames.GUIDE_PR, permission: 'prGuide', label: '4' },
]

@Component
export default class GuideSteps extends Vue {
  items: Item[] = ALL_ITEMS

  activeRouteName: string | null = null

  mounted() {
    this.activeRouteName = this.$route.name || null

    if (!this.restoreSavedStep()) {
      this.redirectFromRoot()
    }
  }

  showHelp() {
    AppUtils.showHelp(this)
  }

  selectItem(item: Item) {
    if (item.routeName && this.$route.name !== item.routeName) {
      this.$router.push({ name: item.routeName })
    }
  }

  get user(): User | null {
    return store.state.auth.user
  }

  private redirectFromRoot() {
    if (this.$router.currentRoute.name === RouteNames.GUIDE) {
      this.$router.replace({ name: RouteNames.GUIDE_STATUS_CENTER })
    }
  }

  private restoreSavedStep(): boolean {
    const savedRouteName = LocalStorage.getItem(LS_KEY_CURRENT_STEP) as string
    if (savedRouteName && this.$route.name !== savedRouteName) {
      const item = this.items.find(it => it.routeName === savedRouteName)
      if (item) {
        this.$router.replace({ name: savedRouteName })
        return true
      }
    }
    return false
  }

  private updateSavedStep() {
    const routeName = this.$route.name
    if (routeName && this.items.find(it => it.routeName === routeName)) {
      LocalStorage.putItem(LS_KEY_CURRENT_STEP, this.$route.name)
    }
  }

  @Watch('$route')
  private onRouteChanged() {
    this.activeRouteName = this.$route.name || null

    this.updateSavedStep()
    this.redirectFromRoot()
  }
}
