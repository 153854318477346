import { ClassType } from 'class-transformer/ClassTransformer'
import { plainToClass, classToPlain } from 'class-transformer'

/** Local storage wrapper */
export default class LocalStorage {
  static getItem(key: string, defaultItem?: any): any | null {
    const stringValue = localStorage.getItem(key)
    if (stringValue) {
      try {
        return JSON.parse(stringValue)
      } catch (error) {
        return stringValue
      }
    } else {
      return defaultItem || null
    }
  }

  static getBoolean(key: string, defaultValue?: boolean): boolean {
    return !!this.getItem(key, defaultValue)
  }

  static putItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static getObject<T>(key: string, cls: ClassType<T>, defaultValue?: T): T | null {
    const plain = this.getItem(key)
    if (plain) {
      return plainToClass(cls, plain)
    } else {
      return defaultValue || null
    }
  }

  static putObject<T>(key: string, object: T) {
    this.putItem(key, classToPlain(object))
  }

  static removeItem(key: string) {
    localStorage.removeItem(key)
  }
}
