import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import AgePointCalculator from '@/data/point-calculator/categories/core/age-point-calculator'
import EducationPointCalculator from '@/data/point-calculator/categories/core/education-point-calculator'
import LanguagePointCalculator from '@/data/point-calculator/categories/core/language-point-calculator'
import PointCategory from '@/data/point-calculator/point-category'
import WorkExperiencePointCalculator from '@/data/point-calculator/categories/core/work-experience-point-calculator'

interface ICategoryA {
  age: PointCategory
  education: PointCategory
  language: PointCategory
  workExp: PointCategory
}

export class CategoryA implements PointCategory {
  readonly title = 'A'
  readonly description = 'category.a'

  readonly points: number
  readonly maxPoints: number

  readonly age: PointCategory
  readonly education: PointCategory
  readonly language: PointCategory
  readonly workExp: PointCategory

  constructor(values: ICategoryA) {
    this.age = values.age
    this.education = values.education
    this.language = values.language
    this.workExp = values.workExp

    this.points = this.calculateTotal()
    this.maxPoints = this.calculateMaxPoints()
  }

  private calculateTotal(): number {
    return this.age.points + this.education.points + this.language.points + this.workExp.points
  }

  private calculateMaxPoints(): number {
    return (
      this.age.maxPoints +
      this.education.maxPoints +
      this.language.maxPoints +
      this.workExp.maxPoints
    )
  }
}

export default class PointCalculatorCategoryA {
  static calculate(profile: EditableProfile): CategoryA {
    return new CategoryA({
      age: AgePointCalculator.calculate(profile),
      education: EducationPointCalculator.calculate(profile),
      language: LanguagePointCalculator.calculate(profile),
      workExp: WorkExperiencePointCalculator.calculate(profile),
    })
  }
}
