import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { LanguageTest } from '@/data/entity/profile/language-test'
import PointCategory from '@/data/point-calculator/point-category'
import { LANGUAGE_SKILLS } from 'shared-entities'

export default class LanguagePointCalculator {
  static calculate(profile: EditableProfile): PointCategory {
    return {
      title: 'label.language',
      points: this.points(profile),
      maxPoints: this.maxPoints(profile),
    }
  }

  static calculateForSpouse(profile: EditableProfile): PointCategory {
    return {
      title: 'label.language',
      points: this.pointsForSpouse(profile),
      maxPoints: this.maxPointsForSpouse(profile),
    }
  }

  private static points(profile: EditableProfile): number {
    let points = this.computePointsForLanguageTest(
      profile,
      profile.firstLanguageTest,
      FIRST_LANGUAGE_SCORES
    )

    const secondLanguagePoints = this.computePointsForLanguageTest(
      profile,
      profile.secondLanguageTest,
      SECOND_LANGUAGE_SCORES
    )

    if (profile.hasSpouse) {
      points += Math.min(22, secondLanguagePoints)
    } else {
      points += secondLanguagePoints
    }

    return points
  }

  private static maxPoints(profile: EditableProfile): number {
    return profile.hasSpouse ? 150 : 160
  }

  private static pointsForSpouse(profile: EditableProfile): number {
    if (profile.hasSpouse) {
      return this.computePointsForLanguageTest(
        profile.spouse,
        profile.spouse.firstLanguageTest,
        SPOUSE_LANGUAGE_SCORES
      )
    }
    return 0
  }

  private static maxPointsForSpouse(profile: EditableProfile): number {
    return profile.hasSpouse ? 20 : 0
  }

  private static computePointsForLanguageTest(
    profile: EditableProfile,
    test: LanguageTest | null,
    scoreList: LanguageProficiencyScore[]
  ) {
    let points = 0
    if (test && test.type !== 'NONE') {
      const scores = profile.langLevel[test.type]
      if (scores) {
        for (let i = 0; i < LANGUAGE_SKILLS.length; i++) {
          const score = scores[LANGUAGE_SKILLS[i]]
          points += this.languageSkillScore(score, profile, scoreList)
        }
      }
    }
    return points
  }

  private static languageSkillScore(
    clbScore: number,
    profile: EditableProfile,
    scoreList: LanguageProficiencyScore[]
  ) {
    let currentScore: LanguageProficiencyScore | null = null
    for (let i = 0; i < scoreList.length; i++) {
      const score = scoreList[i]
      if (clbScore < score.minCLB) {
        break
      }
      currentScore = score
    }

    if (currentScore) {
      return profile.hasSpouse ? currentScore.pointsWithSpouse : currentScore.pointsWithoutSpouse
    }
    return 0
  }
}

export interface LanguageProficiencyScore {
  minCLB: number
  pointsWithSpouse: number
  pointsWithoutSpouse: number
}

export const FIRST_LANGUAGE_SCORES: LanguageProficiencyScore[] = [
  { minCLB: 4, pointsWithSpouse: 6, pointsWithoutSpouse: 6 },
  { minCLB: 6, pointsWithSpouse: 8, pointsWithoutSpouse: 9 },
  { minCLB: 7, pointsWithSpouse: 16, pointsWithoutSpouse: 17 },
  { minCLB: 8, pointsWithSpouse: 22, pointsWithoutSpouse: 23 },
  { minCLB: 9, pointsWithSpouse: 29, pointsWithoutSpouse: 31 },
  { minCLB: 10, pointsWithSpouse: 32, pointsWithoutSpouse: 34 },
]

export const SECOND_LANGUAGE_SCORES: LanguageProficiencyScore[] = [
  { minCLB: 5, pointsWithSpouse: 1, pointsWithoutSpouse: 1 },
  { minCLB: 7, pointsWithSpouse: 3, pointsWithoutSpouse: 3 },
  { minCLB: 9, pointsWithSpouse: 6, pointsWithoutSpouse: 6 },
]

// Points without spouse are not applicable
export const SPOUSE_LANGUAGE_SCORES: LanguageProficiencyScore[] = [
  { minCLB: 5, pointsWithSpouse: 1, pointsWithoutSpouse: 0 },
  { minCLB: 7, pointsWithSpouse: 3, pointsWithoutSpouse: 0 },
  { minCLB: 9, pointsWithSpouse: 5, pointsWithoutSpouse: 0 },
]
