
import FormattingUtils from '@/common/util/formatting-utils'
import { DiscountUtils, OnboardingCouponConfig } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

/**
 * Main block of the QuestionnairePaymentScreen
 *
 * Events:
 * purchase-plan: fired when the user presses the Receive plan button.
 */
@Component
export default class QuestionnairePaymentMain extends Vue {
  @Prop({ type: Boolean, required: true })
  isTimerComplete!: boolean

  @Prop({ type: Boolean, default: false })
  isPurchasingPlan!: boolean

  @Prop({ type: Boolean, default: false })
  isLoadingCouponConfig!: boolean

  @Prop()
  couponConfig: OnboardingCouponConfig | null = null

  private get basePrice(): string | null {
    if (this.couponConfig) {
      const plan = this.couponConfig.plan
      return FormattingUtils.formatAmount(plan.price)
    }
    return null
  }

  private get discountedPrice(): string | null {
    if (this.couponConfig && this.couponConfig.coupon) {
      const basePrice = this.couponConfig.plan.price
      const price = DiscountUtils.calculateDiscountedPrice(
        basePrice,
        this.couponConfig.coupon.percentOff
      )

      return FormattingUtils.formatAmount(price)
    }
    return null
  }

  get couponExists(): boolean {
    return !!(this.couponConfig && this.couponConfig.coupon)
  }

  get oldPrice(): string | null {
    return this.isTimerComplete ? this.discountedPrice : this.basePrice
  }

  get newPrice(): string | null {
    return this.isTimerComplete ? this.basePrice : this.discountedPrice
  }

  purchasePlan() {
    this.$emit('purchase-plan')
  }
}
