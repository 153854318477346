import RouteNames from '@/ui/router/route-names'
import router, { hasPermissionForRoute } from './router'
import LocalStorage from '@/data/util/local-storage'
import QuestionnaireUtils from '../screens/questionnaire/questionnaire-utils'
import store from '@/store/store'
import { AuthTypes } from '@/store/modules/auth.module'

const LS_KEY_RETURN_ROUTE_NAME = 'app_returnRouteName'

/**
 * Helper functions used for navigation.
 */
export default class RouteHelper {
  /**
   * If the current URL is just '/', then execute appropriate redirect logic.
   */
  static redirectRootIfNeeded() {
    if (window.location.pathname === '/') {
      const isSignedIn = store.getters[AuthTypes.getters.isSignedIn]
      if (isSignedIn && QuestionnaireUtils.isQuestionnaireResultExpected()) {
        router.replace({ name: RouteNames.QUESTIONNAIRE_RESULTS })
      } else {
        if (isSignedIn && store.getters[AuthTypes.getters.hasSubscription]) {
          RouteHelper.default()
        } else {
          RouteHelper.navigateToQuestionnaire()
        }
      }
    }
  }

  /**
   * Navigate to the default route.
   */
  static default() {
    let routeName: string
    if (store.getters[AuthTypes.getters.isSignedIn]) {
      if (store.getters[AuthTypes.getters.hasSubscription]) {
        routeName = RouteNames.GUIDE_STATUS_CENTER
      } else {
        routeName = RouteNames.PROFILE
      }
    } else {
      routeName = RouteNames.QUESTIONNAIRE
    }

    if (router.currentRoute.name !== routeName) {
      router.replace({ name: routeName })
    }
  }

  /**
   * Navigate to the login screen.
   * @param returnRouteName The route name to which navigation state should return to after
   *    successful authentication.
   */
  static navigateToAuth(returnRouteName?: string | null) {
    if (router.currentRoute.name !== RouteNames.SIGN_IN) {
      if (returnRouteName) {
        LocalStorage.putItem(LS_KEY_RETURN_ROUTE_NAME, returnRouteName)
      }
      router.push({ name: RouteNames.SIGN_IN })
    }
  }

  /**
   * Navigate to the questionnaire screen.
   */
  static navigateToQuestionnaire() {
    if (router.currentRoute.name !== RouteNames.QUESTIONNAIRE) {
      router.replace({ name: RouteNames.QUESTIONNAIRE })
    }
  }

  /**
   * Navigate to the saved return route, saved in [navigateToAuth], if possible.
   * @returns true if the saved route was found and navigation was performed.
   */
  static returnToSavedRouteIfPossible(): boolean {
    const routeName = LocalStorage.getItem(LS_KEY_RETURN_ROUTE_NAME)
    if (routeName) {
      LocalStorage.removeItem(LS_KEY_RETURN_ROUTE_NAME)
      router.push({ name: routeName })
      return true
    }
    return false
  }

  /**
   * Return true if the current user has permission to access the current route.
   */
  static hasPermissionForCurrentRoute(): Promise<boolean> {
    return hasPermissionForRoute(router.currentRoute)
  }
}
