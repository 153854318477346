import { LANGUAGE_SKILLS } from './language-skill'
import { LanguageTestScores } from './language-test-scores'

export class LanguageTestScoresUtils {
  static create(): LanguageTestScores {
    return {
      r: 0,
      w: 0,
      s: 0,
      l: 0,
    }
  }

  /**
   * Return true if every score satisfies the given predicate.
   */
  static everySkill(scores: LanguageTestScores, predicate: (score: number) => boolean): boolean {
    for (let i = 0; i < LANGUAGE_SKILLS.length; i++) {
      if (!predicate(scores[LANGUAGE_SKILLS[i]])) {
        return false
      }
    }
    return true
  }
}
