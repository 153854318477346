
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Provide } from 'vue-property-decorator'

export interface AppTabSpec {
  id: string
  title: string
  subtitle?: string
  [key: string]: any
}

@Component
export default class AppTabs extends Vue {
  @Prop({ type: String, required: true })
  activeTabId!: string

  @Prop({ type: Array, required: true })
  tabs!: AppTabSpec[]
}
