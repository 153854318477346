
import Vue from 'vue'
import Component from 'vue-class-component'

interface FaqItem {
  id: number
  question: string
  answer: string
}

/**
 * The FAQ section on the questionnaire payment screen.
 */
@Component
export default class QuestionnairePaymentFaq extends Vue {
  faqItems: FaqItem[] = [
    {
      id: 1,
      question: 'Why do I need an immigration plan?',
      answer:
        'To save your time and money you need an immigration plan. Organized approach ' +
        'will increase your chances to get your PR as fast as possible.',
    },
    {
      id: 2,
      question: 'How long will I have access to my plan?',
      answer: 'One month or one year. Most users choose the yearly plan.',
    },
    {
      id: 3,
      question: 'How long till I receive my plan?',
      answer: 'You will receive it immediately after you subscribe to the plan.',
    },
  ]

  expandedItem: number | null = null

  expandItem(item: FaqItem) {
    if (this.expandedItem == item.id) {
      this.expandedItem = null
    } else {
      this.expandedItem = item.id
    }
  }

  isExpanded(item: FaqItem): boolean {
    return this.expandedItem === item.id
  }
}
