import store from '@/store/store'
import isNil from 'lodash/isNil'

const navigator: any = window.navigator

const isIosOnBrowser =
  ['iPhone', 'iPad', 'iPod'].includes(navigator.platform) && !navigator.standalone

if (isIosOnBrowser) {
  const now = Date.now()
  let limitDate = null
  const addToHomeIosPromptLastDate = localStorage.getItem('addToHomeIosPromptLastDate')

  if (!isNil(addToHomeIosPromptLastDate)) {
    limitDate = new Date(parseInt(addToHomeIosPromptLastDate, 10))
    limitDate.setMonth(limitDate.getMonth() + 1)
  }

  if (isNil(limitDate) || now >= limitDate.getTime()) {
    // FIXME: typed mutations.
    // store.commit('app/setShowAddToHomeScreenModalForApple', true)
  }
}
