
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import I18n from '@/i18n/i18n'

const LANGUAGES: SelectOption[] = [
  { id: 'ru', title: 'RU' },
  { id: 'en', title: 'EN' },
]

@Component
export default class AppLangSwitch extends Vue {
  languages: SelectOption[] = LANGUAGES
  isLoading = false

  get selectedId(): string {
    return this.$i18n.locale
  }

  get selectedTitle(): string | null {
    const selectedId = this.selectedId
    if (selectedId) {
      return LANGUAGES.find(it => it.id === selectedId)!.title
    }
    return null
  }

  async onSelect(id: string) {
    this.isLoading = true
    await I18n.setLocale(id)
    this.isLoading = false
  }
}
