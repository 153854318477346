
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import WindowManager from '../util/window-manager'

@Component
export default class AppDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  value!: boolean

  @Prop({ type: [String, Number], default: 300 })
  width!: string | number

  @Prop({ type: Boolean, default: false })
  right!: boolean

  isVisible = false

  mounted() {
    this.onValueChanged()
  }

  beforeDestroy() {
    WindowManager.enableScrolling()
  }

  @Watch('value')
  private onValueChanged() {
    if (this.value) {
      this.isVisible = true
      WindowManager.disableScrolling()
    } else {
      setTimeout(() => {
        this.isVisible = false
      }, 150)
      WindowManager.enableScrolling()
    }
  }
}
