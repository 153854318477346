
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { ImmigrationTime } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

interface Option {
  immigrationTime: ImmigrationTime
  label: string
}

@Component
export default class QuestionnaireIntro extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  options: Option[] = [
    {
      immigrationTime: ImmigrationTime.TWO,
      label: '1-2',
    },
    {
      immigrationTime: ImmigrationTime.FOUR,
      label: '3-4',
    },
  ]

  isOptionSelected(option: Option): boolean {
    const immigrationTime = this.profile.immigrationTime
    return immigrationTime != null && immigrationTime.maxYears === option.immigrationTime.maxYears
  }

  onOptionSelected(option: Option) {
    this.profile.immigrationTime = option.immigrationTime
    this.$emit('submit')
  }
}
