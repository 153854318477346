import { PoolData, Collection, InvitationRound } from 'shared-entities'
import ListUtils from '@/common/util/list-utils'
import asyncFirestore from '../firebase/async-firestore'
import FirestoreUtils from '../firebase/firestore-utils'

export interface PoolDataModel extends PoolData {
  latestRound: InvitationRound
}

type PoolDataSubscriber = (poolData: PoolDataModel | null) => void

export default class PoolDataRepository {
  private static isInitialized = false
  private static initializationPromise: Promise<any> | null = null
  private static poolDataSubscribers: { [id: number]: PoolDataSubscriber } = {}
  private static latestData: PoolDataModel | null = null

  private static subscriberId = 1

  private static init(): Promise<any> {
    if (!this.initializationPromise) {
      this.initializationPromise = asyncFirestore().then(firestore => {
        firestore
          .collection(Collection.POOL)
          .doc('data')
          .onSnapshot(snapshot => {
            const data = FirestoreUtils.convertTimestamps(snapshot.data() as PoolData)
            if (data) {
              const latestRound = ListUtils.maxBy(data.invitationRounds, round =>
                round.roundDatetime.getTime()
              )

              // Constrain the list to most recent 25
              data.invitationRounds = data.invitationRounds.slice(0, 25)

              const model = {
                ...data,
                latestRound,
              }

              this.handlePoolData(model)
            } else {
              this.handlePoolData(null)
            }
          })
      })
    }

    return this.initializationPromise
  }

  private static handlePoolData(data: PoolDataModel | null) {
    this.latestData = data
    const subscribers = this.poolDataSubscribers
    for (const id in subscribers) {
      subscribers[id](data)
    }
  }

  static subscribeToPoolData(subscriber: PoolDataSubscriber): number {
    const id = this.subscriberId++
    this.poolDataSubscribers[id] = subscriber
    if (!this.isInitialized) {
      this.init()
    }

    if (this.latestData) {
      subscriber(this.latestData)
    }

    return id
  }

  static unsubscribeFromPoolData(id: number) {
    delete this.poolDataSubscribers[id]
  }

  static getPoolData(): Promise<PoolDataModel> {
    return new Promise(resolve => {
      let subscriberId: number = 0

      const subscriber: PoolDataSubscriber = data => {
        if (data) {
          resolve(data)
          this.unsubscribeFromPoolData(subscriberId)
        }
      }

      subscriberId = this.subscribeToPoolData(subscriber)
    })
  }

  static getLatestRound(): Promise<InvitationRound> {
    return this.getPoolData().then(poolDataModel => poolDataModel.latestRound)
  }
}
