
import PermissionChecker from '@/data/claims/permission-checker'
import User from '@/data/entity/user.entity'
import { LayoutTypes } from '@/store/modules/layout.module'
import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

interface NavigationItem {
  title: string
  routeName?: string
}

@Component
export default class Navigation extends Vue {
  private allItems: NavigationItem[] = [
    { title: 'label.guide', routeName: RouteNames.GUIDE },
    { title: 'label.rounds', routeName: RouteNames.ROUNDS },
    { title: 'label.programs', routeName: RouteNames.PROGRAMS },
    { title: 'label.expenses', routeName: RouteNames.EXPENSES },
    // { title: 'label.blog' },
    // { title: 'label.help' },
  ]

  items: NavigationItem[] = []

  mounted() {
    this.checkPermissions()
  }

  private async checkPermissions() {
    const hasAppPermission = await PermissionChecker.check('app')
    if (hasAppPermission) {
      this.items = this.allItems
    } else {
      this.items = []
    }
  }

  private get user(): User | null {
    return store.state.auth.user
  }

  @Watch('user')
  private onUserChanged() {
    this.checkPermissions()
  }

  isItemSelected(item: NavigationItem): boolean {
    return this.$route.matched.findIndex(it => it.name === item.routeName) >= 0
  }

  onItemClick(item: NavigationItem) {
    if (!this.isItemSelected(item)) {
      this.$router.push({ name: item.routeName })
      store.commit(LayoutTypes.mutations.setNavDrawerOpen, false)
    }
  }
}
