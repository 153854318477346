
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

const ANIMATION_DURATION_MS = 150

@Component
export default class AppBouncingText extends Vue {
  @Prop({ type: [String, Number] })
  value!: string | number

  isAnimating = false
  containerStyle = {
    transition: `all ${ANIMATION_DURATION_MS}ms`,
  }

  @Watch('value')
  private onValueChanged(value: string | number) {
    this.isAnimating = true
    setTimeout(() => {
      this.isAnimating = false
    }, ANIMATION_DURATION_MS)
  }
}
