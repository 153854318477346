
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { AuthTypes } from '@/store/modules/auth.module'
import RouteHelper from '@/ui/router/route-helper'
import RouteNames from '@/ui/router/route-names'
import WindowManager from '@/ui/util/window-manager'
import Vue from 'vue'
import Component from 'vue-class-component'
import { NavigationGuardNext, Route } from 'vue-router'
import QuestionnaireUtils from '../questionnaire-utils'
import QuestionnaireResultContainer from './component/QuestionnaireResultContainer.vue'
import QuestionnaireResultProgress from './component/QuestionnaireResultProgress.vue'

@Component({
  components: {
    QuestionnaireResultProgress,
    QuestionnaireResultContainer,
  },
})
export default class QuestionnaireResultsScreen extends Vue {
  isInitialized = false
  isShowingProgress = true

  profile!: EditableProfile

  beforeMount() {
    const questionnaireProfile = QuestionnaireUtils.getQuestionnaireProfile()
    if (questionnaireProfile && !this.hasSubscription) {
      this.profile = EditableProfile.fromPlainProfile(questionnaireProfile)
      this.isInitialized = true
    } else {
      RouteHelper.default()
      return
    }

    if (QuestionnaireUtils.didShowQuestionnaireResultProgress()) {
      this.isShowingProgress = false
    }
  }

  get hasSubscription(): boolean {
    return this.$store.getters[AuthTypes.getters.hasSubscription]
  }

  onProgressFinished() {
    this.isShowingProgress = false
    QuestionnaireUtils.setDidShowQuestionnaireResultProgress(true)
  }
}
