import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Program from '@/data/entity/programs/program.entity'
import ProgramEligibility from '@/data/entity/programs/program-eligibility.entity'
import ProgramId from '@/data/entity/programs/program-id.entity'
import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'

export const PNP_PROGRAM: Program = {
  id: ProgramId.PNP,
  title: 'Provincial Nominee Program',
  shortTitle: 'PNP',
  description:
    'This program is for workers who have the skills, education and work experience to contribute to the ' +
    'economy of a specific province or territory, want to live in that province, and want to become permanent residents of Canada',
  link:
    'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/provincial-nominees/works.html',
  eligibilityCriteria: [],
}

export default class PnpEligibility {
  static calculate(profile: EditableProfile): ProgramEligibility {
    let status = EligibilityStatus.NOT_ELIGIBLE
    if (profile.hasProvNomination) {
      status = EligibilityStatus.INFO_NEEDED
    }
    return {
      program: PNP_PROGRAM,
      status,
      criteria: [],
    }
  }
}
