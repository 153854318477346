
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import RootSnackbarController from './root-snackbar-controller'
import {
  IRootSnackbar,
  IRootSnackbarGestureHandler,
  RootSnackbarOptions,
} from './root-snackbar-interface'

const DEFAULT_TIMEOUT = 3900

@Component
export default class RootSnackbar extends Vue implements IRootSnackbar {
  shown = false
  message = ''
  position = 'bottom'
  actionText = 'OK'
  action?: () => void
  persistent = false

  private snackbarTimeout?: number

  private gestureHandler?: IRootSnackbarGestureHandler

  get transitionName(): string {
    return `snackbar-${this.position}`
  }

  mounted() {
    RootSnackbarController.bind(this)
  }

  beforeDestroy() {
    RootSnackbarController.unbind(this)
    if (this.gestureHandler) {
      this.gestureHandler.destroy()
      this.gestureHandler = void 0
    }
  }

  show({
    message,
    persistent = false,
    position = 'bottom',
    actionText = 'OK',
    action,
  }: RootSnackbarOptions) {
    if (persistent) {
      this.resetTimer()
    } else {
      this.hide()
    }

    this.message = message
    this.position = position
    this.actionText = actionText
    this.action = action
    this.persistent = persistent

    this.nextTick(() => {
      this.shown = true

      if (!persistent) {
        this.nextTick(() => {
          this.startProgress()
        })

        this.snackbarTimeout = setTimeout(() => {
          this.hide()
        }, DEFAULT_TIMEOUT)
      } else {
        this.nextTick(() => {
          this.getProgressBar().style.display = 'none'
        })
      }

      this.nextTick(() => {
        this.initGestureHandler()
      })
    })
  }

  private async initGestureHandler() {
    const module = await import(
      /* webpackChunkName: "chunk-snackbar-gesture" */ './root-snackbar-gesture-handler'
    )
    // Don't initialize the gesture handler if the snackbar is already hidden.
    if (this.shown) {
      this.gestureHandler = new module.default(this.getMessageElement(), this.hide)
    }
  }

  hide() {
    this.resetTimer()
    this.shown = false
    this.actionText = ''
    this.action = void 0
    this.persistent = false
    this.destroyGestureHandler()
  }

  onActionClicked() {
    if (this.action) {
      this.action()
    }
    this.hide()
  }

  private nextTick(cb: () => void) {
    setTimeout(cb, 100)
  }

  private startProgress() {
    const progress = this.getProgressBar()
    if (progress) {
      // -100ms for the delay between showing the snackbar and starting the progress.
      const duration = `${DEFAULT_TIMEOUT - 100}ms`
      progress.style.transitionDuration = duration
      progress.style.display = 'block'
      progress.classList.add('started')
    }
  }

  private stopProgress() {
    const progress = this.getProgressBar()
    if (progress) {
      progress.style.display = 'none'
      progress.classList.remove('started')
    }
  }

  private destroyGestureHandler() {
    if (this.gestureHandler) {
      this.gestureHandler.destroy()
      this.gestureHandler = void 0
    }
  }

  private getProgressBar(): HTMLElement {
    return this.$refs.progress as HTMLElement
  }

  private getMessageElement(): HTMLElement {
    return this.$refs.message as HTMLElement
  }

  private resetTimer() {
    if (this.snackbarTimeout) {
      clearTimeout(this.snackbarTimeout)
    }
    this.stopProgress()
  }

  @Watch('shown')
  private onShownChanged(shown: boolean) {
    if (!shown) {
      this.destroyGestureHandler()
    }
  }
}
