import User from '@/data/entity/user.entity'
import authRepository from '@/data/repository/auth.repository'
import { RootState } from '@/store/store'
import QuestionnaireUtils from '@/ui/screens/questionnaire/questionnaire-utils'
import { SubscriptionUtils, SubscriptionInfo, Customer } from 'shared-entities'
import { ActionContext } from 'vuex'
import { Actions, Getters, ModuleWrapper, Mutations } from '../util/store-framework'
import StoreUtils from '../util/store-utils'

const types = {
  mutations: {
    setUser: 'setUser',
    setHadInitializationError: 'setHadInitializationError',
    setAuthorizingRoute: 'setAuthorizingRoute',
  },
  actions: {
    setUser: 'setUser',
    signOut: 'signOut',
  },
  getters: {
    isSignedIn: 'isSignedIn',
    isSubscribedToNewsletter: 'isSubscribedToNewsletter',
    hasSubscription: 'hasSubscription',
    subscription: 'subscription',
    customer: 'customer',
  },
}

export const AuthTypes = StoreUtils.exportTypes('auth', types)
const { mutations, actions, getters } = types

export class AuthState {
  // True if the user was set initially, either to the user object or to null
  isUserInitialized = false
  // True if the customer data is being fetched.
  isInitialized = false
  // True if an error occurred when fetching startup data.
  hadInitializationError = false

  // True if the router navigation guard is currently authorizing user's access to a route.
  isAuthorizingRoute = false

  user: User | null = null
}

class AuthMutations extends Mutations<AuthState> {
  [mutations.setUser](state: AuthState, user: User | null) {
    state.user = user
    if (!state.isUserInitialized) {
      state.isUserInitialized = true
    }
    if (!user || user.customer) {
      state.isInitialized = true
    }
  }

  [mutations.setHadInitializationError](state: AuthState, hadError: boolean) {
    state.hadInitializationError = hadError
  }

  [mutations.setAuthorizingRoute](state: AuthState, authorizing: boolean) {
    state.isAuthorizingRoute = authorizing
  }
}

class AuthActions extends Actions<AuthState> {
  [actions.setUser]({ commit }: ActionContext<AuthState, RootState>, user: User | null) {
    commit(mutations.setUser, user)
    if (user && user.customer) {
      commit('profile/setProfile', user.customer.profile, { root: true })
    }
  }

  async [actions.signOut]({ commit }: ActionContext<AuthState, RootState>) {
    const result = await authRepository.signOut()
    if (result.isSuccessful) {
      commit(mutations.setUser, null)
      commit(mutations.setHadInitializationError, false)
      QuestionnaireUtils.cleanup()
      authRepository.setUser(null)
    }
  }
}

class AuthGetters extends Getters<AuthState> {
  [getters.isSignedIn](state: AuthState): boolean {
    return !!state.user
  }

  [getters.isSubscribedToNewsletter](state: AuthState): boolean {
    const user = state.user
    const customer = user ? user.customer : null
    return customer ? customer.settings.subscribedToNewsletter : false
  }

  [getters.hasSubscription](state: AuthState, g: any): boolean {
    const sub: SubscriptionInfo | null = g[getters.subscription]
    return SubscriptionUtils.isSubscriptionActive(sub)
  }

  [getters.subscription](state: AuthState): SubscriptionInfo | null {
    const user = state.user
    const customer = user ? user.customer : null
    return customer ? customer.subscription : null
  }

  [getters.customer](state: AuthState): Customer | null {
    const user = state.user
    return user ? user.customer || null : null
  }
}

class AuthModule extends ModuleWrapper<AuthState> {
  state = new AuthState()
  mutations = new AuthMutations()
  actions = new AuthActions()
  getters = new AuthGetters()
}

export default new AuthModule()
