
import Vue from 'vue'
import Component from 'vue-class-component'
import GalleryImage from './gallery-image'
import { GalleryPlugin } from './root-gallery-interface'
import RootGalleryController from './root-gallery-controller'
import WindowManager from '@/ui/util/window-manager'

let PhotoSwipe: new (
  pswpElement: HTMLElement,
  uiConstructor:
    | (new (
        pswp: PhotoSwipe<PhotoSwipe.Options>,
        framework: PhotoSwipe.UIFramework
      ) => PhotoSwipe.UI<PhotoSwipe.Options>)
    | boolean,
  items: PhotoSwipe.Item[],
  options: PhotoSwipe.Options
) => PhotoSwipe<PhotoSwipe.Options>

let PhotoSwipeUI_Default: new (
  pswp: PhotoSwipe<PhotoSwipe.Options>,
  framework: PhotoSwipe.UIFramework
) => PhotoSwipe.UI<PhotoSwipe.Options>

@Component
export default class RootGallery extends Vue implements GalleryPlugin {
  loaded = !!PhotoSwipe
  isLoading = false

  private didDisableScrolling = false

  private gallery: PhotoSwipe<PhotoSwipe.Options> | null = null

  mounted() {
    RootGalleryController.bind(this)
  }

  beforeDestroy() {
    RootGalleryController.unbind(this)
    this.enableScrolling()
    if (this.gallery) {
      this.gallery.destroy()
    }
  }

  async open(
    images: GalleryImage[],
    getThumbBoundsFn: (index: number) => { x: number; y: number; w: number },
    index?: number
  ) {
    if (!this.loaded) {
      await this.loadPhotoSwipe()
    }

    if (this.gallery) {
      this.gallery.destroy()
    }

    this.gallery = new PhotoSwipe(this.$refs.pswp as HTMLElement, PhotoSwipeUI_Default, images, {
      index,
      closeOnScroll: false,
      getThumbBoundsFn,
      history: false,
      bgOpacity: 0.8,
      showAnimationDuration: 250,
      hideAnimationDuration: 250,
    })

    this.gallery.listen('destroy', () => {
      this.enableScrolling()
      this.gallery = null
    })

    this.gallery.init()

    this.disableScrolling()
  }

  private enableScrolling() {
    if (this.didDisableScrolling) {
      WindowManager.enableScrolling()
      this.didDisableScrolling = false
    }
  }

  private disableScrolling() {
    if (!this.didDisableScrolling) {
      WindowManager.disableScrolling()
      this.didDisableScrolling = true
    }
  }

  private async loadPhotoSwipe(): Promise<void> {
    this.isLoading = true
    const load = await import(/* webpackChunkName: "chunk-gallery" */ './photo-swipe')
    PhotoSwipe = load.PhotoSwipe
    PhotoSwipeUI_Default = load.PhotoSwipeUI_Default

    this.isLoading = false
    this.loaded = true
  }
}
