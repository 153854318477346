import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { LANGUAGE_SKILLS, EducationLevel, LanguageTestScoresUtils, NocGroup } from 'shared-entities'

const EDUCATION_POINTS: { [key in EducationLevel]: number } = {
  [EducationLevel.LESS_THAN_SECONDARY]: 0,
  [EducationLevel.SECONDARY]: 5,
  [EducationLevel.ONE_YEAR_DEGREE]: 15,
  [EducationLevel.TWO_YEAR_DEGREE]: 19,
  [EducationLevel.BACHELORS_DEGREE]: 21,
  [EducationLevel.TWO_DEGREES]: 22,
  [EducationLevel.MASTERS_DEGREE]: 23,
  [EducationLevel.PHD]: 25,
}

export default class FswPointCalculator {
  static calculate(profile: EditableProfile): number {
    let result = 0

    result += this.langPoints(profile)
    result += this.educationPoints(profile)
    result += this.workExpPoints(profile)
    result += this.agePoints(profile)
    result += this.jobOfferPoints(profile)
    result += this.adaptabilityPoints(profile)

    return result
  }

  private static langPoints(profile: EditableProfile): number {
    let points = 0
    const firstLangScores = profile.getFirstLanguageClbScores()
    if (firstLangScores) {
      for (let i = 0; i < LANGUAGE_SKILLS.length; i++) {
        const score = firstLangScores[LANGUAGE_SKILLS[i]]
        if (score >= 9) points += 6
        else if (score >= 8) points += 5
        else if (score >= 7) points += 4
      }
    }

    const secondLangScores = profile.getSecondLanguageClbScores()
    if (secondLangScores) {
      for (let i = 0; i < LANGUAGE_SKILLS.length; i++) {
        const score = secondLangScores[LANGUAGE_SKILLS[i]]
        if (score >= 5) points += 4
      }
    }

    return Math.min(points, 28)
  }

  private static educationPoints(profile: EditableProfile): number {
    const education = profile.education
    if (education) {
      return EDUCATION_POINTS[education]
    }
    return 0
  }

  private static workExpPoints(profile: EditableProfile): number {
    const totalWorkExp = profile.workExp + profile.foreignWorkExp
    if (totalWorkExp >= 6) return 15
    else if (totalWorkExp >= 4) return 13
    else if (totalWorkExp >= 2) return 11
    else if (totalWorkExp >= 1) return 9
    else return 0
  }

  private static agePoints(profile: EditableProfile): number {
    const age = profile.age
    if (age && age >= 18) {
      return Math.min(12, Math.max(0, 47 - age))
    } else {
      return 0
    }
  }

  private static jobOfferPoints(profile: EditableProfile): number {
    return profile.jobOffer !== null && profile.eligibility.fsw.hasJobOffer ? 10 : 0
  }

  private static adaptabilityPoints(profile: EditableProfile): number {
    let points = 0
    points += this.spouseLangAdaptability(profile)
    points += this.canadianEducationAdaptability(profile)
    points += this.canadianWorkExpAdaptability(profile)
    points += this.jobOfferAdaptability(profile)
    points += this.relativesAdaptability(profile)
    return Math.min(points, 10)
  }

  private static spouseLangAdaptability(profile: EditableProfile): number {
    if (profile.hasSpouse) {
      const firstLangScores = profile.spouse.getFirstLanguageClbScores()
      if (
        firstLangScores &&
        LanguageTestScoresUtils.everySkill(firstLangScores, score => score >= 4)
      ) {
        return 10
      }
    }

    return 0
  }

  private static canadianEducationAdaptability(profile: EditableProfile): number {
    let points = 0
    if (profile.eligibility.fsw.canadianEducation) {
      points += 5
    }
    if (profile.hasSpouse && profile.eligibility.fsw.spouseCanadianEducation) {
      points += 5
    }
    return points
  }

  private static canadianWorkExpAdaptability(profile: EditableProfile): number {
    let points = 0
    if (profile.workExp >= 1) {
      points += 10
    }

    if (profile.hasSpouse && profile.spouse.workExp >= 1) {
      points += 5
    }
    return points
  }

  private static jobOfferAdaptability(profile: EditableProfile): number {
    if (profile.jobOffer !== null && profile.jobOffer >= NocGroup.NOC_B) {
      return 5
    }
    return 0
  }

  private static relativesAdaptability(profile: EditableProfile): number {
    if (profile.hasCanadianSibling || profile.eligibility.fsw.hasRelativesInCanada) {
      return 5
    }
    return 0
  }
}
