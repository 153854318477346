
import Vue from 'vue'
import Component from 'vue-class-component'
import RouteNames from '../router/route-names'

@Component
export default class PremiumAdBanner extends Vue {
  goToPremium() {
    this.$router.push({ name: RouteNames.PREMIUM })
  }
}
