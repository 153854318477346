
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class AppCircleImage extends Vue {
  @Prop() src!: string
  @Prop() size?: number

  get dimension(): number {
    return this.size || 50
  }
}
