import PermissionChecker from '@/data/claims/permission-checker'
import { Permission } from 'shared-entities'
import { AsyncComponent } from 'vue'
import { RouteConfig } from 'vue-router'
import RouteNames from './route-names'

import AuthScreen from '@/ui/screens/auth/AuthScreen.vue'
import GuideScreen from '@/ui/screens/guide/GuideScreen.vue'
import HomeScreen from '@/ui/screens/home/HomeScreen.vue'
import PaymentResultScreen from '@/ui/screens/payment/PaymentResultScreen.vue'
import QuestionnairePaymentScreen from '@/ui/screens/questionnaire/payment/QuestionnairePaymentScreen.vue'
import QuestionnaireScreen from '@/ui/screens/questionnaire/QuestionnaireScreen.vue'
import QuestionnaireResultsScreen from '@/ui/screens/questionnaire/results/QuestionnaireResultsScreen.vue'
import PageNotFound from './PageNotFound.vue'

/** Extend our custom config type to add the strongly typed meta field */
interface AppRouteConfig extends RouteConfig {
  meta?: RouteMeta
  children?: AppRouteConfig[]
}

/** Meta parameters for routes. */
export interface RouteMeta {
  /** If true, then the route requires that the user is authenticated */
  authenticate?: boolean
  /** Lists permissions that the user must have to be able to access this route */
  permissions?: Permission[]
  /** If true, then the route cannot be accessed by entering the URL directly */
  noDirectAccess?: boolean
  /** If true, then allow access to this route only to unpaid/signed out users. */
  unpaidOnly?: boolean
}

type VueImport = typeof import('*.vue')

/**
 * Return an AsyncComponent that resolves with either the success or failure component,
 * or returns a rejection, based on whether the current user is granted the given permission.
 *
 * @param permission The permission to check
 * @param onSuccess
 *    The function to retrieve the component to show in case of success.
 * @param onFailure
 *    Optional function to retrieve the component to show in case of failure.
 */
function checkPermission(
  permission: Permission,
  params: {
    onSuccess: () => Promise<VueImport>
    onFailure: () => Promise<VueImport>
  }
): AsyncComponent {
  return async () => {
    const hasPermission = await PermissionChecker.check(permission)
    if (hasPermission) {
      return params.onSuccess()
    } else {
      return params.onFailure()
    }
  }
}

const routes: AppRouteConfig[] = [
  {
    path: '/home',
    name: RouteNames.HOME,
    component: HomeScreen,
    children: [
      {
        path: '/login',
        name: RouteNames.SIGN_IN,
        component: AuthScreen,
      },
      {
        path: '/questionnaire',
        name: RouteNames.QUESTIONNAIRE,
        component: QuestionnaireScreen,
        meta: {
          unpaidOnly: true,
        },
      },
      {
        path: '/questionnaire-results',
        name: RouteNames.QUESTIONNAIRE_RESULTS,
        component: QuestionnaireResultsScreen,
        meta: {
          authenticate: true,
        },
      },
      {
        path: '/questionnaire-receive-plan',
        name: RouteNames.QUESTIONNAIRE_PAYMENT,
        component: QuestionnairePaymentScreen,
        meta: {
          authenticate: true,
        },
      },
      {
        path: 'guide',
        name: RouteNames.GUIDE,
        component: GuideScreen,
        meta: {
          permissions: ['app'],
        },
        children: [
          {
            path: '',
            name: RouteNames.GUIDE_STATUS_CENTER,
            component: () =>
              import(
                /* webpackChunkName: "chunk-fa8247fe-6610-4439-ab0a-086b6f6e9bef" */ '../screens/guide/status-center/StatusCenterScreen.vue'
              ),
            meta: {
              permissions: ['statusCenter'],
            },
          },
          {
            path: 'eligibility',
            name: RouteNames.GUIDE_ELIGIBILITY,
            component: () =>
              import(
                /* webpackChunkName: "chunk-cdc1bd4e-351a-4007-a182-92d0c1682241" */ '../screens/guide/point-calculator/PointCalculatorScreen.vue'
              ),
            meta: {
              permissions: ['eligibilityGuide'],
            },
          },
          {
            path: 'pool',
            name: RouteNames.GUIDE_POOL,
            component: checkPermission('poolGuide', {
              onSuccess: () =>
                import(
                  /* webpackChunkName: "chunk-f0d79ae6-f303-4f3e-b87a-b41ea8fe7909" */ '../screens/guide/pool/PoolScreen.vue'
                ),
              onFailure: () =>
                import(
                  /* webpackChunkName: "chunk-pool-demo" */ '../screens/guide/pool/PoolScreenDemo.vue'
                ),
            }),
          },
          {
            path: 'ita',
            name: RouteNames.GUIDE_ITA,
            component: checkPermission('itaGuide', {
              onSuccess: () =>
                import(
                  /* webpackChunkName: "chunk-d9485bb8-dcad-4838-80f2-7a98390bf9bc" */ '../screens/guide/ita/ItaScreen.vue'
                ),
              onFailure: () =>
                import(
                  /* webpackChunkName: "chunk-ita" */ '../screens/guide/ita/ItaScreenDemo.vue'
                ),
            }),
          },
          {
            path: 'pr',
            name: RouteNames.GUIDE_PR,
            component: checkPermission('prGuide', {
              onSuccess: () =>
                import(
                  /* webpackChunkName: "chunk-072ea44d-0d64-44e0-9a8b-179d96d786dd" */ '../screens/guide/pr/PrScreen.vue'
                ),
              onFailure: () =>
                import(/* webpackChunkName: "chunk-pr" */ '../screens/guide/pr/PrScreenDemo.vue'),
            }),
          },
        ],
      },
      {
        path: 'rounds',
        name: RouteNames.ROUNDS,
        component: () =>
          import(
            /* webpackChunkName: "chunk-9d5bd8c5-3d0d-4fa9-997c-c1bc39b275b9" */ '../screens/rounds/RoundsScreen.vue'
          ),
        meta: {
          permissions: ['app'],
        },
      },
      {
        path: 'programs',
        name: RouteNames.PROGRAMS,
        component: () =>
          import(
            /* webpackChunkName: "chunk-8b9820fa-8dad-4e7f-bd78-d17bae2883f7" */ '../screens/programs/ProgramsScreen.vue'
          ),
        meta: {
          permissions: ['app'],
        },
      },
      {
        path: 'expenses',
        name: RouteNames.EXPENSES,
        component: () =>
          import(
            /* webpackChunkName: "chunk-06f666ce-5667-4d1d-adda-dcfc2372bd0d" */ '../screens/expenses/ExpensesScreen.vue'
          ),
        meta: {
          permissions: ['app'],
        },
      },
      {
        path: 'profile',
        name: RouteNames.PROFILE,
        component: () =>
          import(/* webpackChunkName: "chunk-profile" */ '../screens/profile/ProfileScreen.vue'),
        meta: {
          authenticate: true,
        },
      },
      {
        path: 'plans',
        name: RouteNames.PLANS,
        component: () =>
          import(/* webpackChunkName: "chunk-payment" */ '../screens/payment/PaymentScreen.vue'),
      },
      {
        path: 'premium',
        name: RouteNames.PREMIUM,
        component: () =>
          import(
            /* webpackChunkName: "chunk-payment" */ '../screens/payment/premium/PremiumScreen.vue'
          ),
      },
      {
        path: '/payment-result/:result',
        name: RouteNames.PAYMENT_RESULT,
        component: PaymentResultScreen,
        meta: {
          authenticate: true,
        },
      },
    ],
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

export default routes
