
import Vue from 'vue'
import Component from 'vue-class-component'

import './_questionnaire.scss'

import EditableProfile from '@/data/entity/profile/editable-profile.entity'

import QuestionnaireProgressBar from './component/QuestionnaireProgressBar.vue'

import QuestionnaireIntro from './steps/QuestionnaireIntro.vue'
import QuestionnaireSpouse from './steps/QuestionnaireSpouse.vue'
import QuestionnaireAge from './steps/QuestionnaireAge.vue'
import QuestionnaireEducation from './steps/QuestionnaireEducation.vue'
import QuestionnaireCanadianEducation from './steps/QuestionnaireCanadianEducation.vue'
import QuestionnaireLanguage from './steps/QuestionnaireLanguage.vue'
import QuestionnaireWorkExperience from './steps/QuestionnaireWorkExperience.vue'
import QuestionnaireJobOffer from './steps/QuestionnaireJobOffer.vue'
import QuestionnaireCertificate from './steps/QuestionnaireCertificate.vue'
import QuestionnaireRelatives from './steps/QuestionnaireRelatives.vue'
import QuestionnaireSibling from './steps/QuestionnaireSibling.vue'
import QuestionnaireProofOfFunds from './steps/QuestionnaireProofOfFunds.vue'
import QuestionnaireDesiredProvince from './steps/QuestionnaireDesiredProvince.vue'
import RouteNames from '@/ui/router/route-names'

import QuestionnaireUtils from './questionnaire-utils'
import { AuthTypes } from '@/store/modules/auth.module'
import RouteHelper from '@/ui/router/route-helper'
import WindowManager from '@/ui/util/window-manager'
import { NavigationGuardNext, Route } from 'vue-router'

interface QuestionnaireStep {
  // The component to render for this step.
  component: string
  // Props to be passed to the component.
  props?: any
  // If specified, this step will only be shown if the function returns true.
  condition?: (profile: EditableProfile) => boolean
}

@Component({
  components: {
    QuestionnaireProgressBar,
    QuestionnaireIntro,
    QuestionnaireSpouse,
    QuestionnaireAge,
    QuestionnaireEducation,
    QuestionnaireCanadianEducation,
    QuestionnaireLanguage,
    QuestionnaireWorkExperience,
    QuestionnaireJobOffer,
    QuestionnaireCertificate,
    QuestionnaireRelatives,
    QuestionnaireSibling,
    QuestionnaireProofOfFunds,
    QuestionnaireDesiredProvince,
  },
})
export default class QuestionnaireScreen extends Vue {
  profile: EditableProfile = EditableProfile.create()

  private allSteps: QuestionnaireStep[] = [
    { component: 'QuestionnaireIntro' },
    { component: 'QuestionnaireSpouse' },
    { component: 'QuestionnaireAge' },
    {
      component: 'QuestionnaireEducation',
      props: {
        title: 'Your level of education',
      },
    },
    {
      component: 'QuestionnaireEducation',
      props: {
        title: "Your spouse's level of education",
        profile: this.profile.spouse,
      },
      condition: profile => profile.hasSpouse,
    },
    { component: 'QuestionnaireCanadianEducation' },
    {
      component: 'QuestionnaireLanguage',
      props: {
        topImageSrc: require('@/assets/images/q-english.png'),
        title: 'Knowledge of English',
        languageTestType: 'IELTS',
      },
    },
    {
      component: 'QuestionnaireLanguage',
      props: {
        topImageSrc: require('@/assets/images/q-english.png'),
        title: "Your spouse's knowledge of English",
        languageTestType: 'IELTS',
        profile: this.profile.spouse,
      },
      condition: profile => profile.hasSpouse,
    },
    {
      component: 'QuestionnaireLanguage',
      props: {
        topImageSrc: require('@/assets/images/q-french.png'),
        title: 'Knowledge of French',
        languageTestType: 'TEF',
      },
    },
    {
      component: 'QuestionnaireLanguage',
      props: {
        topImageSrc: require('@/assets/images/q-french.png'),
        title: "Your spouse's knowledge of French",
        languageTestType: 'TEF',
        profile: this.profile.spouse,
      },
      condition: profile => profile.hasSpouse,
    },
    {
      component: 'QuestionnaireWorkExperience',
      props: {
        title: 'Work experience in Canada',
        field: 'workExp',
      },
    },
    {
      component: 'QuestionnaireWorkExperience',
      props: {
        title: 'Work experience outside of Canada',
        field: 'foreignWorkExp',
      },
    },
    {
      component: 'QuestionnaireWorkExperience',
      props: {
        title: "Your spouse's work experience in Canada",
        field: 'workExp',
        profile: this.profile.spouse,
      },
      condition: profile => profile.hasSpouse,
    },
    { component: 'QuestionnaireJobOffer' },
    { component: 'QuestionnaireCertificate' },
    { component: 'QuestionnaireRelatives' },
    {
      component: 'QuestionnaireSibling',
      condition: profile => profile.eligibility.fsw.hasRelativesInCanada,
    },
    { component: 'QuestionnaireProofOfFunds' },
    { component: 'QuestionnaireDesiredProvince' },
  ]

  steps: QuestionnaireStep[] = this.allSteps

  currentStepIndex = 0

  /** Whether the current step is fading out. */
  stepFadingOut = false

  /** Set to true when the initial image is precached */
  isInitialized = false

  created() {
    this.updateSteps()
    WindowManager.enableConfirmTabClose()
  }

  beforeMount() {
    this.precacheImages()
  }

  private async precacheImages() {
    const image = new Image()
    image.src = require('@/assets/images/q-intro.png')
    await new Promise((resolve, reject) => {
      image.onload = resolve
      image.onerror = reject
    })

    this.isInitialized = true

    const imageUrls: string[] = [
      require('@/assets/images/q-plan.png'),
      require('@/assets/images/q-sibling.png'),
      require('@/assets/images/q-job-offer.png'),
      require('@/assets/images/q-french.png'),
      require('@/assets/images/q-english.png'),
      require('@/assets/images/q-education.png'),
      require('@/assets/images/q-certificate.png'),
      require('@/assets/images/q-canadian-education.png'),
    ]

    for (let i = 0; i < imageUrls.length; i++) {
      const image = new Image()
      image.src = imageUrls[i]
    }
  }

  beforeDestroy() {
    WindowManager.disableConfirmTabClose()
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (this.currentStepIndex > 0 && this.currentStepIndex < this.steps.length - 1) {
      const answer = window.confirm('Do you really want to leave? Your changes will not be saved.')
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }

  private updateSteps() {
    const oldCurrentStep = this.steps[this.currentStepIndex]
    const newSteps = this.allSteps.filter(step => {
      if (step.condition) {
        return step.condition(this.profile)
      } else {
        return true
      }
    })

    // If oldCurrentStep is no longer in the list of steps, find the closest step to the
    // left that is in the new list.
    let newIndex = this.allSteps.indexOf(oldCurrentStep)
    if (newSteps.indexOf(oldCurrentStep) < 0) {
      while (newIndex > 0 && newSteps.indexOf(this.allSteps[newIndex]) < 0) {
        newIndex--
      }
    }
    this.currentStepIndex = newSteps.indexOf(this.allSteps[newIndex])
    this.steps = newSteps
  }

  get currentStep(): QuestionnaireStep {
    return this.steps[this.currentStepIndex]
  }

  get progress(): number {
    return (this.currentStepIndex / (this.steps.length - 1)) * 100
  }

  getStepProps(step: QuestionnaireStep): any {
    if (step.props) {
      return {
        profile: this.profile,
        ...step.props,
      }
    } else {
      return {
        profile: this.profile,
      }
    }
  }

  onStepSubmitted() {
    this.updateSteps()

    if (this.currentStepIndex < this.steps.length - 1) {
      this.currentStepIndex++
      this.onStepIndexUpdated(this.currentStepIndex - 1, this.currentStepIndex)
    } else {
      this.completeQuestionnaire()
    }
  }

  goBack() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--
      this.onStepIndexUpdated(this.currentStepIndex + 1, this.currentStepIndex)
    }
  }

  private async completeQuestionnaire() {
    QuestionnaireUtils.setQuestionnaireCompleted(this.profile)
    if (this.$store.getters[AuthTypes.getters.isSignedIn]) {
      this.$router.replace({ name: RouteNames.QUESTIONNAIRE_RESULTS })
    } else {
      const result = await this.$dialog.confirm({
        title: this.$ts('message.signInRequired'),
        message: this.$ts('questionnaire.pleaseSignIn'),
        confirmBtnText: this.$ts('action.continue'),
      })

      if (result) {
        // Wait for the confirmation dialog to close.
        setTimeout(() => {
          RouteHelper.navigateToAuth()
        }, 100)
      }
    }
  }

  private onStepIndexUpdated(oldIndex: number, newIndex: number) {
    WindowManager.scrollToTop()
    if (oldIndex !== 0 && newIndex !== 0) {
      this.stepFadingOut = true
      setTimeout(() => {
        this.stepFadingOut = false
      }, 150)
    }
  }
}
