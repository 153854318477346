
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import { Prop } from 'vue-property-decorator'
import UiUtils from '../util/ui-utils'

@Component
export default class AppDropdownDialog extends Vue {
  @Prop({ type: Array, required: true })
  options!: SelectOption[]

  @Prop({ type: [Number, String] })
  selectedId?: number | string

  @Prop({ type: String })
  title?: string

  @Prop({ type: Boolean, default: false })
  nullable!: boolean

  mounted() {
    this.scrollToSelected()
  }

  private scrollToSelected() {
    if (typeof this.selectedId !== 'undefined') {
      setTimeout(() => {
        const options = this.getOptions()
        if (options) {
          const selectedIndex = this.options.findIndex(it => it.id === this.selectedId)
          if (selectedIndex >= 0) {
            UiUtils.scrollToElement(options, selectedIndex)
          }
        }
      })
    }
  }

  private getOptions(): HTMLElement {
    return this.$refs.options as HTMLElement
  }

  selectOption(option: SelectOption) {
    this.$emit('dismiss', option.id)
  }

  clearSelection() {
    this.$emit('dismiss', null)
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
