import {
  IRootDialog,
  RootDialogOptions,
  DialogPlugin,
  ConfirmDialogOptions,
} from './root-dialog-interface'

export class RootDialogController implements DialogPlugin {
  private component: IRootDialog | null = null

  bind(component: IRootDialog) {
    if (this.component && this.component !== component) {
      throw new Error('Root dialog component is already bound')
    }

    this.component = component
  }

  unbind(component: IRootDialog) {
    if (this.component === component) {
      this.component = null
    } else {
      throw new Error('Invalid component supplied in unbind() call')
    }
  }

  closedInternally() {
    // Invoked by RootDialog when it is closed internally, such as when
    // the user clicks on the dialog backdrop.
    if (window.history.state) {
      window.history.back()
    }
  }

  show(options: RootDialogOptions) {
    if (this.component) {
      // Add the hash so that when the user presses the back button in the browser
      // or on the Android device, the hash is popped and we're notified about that
      // in detect-popstate.ts, which then closes the dialog.
      window.history.pushState('dialog', '')
      this.component.show(options)
    } else {
      console.error('Cannot show: RootDialog component not bound')
    }
  }

  hide() {
    if (this.component && this.component.isShown()) {
      // This causes the 'popstate' event to be emitted on the Window
      // object, which is caught in detect-popstate.ts, and leads
      // to a call to close() method below.
      if (window.history.state) {
        window.history.back()
      }
    } else {
      console.error('Cannot hide: RootDialog component not bound')
    }
  }

  close() {
    if (this.component) {
      this.component.hide()
    } else {
      console.error('Cannot close: RootDialog component not bound')
    }
  }

  alert(options: RootDialogOptions) {
    const defaultOptions: RootDialogOptions = {
      primaryBtnText: 'action.close',
    }
    this.show(Object.assign(defaultOptions, options))
  }

  confirm(options: ConfirmDialogOptions): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.component) {
        this.show({
          title: options.title,
          message: options.message,
          primaryBtnText: options.confirmBtnText || 'action.ok',
          secondaryBtnText: options.rejectBtnText || 'action.cancel',
          primaryFn: () => {
            if (options.onConfirm) {
              const promise = options.onConfirm()
              if (promise) {
                return promise
              }
            }
            resolve(true)
          },
          secondaryFn: () => {
            resolve(false)
            if (options.onReject) {
              options.onReject()
            }
          },
          cancelable: typeof options.cancelable === 'boolean' ? options.cancelable : true,
          width: options.width,
        })
      } else {
        reject('Cannot show confirm dialog: RootDialog component not bound')
      }
    })
  }

  isCancelable(): boolean {
    if (this.component) {
      return this.component.isCancelable()
    }
    return false
  }

  setCancelable(cancelable: boolean) {
    if (this.component) {
      this.component.setCancelable(cancelable)
    } else {
      console.error('Cannot setCancelable: RootDialog component not bound')
    }
  }

  isShown(): boolean {
    return (this.component && this.component.isShown()) || false
  }
}

export default new RootDialogController()
