import EligibilityCriterionResult from '@/data/entity/programs/eligibility-criterion-result.entity'
import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'
import ListUtils from '@/common/util/list-utils'

export default class EligibilityCalculatorUtils {
  /**
   * Calculate the program eligibility status based on the criteria that make it up.
   */
  static calculateProgramEligibility(criteria: EligibilityCriterionResult[]): EligibilityStatus {
    return ListUtils.minBy(criteria, it => it.status).status
  }
}
