export enum EducationLevel {
  LESS_THAN_SECONDARY = 1,
  SECONDARY = 2,
  ONE_YEAR_DEGREE = 3,
  TWO_YEAR_DEGREE = 4,
  BACHELORS_DEGREE = 5,
  TWO_DEGREES = 6,
  MASTERS_DEGREE = 7,
  PHD = 8,
}
