import firebase from 'firebase/compat/app'

import { Customer, Claims } from 'shared-entities'
import { Exclude } from 'class-transformer'

import FirebaseUser = firebase.User
import Utils from '@/common/util/utils'

export default class User {
  id!: string
  email!: string
  name!: string | null
  customer?: Customer

  @Exclude() firebaseUser?: FirebaseUser

  static fromFirebaseUser(firebaseUser: FirebaseUser, customer?: Customer): User {
    const user = new User()
    user.id = firebaseUser.uid
    user.email = firebaseUser.email!
    user.firebaseUser = firebaseUser
    user.name = firebaseUser.displayName
    user.customer = customer
    return user
  }

  /**
   * Retrieve custom claims associated with this user.
   */
  async getClaims(): Promise<Claims> {
    if (this.firebaseUser) {
      const tokenResult = await this.firebaseUser.getIdTokenResult()
      return tokenResult.claims
    }
    return {}
  }

  /**
   * Get a list of authentication providers that are linked with this user's account.
   */
  get authProviders(): string[] {
    if (this.firebaseUser) {
      const providerData = this.firebaseUser.providerData
      return providerData.filter(Utils.hasValue).map(it => it.providerId)
    }
    return []
  }

  copyWithFirebaseUser(firebaseUser: FirebaseUser) {
    const user = new User()
    user.id = firebaseUser.uid
    user.email = firebaseUser.email || this.email
    user.name = firebaseUser.displayName || this.name
    user.customer = this.customer
    user.firebaseUser = firebaseUser
    return user
  }

  copyWithCustomer(customer: Customer) {
    const user = new User()
    user.id = this.id
    user.email = this.email
    user.name = this.name
    user.customer = customer
    user.firebaseUser = this.firebaseUser
    return user
  }
}
