
import Vue from 'vue'
import Component from 'vue-class-component'

import AgeOptions from '@/ui/business-utils/age-options'
import SelectOption from '@/common/interfaces/select-option'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { Prop } from 'vue-property-decorator'

@Component
export default class QuestionnaireAge extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  ageOptions: SelectOption[] = AgeOptions.generate()

  get age(): number | null {
    return this.profile.age
  }

  setAge(value: number) {
    this.profile.age = value
  }

  get hasSpouse(): boolean {
    return this.profile.hasSpouse
  }

  get spouseAge(): number | null {
    return this.profile.spouse.age
  }

  setSpouseAge(value: number) {
    this.profile.spouse.age = value
  }

  get canContinue(): boolean {
    return !!this.profile.age && (!this.profile.hasSpouse || !!this.profile.spouse.age)
  }

  submit() {
    if (this.canContinue) {
      this.$emit('submit')
    }
  }
}
