
import { DIALOG_ANIMATION_DURATION } from '@/ui/components/AppDialog.vue'
import WindowManager from '@/ui/util/window-manager'
import Vue from 'vue'
import Component from 'vue-class-component'
import RootDialogController from './root-dialog-controller'
import { IRootDialog, RootDialogOptions } from './root-dialog-interface'

/**
 * Root dialog component, mounted on App component.
 * Controlled by the RootDialogController, which is injected into Vue
 * instances as a $dialog plugin.
 */
@Component
export default class RootDialog extends Vue implements IRootDialog {
  shown: boolean = false
  options: RootDialogOptions = {
    title: '',
    message: '',
    primaryBtnText: '',
    secondaryBtnText: '',
    cancelable: true,
    primaryFn: null,
    secondaryFn: null,
    dismissFn: null,
    onHide: null,
    component: null,
    props: null,
    large: false,
    noPadding: false,
  }

  isPrimaryButtonLoading = false

  mounted() {
    RootDialogController.bind(this)
  }

  beforeDestroy() {
    RootDialogController.unbind(this)
  }

  async show(options: RootDialogOptions) {
    if (this.shown) {
      this.shown = false
      await setTimeout(() => {})
    }

    this.assignOptions(options)
    this.shown = true

    if (WindowManager.scrollY == 0) {
      WindowManager.scrollTo(1)
    }
  }

  hide() {
    if (!this.shown) {
      return
    }

    this.shown = false
    if (this.options.onHide) {
      this.options.onHide()
    }

    setTimeout(() => {
      this.assignOptions({
        title: '',
        message: '',
        cancelable: true,
        primaryBtnText: '',
        secondaryBtnText: '',
        primaryFn: null,
        secondaryFn: null,
        dismissFn: null,
        onHide: null,
        component: null,
        props: null,
        large: false,
        width: void 0,
        noPadding: false,
      })
    }, DIALOG_ANIMATION_DURATION)
  }

  private assignOptions(options: RootDialogOptions) {
    this.options.title = options.title
    this.options.message = options.message
    this.options.cancelable = options.cancelable
    this.options.primaryBtnText = options.primaryBtnText
    this.options.secondaryBtnText = options.secondaryBtnText
    this.options.primaryFn = options.primaryFn
    this.options.secondaryFn = options.secondaryFn
    this.options.dismissFn = options.dismissFn
    this.options.onHide = options.onHide
    this.options.component = options.component
    this.options.props = options.props
    this.options.large = options.large || false
    this.options.width = options.width
    this.options.noPadding = options.noPadding || false
  }

  isCancelable(): boolean {
    return this.options.cancelable || true
  }

  setCancelable(cancelable: boolean) {
    this.options.cancelable = cancelable
  }

  isShown(): boolean {
    return this.shown
  }

  // Template API.

  onInput(shown: boolean) {
    if (!shown) {
      this.hide()
      RootDialogController.closedInternally()
      if (this.options.dismissFn) {
        this.options.dismissFn()
      }
    }
  }

  async onPrimaryClick(result: any) {
    let promise: Promise<any> | void
    if (this.options.primaryFn) {
      promise = this.options.primaryFn(result)
    }

    if (promise) {
      this.isPrimaryButtonLoading = true
      try {
        await promise
      } finally {
        this.isPrimaryButtonLoading = false
      }
    }

    this.hide()
    RootDialogController.closedInternally()
  }

  onSecondaryClick() {
    this.hide()
    RootDialogController.closedInternally()
    if (this.options.secondaryFn) {
      this.options.secondaryFn()
    }
  }

  onDismiss(result: any) {
    if (typeof result !== 'undefined') {
      this.onPrimaryClick(result)
    } else {
      this.onSecondaryClick()
    }
  }
}
