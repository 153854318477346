import svgConfig from '@/config/svg.config'

let _symbolsInitialized = false
let _symbols: {
  [key: string]: {
    viewBox: string | null
    svg: string
  }
}

export default class SvgUtils {
  static async init() {
    if (!_symbolsInitialized) {
      await svgConfig.ready

      if (!_symbols) {
        _symbols = {}

        const serializer = new XMLSerializer()

        const symbolTags = document.getElementsByTagName('symbol')
        for (let i = 0, len = symbolTags.length; i < len; i++) {
          const symbol = symbolTags[i]
          const svg = this._getSvgForSymbol(serializer, symbol)
          const id = symbol.getAttribute('id')
          if (id) {
            _symbols[id] = {
              viewBox: symbol.getAttribute('viewBox'),
              svg,
            }
          }
        }
      }

      _symbolsInitialized = true
    }
  }

  /**
   * We cant' just use innerHTML on svg elements, because it doesn't work
   * on older android devices.
   * @param {XMLSerializer} serializer An XMLSerializer instance
   * @param {SVGElement} symbol Symbol element.
   */
  private static _getSvgForSymbol(serializer: XMLSerializer, symbol: SVGElement) {
    const result = []
    const nodes = symbol.childNodes
    for (let i = 0, len = nodes.length; i < len; i++) {
      result.push(serializer.serializeToString(nodes[i]))
    }

    return result.join('')
  }

  static getSymbol(name: string) {
    const symbol = _symbols[`icon-${name}`]
    if (!symbol) {
      throw new Error(`SVG symbol with name '${name}' is not found`)
    }

    return symbol
  }
}
