import { SubscriptionClaim } from './subscription-claim.entity'
import { Permission } from './permission'

// Maps permissions to minimum required SubscriptionClaims. If null is specified,
// then no subscription claim is required for that permission.
const REQUIRED_CLAIMS: { [key in Permission]: SubscriptionClaim | null } = {
  app: SubscriptionClaim.STANDARD,
  statusCenter: SubscriptionClaim.STANDARD,
  eligibilityGuide: SubscriptionClaim.STANDARD,
  saveProfile: SubscriptionClaim.STANDARD,
  switchSpouse: SubscriptionClaim.STANDARD,
  guideProgress: SubscriptionClaim.STANDARD,
  poolGuide: SubscriptionClaim.STANDARD,
  itaGuide: SubscriptionClaim.PLUS,
  prGuide: SubscriptionClaim.PREMIUM,
}

export class AppPermissions {
  /**
   * Return true if the given claim provides access to the given permission.
   * If null is provided as the claim, then return true.
   */
  static check(claim: SubscriptionClaim | null, permission: Permission): boolean {
    const requiredClaim = REQUIRED_CLAIMS[permission]
    if (requiredClaim) {
      return !!claim && claim >= requiredClaim
    } else {
      return true
    }
  }

  /**
   * Return true if the given permission is available in the Free plan.
   */
  static isAvailableForFree(permission: Permission): boolean {
    return this.check(null, permission)
  }
}
