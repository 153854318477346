import { OperationError, OperationResult, OperationSuccess } from '@/data/api/operation-result'
import { Collection, Product } from 'shared-entities'
import asyncFirestore from '../firebase/async-firestore'

export default class ProductsRepository {
  static async getProducts(): Promise<OperationResult<Product[]>> {
    try {
      const firestore = await asyncFirestore()
      const productsSnap = await firestore
        .collection(Collection.PRODUCTS)
        .where('isActive', '==', true)
        .get()

      let features: string[] = []
      const products = productsSnap.docs.map(snapshot => {
        const product = snapshot.data() as Product

        // Each consecutive product in the ordering includes the features from the product before it.
        product.features = [...features, ...product.features]
        features = product.features

        return product
      })

      products.sort((first, second) => first.ordering - second.ordering)

      return new OperationSuccess(products)
    } catch (error) {
      return OperationError.fromError(error)
    }
  }
}
