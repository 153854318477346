const MSG_REQUIRED = 'This field is required'

const PATTERN_PHONE_NUMBER = /^\+7 \(\d{3}\) \d{3} \d{4}$/
const PATTERN_PHONE_NUMBER_DIGITS = /^\+?\d{11}$/
const PATTERN_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class Validators {
  [key: string]: (v: any) => boolean | string

  required(v: any): boolean | string {
    return !!v || MSG_REQUIRED
  }

  phoneNumber(v: any): boolean | string {
    if (!v) {
      return MSG_REQUIRED
    }

    if (!PATTERN_PHONE_NUMBER.test(v) && !PATTERN_PHONE_NUMBER_DIGITS.test(v)) {
      return 'Invalid format'
    }

    return true
  }

  email(v: any): boolean | string {
    if (!v) {
      return MSG_REQUIRED
    }

    if (!PATTERN_EMAIL.test(v)) {
      return 'Invalid format'
    }

    return true
  }
}

export default new Validators()
