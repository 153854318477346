import { NocGroup } from '../noc-group'

export enum CecWorkExpType {
  NOC_0_A = NocGroup.NOC_00 | NocGroup.NOC_0 | NocGroup.NOC_A,
  NOC_B = NocGroup.NOC_B,
}

/** The profile fields used for CEC program eligibility calculation. */
export interface CecFields {
  /** Whether the user has at least 1 year of Canadian work experience within last 3 years */
  hasWorkExp: boolean
  /** The NOC groups that the applicant has the most experience in. */
  workExpType: CecWorkExpType | null
}
