
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class AppDisabledOverlay extends Vue {
  @Prop({ type: Boolean, default: false })
  disabled!: boolean
}
