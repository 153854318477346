
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class AuthEmailSentView extends Vue {
  @Prop({ type: String, required: true })
  email!: string

  @Prop({ type: Boolean, default: false })
  resending!: boolean

  isMounted = false

  mounted() {
    this.isMounted = true
  }
}
