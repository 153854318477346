import Environment from '@/config/environment'
import { AuthTypes } from '@/store/modules/auth.module'
import store from '@/store/store'
import { FirebaseError } from '@firebase/util'
import firebase from 'firebase/compat/app'
import { Claims, Collection, Customer, SubscriptionUtils } from 'shared-entities'
import { OperationError, OperationResult, OperationSuccess } from '../api/operation-result'
import User from '../entity/user.entity'
import asyncFirestore from '../firebase/async-firestore'
import FirestoreUtils from '../firebase/firestore-utils'
import LocalStorage from '../util/local-storage'
import ProfileRepository from './profile.repository'

// Local storage keys
/** Currently authenticated user */
const LS_KEY_USER = 'app_user'
/** The email with which the user is attempting to sign in */
const LS_KEY_SIGN_IN_EMAIL = 'app_signInEmail'
/** True if a redirect is expected from Google/Facebook after authentication attempt */
const LS_KEY_REDIRECT_EXPECTED = 'app_authRedirectExpected'
const LS_KEY_PENDING_CREDENTIAL = 'app_pendingCredential'

export enum AuthStatus {
  /** Successful authentication */
  SUCCESS = 'success',
  /** Authentication was not necessary */
  NONE = 'none',
  /**
   * Attempted authentication with Facebook, but the account was missing an
   * email address, or the user didn't provide access to the email address.
   */
  ERROR_FB_EMAIL_MISSING = 'fbEmailMissing',

  /**
   * Attempted authentication with Facebook, but an account with the
   * corresponding email address was already registered with an emailLink provider.
   * Ask the user to prove that they have access to the email address by sending
   * them a sign in link.
   */
  ERROR_ACCOUNT_EXISTS_EMAIL_LINK = 'accountExistsEmailLink',
  /**
   * Same as above, but an account already was created with a Google credential.
   * Ask the user to sign in using their Google account.
   */
  ERROR_ACCOUNT_EXISTS_GOOGLE = 'accountExistsGoogle',
}

export class AuthResult {
  email?: string
  pendingCredential?: firebase.auth.AuthCredential

  constructor(public status: AuthStatus) {}

  get isSuccessful(): boolean {
    return this.status === AuthStatus.SUCCESS
  }

  get isNone(): boolean {
    return this.status === AuthStatus.NONE
  }

  static success(): AuthResult {
    return new AuthResult(AuthStatus.SUCCESS)
  }

  static none(): AuthResult {
    return new AuthResult(AuthStatus.NONE)
  }
}

type UserListener = (user: User | null) => void
type CustomerListener = (customer: Customer | null) => void

export default class AuthRepository {
  private static auth: firebase.auth.Auth

  private static _user: User | null = null
  private static _isFirebaseUserInitialized = false
  private static _unsubscribeFromCustomer: (() => void) | null = null

  private static _userListeners: UserListener[] = []
  private static _customerListeners: CustomerListener[] = []
  private static _tokenListeners: (() => void)[] = []

  /**
   * Initialize the repository. Should be called from the `mounted()` callback in the root
   * App component.
   */
  static init() {
    this.auth = firebase.auth()
    this._user = this.getUserFromLocalStorage()

    if (this._user) {
      store.dispatch(AuthTypes.actions.setUser, this._user)
    }

    this.auth.onAuthStateChanged((firebaseUser: firebase.User | null) => {
      this.updateFirebaseUser(firebaseUser)

      if (firebaseUser) {
        this.subscribeToCustomer(firebaseUser)
        ProfileRepository.updateGuideProgressSubscription()
        ProfileRepository.updateStatusCenterSubscription()
      } else {
        this.unsubscribeFromCustomer()
        ProfileRepository.unsubscribeFromGuideProgress()
        ProfileRepository.unsubscribeFromStatusCenterProgress()
      }
    })
  }

  /**
   * Update the current user with the passed Firebase user.
   */
  static updateFirebaseUser(firebaseUser: firebase.User | null) {
    let user: User | null = null
    if (firebaseUser && firebaseUser.email) {
      const localUser = this.getUser()
      if (localUser && firebaseUser.uid === localUser.id) {
        user = localUser.copyWithFirebaseUser(firebaseUser)
      } else {
        user = User.fromFirebaseUser(firebaseUser)
      }
    }

    this.setUser(user)
    store.dispatch(AuthTypes.actions.setUser, user)
  }

  /**
   * Update the current user with the current Firebase user.
   */
  static refreshFirebaseUser() {
    this.updateFirebaseUser(this.auth.currentUser)
  }

  /**
   * Subscribe to the changes of the customer object associated with the passed
   * firebase User.
   */
  private static subscribeToCustomer(firebaseUser: firebase.User) {
    asyncFirestore().then(firestore => {
      this.unsubscribeFromCustomer()

      this._unsubscribeFromCustomer = firestore
        .collection(Collection.CUSTOMERS)
        .doc(firebaseUser.uid)
        .onSnapshot(
          async snapshot => {
            let customer = snapshot.data() as Customer
            const user = this.getUser()
            if (customer && user) {
              customer = FirestoreUtils.convertTimestamps(customer)

              // Refresh the ID token if the custom claims have changed.
              const shouldRefreshToken = await this.shouldRefreshToken(user, customer)
              if (shouldRefreshToken) {
                await this.refreshIdToken(user)
              }

              const updatedUser = user.copyWithCustomer(customer)
              this.setUser(updatedUser)
              store.dispatch(AuthTypes.actions.setUser, updatedUser)

              ProfileRepository.updateGuideProgressSubscription()
            }
          },
          error => {
            console.error(error)
            setTimeout(() => {
              const user = this.auth.currentUser
              if (user) {
                this.subscribeToCustomer(user)
              }
            }, 200)
          }
        )
    })
  }

  /**
   * Determine whether the Firebase ID token should be refreshed based on the custom claims
   * that should be provided by the subscription in the new customer object.
   *
   * @param user The current user object.
   * @param newCustomer The updated customer object.
   */
  private static async shouldRefreshToken(user: User, newCustomer: Customer): Promise<boolean> {
    const claims = (await user.getClaims()) as Claims
    if (newCustomer.subscription && newCustomer.subscription.product) {
      const productClaim = newCustomer.subscription.product.claim
      return productClaim !== claims.subscription
    }
    return !!claims.subscription
  }

  /**
   * Refresh the Firebase ID token.
   * @param user The current user object.
   */
  private static async refreshIdToken(user: User): Promise<any> {
    if (user.firebaseUser) {
      console.info('Refreshing ID token...')
      await user.firebaseUser.getIdToken(true)
      this._tokenListeners.forEach(listener => listener())
    }
    return null
  }

  private static unsubscribeFromCustomer() {
    if (this._unsubscribeFromCustomer) {
      this._unsubscribeFromCustomer()
      this._unsubscribeFromCustomer = null
    }
  }

  /**
   * Set Firebase auth's locale. It is used when sending sign in emails and other
   * cases where user-facing messages are generated.
   */
  static setLocale(locale: string) {
    this.auth.languageCode = locale
  }

  /**
   * Request a sign in link to the provided email address.
   * Resolves with true if successful.
   */
  static async sendSignInLinkToEmail(email: string): Promise<OperationResult<boolean>> {
    const actionCodeSettings: firebase.auth.ActionCodeSettings = {
      url: Environment.isProduction()
        ? 'https://app.expresscanada.me/'
        : 'https://testapp.expresscanada.me/',
      handleCodeInApp: true,
    }

    try {
      await this.auth.sendSignInLinkToEmail(email, actionCodeSettings)
      LocalStorage.putItem(LS_KEY_SIGN_IN_EMAIL, email)
      return new OperationSuccess(true)
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * Begin the sign in with Google authentication flow.
   * If successful, then the page is redirected to a Google accounts page.
   */
  static async signInWithGoogle(): Promise<OperationResult<any>> {
    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      LocalStorage.putItem(LS_KEY_REDIRECT_EXPECTED, true)
      await this.auth.signInWithRedirect(provider)
      return new OperationError({ errorMessage: 'Did not redirect' })
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * Begin the sign in with Facebook authentication flow.
   * If successful, then the page is redirected to Facebook.
   */
  static async signInWithFacebook(): Promise<OperationResult<any>> {
    try {
      const provider = new firebase.auth.FacebookAuthProvider()
      provider.addScope('email')
      LocalStorage.putItem(LS_KEY_REDIRECT_EXPECTED, true)
      await this.auth.signInWithRedirect(provider)
      return new OperationError({ errorMessage: 'Did not redirect' })
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * Link the current user account with an additional authentication provider.
   *
   * @param providerId The Firebase authentication provider ID
   * @returns An error in case if redirect did not happen.
   */
  static async linkWithProvider(providerId: string): Promise<OperationResult<any>> {
    try {
      const firebaseUser = this.auth.currentUser
      if (!firebaseUser) {
        return new OperationError({ errorMessage: 'User not logged in' })
      }

      let provider
      if (providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID) {
        provider = new firebase.auth.FacebookAuthProvider()
        provider.addScope('email')
      } else if (providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
        provider = new firebase.auth.GoogleAuthProvider()
      } else {
        return new OperationError({ errorMessage: `Invalid providerId ${providerId}` })
      }

      LocalStorage.putItem(LS_KEY_REDIRECT_EXPECTED, true)
      await firebaseUser.linkWithRedirect(provider)

      return new OperationError({ errorMessage: 'Did not redirect' })
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * Sign the user in if the current location corresponds to a sign-in email link or
   * if an auth redirect is expected.
   * Resolve with AuthResult, whose status will define the result of the sign in operation.
   */
  static async signInIfNeeded(): Promise<OperationResult<AuthResult>> {
    if (this.auth.isSignInWithEmailLink(window.location.href)) {
      const email = LocalStorage.getItem(LS_KEY_SIGN_IN_EMAIL)
      if (email) {
        try {
          const result = await this.auth.signInWithEmailLink(email, window.location.href)
          const firebaseUser = result.user
          if (firebaseUser) {
            this.linkWithPendingCredentialIfNeeded(firebaseUser)
            this.setUser(User.fromFirebaseUser(firebaseUser))
            LocalStorage.removeItem(LS_KEY_SIGN_IN_EMAIL)
            return new OperationSuccess(AuthResult.success())
          }
        } catch (error) {
          return OperationError.fromError(error)
        }
      } else {
        return new OperationError({
          errorMessage:
            'The sign-in link is invalid or has already been used. Make sure you use ' +
            'the same browser to open the link.',
        })
      }
    } else if (LocalStorage.getBoolean(LS_KEY_REDIRECT_EXPECTED)) {
      return this.signInFromRedirect()
    }

    return new OperationSuccess(AuthResult.none())
  }

  /**
   * If a sign in redirect was previously initiated, retrieve
   * the redirect result and sign in.
   */
  private static async signInFromRedirect(): Promise<OperationResult<AuthResult>> {
    try {
      const credential = await this.auth.getRedirectResult()
      const firebaseUser = credential.user
      LocalStorage.removeItem(LS_KEY_REDIRECT_EXPECTED)
      if (firebaseUser) {
        if (firebaseUser.email) {
          await this.linkWithPendingCredentialIfNeeded(firebaseUser)
          this.setUser(User.fromFirebaseUser(firebaseUser))
          return new OperationSuccess(AuthResult.success())
        } else {
          await this.signOut()
          return new OperationSuccess(new AuthResult(AuthStatus.ERROR_FB_EMAIL_MISSING))
        }
      } else {
        return new OperationSuccess(AuthResult.none())
      }
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.error(`Error code: ${error.code}`)
        if (error.code === 'auth/account-exists-with-different-credential') {
          return this.processExistingAccountError(error)
        }
      }
      return OperationError.fromError(error)
    }
  }

  /**
   * If a pending credential exists in local storage, attempt to link
   * the provided user with the pending credential.
   */
  private static async linkWithPendingCredentialIfNeeded(
    firebaseUser: firebase.User
  ): Promise<any> {
    const pendingCredential = LocalStorage.getItem(LS_KEY_PENDING_CREDENTIAL)
    if (pendingCredential) {
      if (pendingCredential.providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID) {
        const credential = firebase.auth.FacebookAuthProvider.credential(
          pendingCredential.oauthAccessToken
        )

        await firebaseUser.linkWithCredential(credential)
        LocalStorage.removeItem(LS_KEY_PENDING_CREDENTIAL)
      }
    }
  }

  /**
   * Process the error raised in case of a sign in attempt with an account for which
   * an authentication credential already exists.
   */
  private static async processExistingAccountError(
    error: any
  ): Promise<OperationResult<AuthResult>> {
    const email = error.email
    const providers = await this.auth.fetchSignInMethodsForEmail(email)
    let authResult: AuthResult | null = null
    if (this.hasGoogleAuthProvider(providers)) {
      authResult = new AuthResult(AuthStatus.ERROR_ACCOUNT_EXISTS_GOOGLE)
    } else if (this.hasEmailLinkProvider(providers)) {
      authResult = new AuthResult(AuthStatus.ERROR_ACCOUNT_EXISTS_EMAIL_LINK)
    }

    if (authResult) {
      authResult.email = email
      authResult.pendingCredential = error.credential
      return new OperationSuccess(authResult)
    } else {
      return OperationError.fromError(error)
    }
  }

  /**
   * If a previous sign in attempt resulted in an error status due to account
   * being unlinked, this function is called to link the account after the user
   * gives their consent.
   */
  static async linkAccount(authResult: AuthResult): Promise<OperationResult<boolean>> {
    try {
      const { email, pendingCredential } = authResult
      if (email && pendingCredential) {
        if (authResult.status === AuthStatus.ERROR_ACCOUNT_EXISTS_GOOGLE) {
          const googleProvider = new firebase.auth.GoogleAuthProvider()
          // Attempt to sign in with the same email
          googleProvider.setCustomParameters({
            login_hint: email,
          })

          LocalStorage.putItem(LS_KEY_REDIRECT_EXPECTED, true)
          LocalStorage.putItem(LS_KEY_PENDING_CREDENTIAL, pendingCredential)

          await this.auth.signInWithRedirect(googleProvider)
          return new OperationError({ errorMessage: 'Did not redirect' })
        } else if (authResult.status === AuthStatus.ERROR_ACCOUNT_EXISTS_EMAIL_LINK) {
          LocalStorage.putItem(LS_KEY_PENDING_CREDENTIAL, pendingCredential)

          const result = await this.sendSignInLinkToEmail(email)
          if (result.isSuccessful) {
            return new OperationSuccess(true)
          } else {
            return result
          }
        } else {
          throw new Error(
            `AuthRepository.linkAccount called with AuthResult with invalid state ${authResult.status}`
          )
        }
      } else {
        throw new Error('Missing email and pending credential in AuthResult')
      }
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * A pending credential is stored in local storage before attempting to link
   * the account with another auth provider. If the linking operation
   * is rejected, the pending credentials need to be cleared.
   */
  static clearPendingCredential() {
    LocalStorage.removeItem(LS_KEY_REDIRECT_EXPECTED)
    LocalStorage.removeItem(LS_KEY_PENDING_CREDENTIAL)
  }

  /**
   * Unlink the given authentication provider from the current user's account.
   * @param providerId The ID of the authentication provider.
   */
  static async unlinkProvider(providerId: string): Promise<OperationResult<boolean>> {
    try {
      const user = this.getUser()
      if (user && user.firebaseUser) {
        await user.firebaseUser.unlink(providerId)
        this.refreshFirebaseUser()
        return new OperationSuccess(true)
      } else {
        return new OperationError({ errorMessage: 'User not logged in' })
      }
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  private static hasGoogleAuthProvider(providers: string[]): boolean {
    return providers.indexOf(firebase.auth.GoogleAuthProvider.PROVIDER_ID) >= 0
  }

  private static hasEmailLinkProvider(providers: string[]): boolean {
    return providers.indexOf('emailLink') >= 0
  }

  /**
   * Get the currently signed in user, or null.
   */
  static getUser(): User | null {
    return this._user
  }

  /**
   * Return a promise that is resolved with the user object (or null) as soon as possible.
   * If the user is already initialized, then the promise is resolved immediately.
   */
  static asyncUser(): Promise<User | null> {
    if (this._isFirebaseUserInitialized) {
      return Promise.resolve(this.getUser())
    } else {
      let resolve: UserListener

      this._userListeners.push(user => {
        resolve(user)
      })

      return new Promise(resolveFn => {
        resolve = resolveFn
      })
    }
  }

  static async asyncCustomer(): Promise<Customer | null> {
    const user = await this.asyncUser()
    if (user && user.customer) {
      return user.customer
    } else {
      let resolve: CustomerListener
      const customerListener: CustomerListener = customer => {
        resolve(customer)
        this.removeCustomerListener(customerListener)
      }

      this.addCustomerListener(customerListener)

      return new Promise(resolveFn => {
        resolve = resolveFn
      })
    }
  }

  /**
   * Return true if the current user has an active subscription.
   */
  static userHasSubscription(): boolean {
    const user = this.getUser()
    return !!(
      user &&
      user.customer &&
      SubscriptionUtils.isSubscriptionActive(user.customer.subscription)
    )
  }

  /**
   * Subscribe the given listener to Customer changes.
   */
  static addCustomerListener(listener: CustomerListener) {
    this._customerListeners.push(listener)
  }

  static removeCustomerListener(listener: CustomerListener) {
    const index = this._customerListeners.indexOf(listener)
    if (index >= 0) {
      this._customerListeners.splice(index, 1)
    }
  }

  /**
   * Set the currently signed in user. If set to null, then the previously
   * signed in user will be considered signed out.
   */
  static setUser(user: User | null) {
    this._user = user
    this.putUserToLocalStorage(user)
    this._isFirebaseUserInitialized = true
    this._userListeners.forEach(listener => listener(user))
    this._userListeners.splice(0, this._userListeners.length)

    if (user) {
      const customer = user.customer || null
      this._customerListeners.forEach(listener => {
        if (listener) {
          listener(customer)
        }
      })
    } else {
      this._customerListeners.forEach(listener => listener(null))
    }
  }

  private static getUserFromLocalStorage(): User | null {
    const user = LocalStorage.getObject(LS_KEY_USER, User)
    const customer = user ? user.customer : null
    const subscription = customer ? customer.subscription : null

    if (subscription && subscription.lastUpdated) {
      subscription.lastUpdated = new Date(subscription.lastUpdated)
    }
    return user
  }

  private static putUserToLocalStorage(user: User | null) {
    if (user) {
      LocalStorage.putObject(LS_KEY_USER, user)
    } else {
      LocalStorage.removeItem(LS_KEY_USER)
    }
  }

  static addTokenChangedListener(listener: () => void) {
    this._tokenListeners.push(listener)
  }

  static removeTokenChangedListener(listener: () => void) {
    const index = this._tokenListeners.indexOf(listener)
    if (index >= 0) {
      this._tokenListeners.splice(index, 1)
    }
  }

  /**
   * Sign out the current user.
   */
  static async signOut(): Promise<OperationResult<boolean>> {
    try {
      await this.auth.signOut()
      return new OperationSuccess(true)
    } catch (error) {
      return OperationError.fromError(error)
    }
  }
}
