import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import store from '@/store/store'
import { AuthTypes } from '@/store/modules/auth.module'
import User from '@/data/entity/user.entity'
import Environment from '@/config/environment'

const http = axios.create({
  timeout: 30000,
  validateStatus: status => status < 500,
  // TODO configure actual baseURL.
  baseURL: Environment.isProduction()
    ? 'https://europe-west1-prod-immigo.cloudfunctions.net/api'
    : 'https://europe-west1-test-immigo.cloudfunctions.net/api',
  headers: {
    Accept: 'application/json',
  },
})

export const HEADER_FIREBASE_AUTH = 'X-Authorization-Firebase'

http.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const user = store.state.auth.user as User
  if (user) {
    if (!config.headers[HEADER_FIREBASE_AUTH] && user.firebaseUser) {
      const token = await user.firebaseUser.getIdToken()
      if (token) {
        config.headers[HEADER_FIREBASE_AUTH] = token
      }
    }
  }

  return config
})

http.interceptors.response.use((config: AxiosResponse) => {
  if (config.status === 401) {
    store.dispatch(AuthTypes.actions.signOut)
  }
  return config
})

export { AxiosRequestConfig as RequestConfig, AxiosResponse as Response }

export default http
