interface Array<T> {
  lastElement(): T

  sum(getter?: (item: T) => number): number

  reduceTyped<R>(initialValue: R, reducer: (acc: R, item: T) => R): R
}

Array.prototype.lastElement = function() {
  if (this.length > 0) {
    return this[this.length - 1]
  } else {
    throw new Error('Cannot return last element: array is empty.')
  }
}

Array.prototype.sum = function<T>(getter?: (item: T) => number): number {
  if (getter) {
    return this.map(getter).reduce((total, item) => total + item, 0)
  } else if (this.length) {
    return this.reduce((total, item) => total + item, 0)
  } else {
    return 0
  }
}

Array.prototype.reduceTyped = function<T, R>(initialValue: R, reducer: (acc: R, item: T) => R): R {
  let result = initialValue
  for (let i = 0; i < this.length; i++) {
    const item = this[i]
    result = reducer(result, item)
  }
  return result
}
