import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import EligibilityCriterionResult from '@/data/entity/programs/eligibility-criterion-result.entity'
import EligibilityCriterion from '@/data/entity/programs/eligibility-criterion.entity'
import ProgramEligibility from '@/data/entity/programs/program-eligibility.entity'
import ProgramId from '@/data/entity/programs/program-id.entity'
import Program from '@/data/entity/programs/program.entity'
import EligibilityCalculatorUtils from '../utils/eligibility-calculator-utils'
import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'
import { LanguageTestScoresUtils, EducationLevel } from 'shared-entities'
import ProofOfFundsCalculator from '../proof-of-funds-calculator'
import FswPointCalculator from './fsw-point-calculator'

const languageCriterion: EligibilityCriterion = {
  title: 'language',
  description: 'English or French skills: CLB 7',
}

const workExpTypeCriterion: EligibilityCriterion = {
  title: 'workExpType',
  description: 'Canadian or foreign experience in 1 of these NOCs: 0, A or B',
}

const workExpAmountCriterion: EligibilityCriterion = {
  title: 'workExp',
  description:
    'One year continuous within the last 10 years (combination ' +
    'of part-time, full-time or more than 1 job in your primary occupation)',
}

const jobOfferCriterion: EligibilityCriterion = {
  title: 'jobOffer',
  description:
    'Not required. But you can get selection criteria (FSW) points for having a valid job offer.',
}

const educationCriterion: EligibilityCriterion = {
  title: 'education',
  description:
    'Secondary education required. You can get more selection criteria (FSW) points for your post-secondary education.',
}

const pointsCriterion: EligibilityCriterion = {
  title: 'fswSelectionFactors',
  description: '',
}

export const FSW_PROGRAM: Program = {
  id: ProgramId.FSW,
  title: 'Federal Skilled Worker',
  shortTitle: 'FSW',
  description:
    'This program is for skilled workers with foreign work experience who want to immigrate to Canada permanently.',
  link:
    'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/federal-skilled-workers.html',
  eligibilityCriteria: [
    languageCriterion,
    workExpTypeCriterion,
    { ...workExpAmountCriterion, title: 'workExpAmount' },
    jobOfferCriterion,
    educationCriterion,
  ],
}

export default class FswEligibility {
  static calculate(profile: EditableProfile): ProgramEligibility {
    const criteria: EligibilityCriterionResult[] = [
      this.language(profile),
      this.education(profile),
      this.workExp(profile),
      this.points(profile),
      ProofOfFundsCalculator.calculate({ profile, programId: ProgramId.FSW }),
    ]

    return {
      program: FSW_PROGRAM,
      status: EligibilityCalculatorUtils.calculateProgramEligibility(criteria),
      criteria,
    }
  }

  private static language(profile: EditableProfile): EligibilityCriterionResult {
    let status = EligibilityStatus.INFO_NEEDED
    const firstLangScores = profile.getFirstLanguageClbScores()
    if (firstLangScores) {
      status = LanguageTestScoresUtils.everySkill(firstLangScores, skill => skill >= 7)
        ? EligibilityStatus.ELIGIBLE
        : EligibilityStatus.NOT_ELIGIBLE
    }

    return {
      criterion: languageCriterion,
      status,
    }
  }

  private static education(profile: EditableProfile): EligibilityCriterionResult {
    return {
      criterion: educationCriterion,
      status: profile.education
        ? profile.education >= EducationLevel.SECONDARY
          ? EligibilityStatus.ELIGIBLE
          : EligibilityStatus.NOT_ELIGIBLE
        : EligibilityStatus.INFO_NEEDED,
    }
  }

  private static workExp(profile: EditableProfile): EligibilityCriterionResult {
    return {
      criterion: workExpAmountCriterion,
      status: this.hasWorkExp(profile)
        ? EligibilityStatus.ELIGIBLE
        : EligibilityStatus.NOT_ELIGIBLE,
    }
  }

  private static hasWorkExp(profile: EditableProfile): boolean {
    return profile.totalWorkExp >= 1 && profile.eligibility.fsw.hasWorkExp
  }

  private static points(profile: EditableProfile): EligibilityCriterionResult {
    const points = FswPointCalculator.calculate(profile)
    return {
      criterion: pointsCriterion,
      status: points >= 67 ? EligibilityStatus.ELIGIBLE : EligibilityStatus.NOT_ELIGIBLE,
      points,
      maxPoints: 100,
    }
  }
}
