import SelectOption from '@/common/interfaces/select-option'
import OfficialLanguage from '@/data/entity/profile/official-language'
import { LanguageTestType, LanguageTestScores, LanguageSkill } from 'shared-entities'

/**
 * Minimum scrore in particular test type for a particular skill
 * (reading, writing etc) to CLB score mapping, ordered from minimum score to maximum
 * [[score, CLBScore], ...]
 */
export type CLBSkillMapping = Array<{
  minScore: number
  clb: number
}>

/**
 * CLBSkillMapping for every language skill.
 */
type CLBMapping = {
  [skill in LanguageSkill]: CLBSkillMapping
}

/**
 * Maximum possible scores for each skill, used for input validation.
 */
type MaxScores = {
  [skill in LanguageSkill]: number
}

/**
 * Defines parameters and operations for a single language test (such as IELTS).
 */
export class LanguageTest implements SelectOption {
  constructor(
    public readonly language: OfficialLanguage,
    public readonly type: LanguageTestType,
    public readonly description: string,
    public readonly maxScores: MaxScores,
    public readonly clbMapping: CLBMapping
  ) {}

  static none(): LanguageTest {
    return new LanguageTest(OfficialLanguage.NONE, 'NONE', 'None', null!, null!)
  }

  get id(): LanguageTestType {
    return this.type
  }

  get title(): string {
    return `profile.language.tests.${this.type}`
  }

  get languageTitle(): string {
    switch (this.language) {
      case OfficialLanguage.ENGLISH:
        return 'English'
      case OfficialLanguage.FRENCH:
        return 'French'
      default:
        throw new Error(`Invalid language: ${this.language}`)
    }
  }

  convertClbScore(clb: number, skill: LanguageSkill): number {
    const skillMapping = this.clbMapping[skill]
    const mapping = skillMapping.find(it => it.clb === clb)
    if (mapping) {
      return mapping.minScore
    }
    return 0
  }
}

// CELPIP score maps directly to CLB
function initCelpipMapping(): CLBMapping {
  const celpipMapping: CLBMapping = { r: [], w: [], l: [], s: [] }

  for (let i = 4; i <= 10; i++) {
    celpipMapping.r.push({ minScore: i, clb: i })
    celpipMapping.w.push({ minScore: i, clb: i })
    celpipMapping.l.push({ minScore: i, clb: i })
    celpipMapping.s.push({ minScore: i, clb: i })
  }

  return celpipMapping
}

// CLB score mappings. See
// https://www.canada.ca/en/immigration-refugees-citizenship/corporate/publications-manuals/operational-bulletins-manuals/standard-requirements/language-requirements/test-equivalency-charts.html

const CELPIP = new LanguageTest(
  OfficialLanguage.ENGLISH,
  'CELPIP',
  'Canadian English Language Proficiency Index Program',
  { r: 10, w: 10, l: 10, s: 10 },
  initCelpipMapping()
)

const IELTS = new LanguageTest(
  OfficialLanguage.ENGLISH,
  'IELTS',
  'International English Language Testing System',
  { r: 9, w: 9, l: 9, s: 9 },
  {
    r: [
      { minScore: 1, clb: 1 },
      { minScore: 1.5, clb: 2 },
      { minScore: 2.5, clb: 3 },
      { minScore: 3.5, clb: 4 },
      { minScore: 4, clb: 5 },
      { minScore: 5, clb: 6 },
      { minScore: 6, clb: 7 },
      { minScore: 6.5, clb: 8 },
      { minScore: 7, clb: 9 },
      { minScore: 8, clb: 10 },
    ],
    w: [
      { minScore: 4, clb: 4 },
      { minScore: 5, clb: 5 },
      { minScore: 5.5, clb: 6 },
      { minScore: 6, clb: 7 },
      { minScore: 6.5, clb: 8 },
      { minScore: 7, clb: 9 },
      { minScore: 7.5, clb: 10 },
    ],
    l: [
      { minScore: 4.5, clb: 4 },
      { minScore: 5, clb: 5 },
      { minScore: 5.5, clb: 6 },
      { minScore: 6, clb: 7 },
      { minScore: 7.5, clb: 8 },
      { minScore: 8, clb: 9 },
      { minScore: 8.5, clb: 10 },
    ],
    s: [
      { minScore: 4, clb: 4 },
      { minScore: 5, clb: 5 },
      { minScore: 5.5, clb: 6 },
      { minScore: 6, clb: 7 },
      { minScore: 6.5, clb: 8 },
      { minScore: 7, clb: 9 },
      { minScore: 7.5, clb: 10 },
    ],
  }
)

const TEF = new LanguageTest(
  OfficialLanguage.FRENCH,
  'TEF',
  'Test d’évaluation de français pour le Canada',
  { r: 300, w: 450, l: 360, s: 450 },
  {
    r: [
      { minScore: 50, clb: 1 },
      { minScore: 70, clb: 2 },
      { minScore: 100, clb: 3 },
      { minScore: 121, clb: 4 },
      { minScore: 151, clb: 5 },
      { minScore: 181, clb: 6 },
      { minScore: 207, clb: 7 },
      { minScore: 233, clb: 8 },
      { minScore: 248, clb: 9 },
      { minScore: 263, clb: 10 },
    ],
    w: [
      { minScore: 181, clb: 4 },
      { minScore: 226, clb: 5 },
      { minScore: 271, clb: 6 },
      { minScore: 310, clb: 7 },
      { minScore: 349, clb: 8 },
      { minScore: 371, clb: 9 },
      { minScore: 393, clb: 10 },
    ],
    l: [
      { minScore: 145, clb: 4 },
      { minScore: 181, clb: 5 },
      { minScore: 217, clb: 6 },
      { minScore: 249, clb: 7 },
      { minScore: 280, clb: 8 },
      { minScore: 298, clb: 9 },
      { minScore: 316, clb: 10 },
    ],
    s: [
      { minScore: 181, clb: 4 },
      { minScore: 226, clb: 5 },
      { minScore: 271, clb: 6 },
      { minScore: 310, clb: 7 },
      { minScore: 349, clb: 8 },
      { minScore: 371, clb: 9 },
      { minScore: 393, clb: 10 },
    ],
  }
)

const TCF = new LanguageTest(
  OfficialLanguage.FRENCH,
  'TCF',
  'Test de connaissance du français pour le Canada',
  { r: 699, w: 20, l: 699, s: 20 },
  {
    r: [
      { minScore: 342, clb: 4 },
      { minScore: 375, clb: 5 },
      { minScore: 406, clb: 6 },
      { minScore: 453, clb: 7 },
      { minScore: 499, clb: 8 },
      { minScore: 524, clb: 9 },
      { minScore: 549, clb: 10 },
    ],
    w: [
      { minScore: 4, clb: 4 },
      { minScore: 6, clb: 5 },
      { minScore: 7, clb: 6 },
      { minScore: 10, clb: 7 },
      { minScore: 12, clb: 8 },
      { minScore: 14, clb: 9 },
      { minScore: 16, clb: 10 },
    ],
    l: [
      { minScore: 331, clb: 4 },
      { minScore: 369, clb: 5 },
      { minScore: 398, clb: 6 },
      { minScore: 458, clb: 7 },
      { minScore: 503, clb: 8 },
      { minScore: 523, clb: 9 },
      { minScore: 549, clb: 10 },
    ],
    s: [
      { minScore: 4, clb: 4 },
      { minScore: 6, clb: 5 },
      { minScore: 7, clb: 6 },
      { minScore: 10, clb: 7 },
      { minScore: 12, clb: 8 },
      { minScore: 14, clb: 9 },
      { minScore: 16, clb: 10 },
    ],
  }
)

const LANGUAGE_TESTS = [CELPIP, IELTS, TEF, TCF]
export const LANGUAGE_TEST_NONE = LanguageTest.none()
export const ALL_LANGUAGE_TESTS = [LANGUAGE_TEST_NONE, ...LANGUAGE_TESTS]
export const LANGUAGE_TESTS_MAP: { [key in LanguageTestType]: LanguageTest } = {
  NONE: LANGUAGE_TEST_NONE,
  CELPIP,
  IELTS,
  TEF,
  TCF,
}

export default LANGUAGE_TESTS
