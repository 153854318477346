
import Vue from 'vue'
import debounce from 'lodash/debounce'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

const LOADING_DEBOUNCE_DURATION_MS = 150

@Component
export default class AppLoading extends Vue {
  @Prop({ type: Boolean, default: false })
  loading!: boolean
  @Prop({ type: String, default: 'absolute' })
  position!: string
  @Prop({ type: Boolean, default: false })
  immediate!: boolean
  @Prop({ type: [Number, String] })
  minHeight?: number | string

  showLoading = false

  private debouncedSetLoading!: () => void

  created() {
    this.debouncedSetLoading = debounce(() => {
      if (this.loading) {
        this.showLoading = true
      }
    }, LOADING_DEBOUNCE_DURATION_MS)

    this.onLoadingChanged(this.loading)
  }

  get minHeightPixels(): string | null {
    if (this.minHeight) {
      return `${this.minHeight}px`
    }
    return null
  }

  @Watch('loading')
  onLoadingChanged(value: boolean) {
    if (value) {
      if (this.immediate) {
        this.showLoading = true
      } else {
        this.debouncedSetLoading()
      }
    } else {
      this.showLoading = false
    }
  }
}
