
import Vue from 'vue'
import Component from 'vue-class-component'
import GalleryImage from '@/common/plugins/gallery/gallery-image'
import { Prop } from 'vue-property-decorator'
import UiUtils from '../util/ui-utils'

interface ThumbBounds {
  x: number
  y: number
  w: number
}

@Component
export default class AppGallery extends Vue {
  @Prop({ type: Array, default: [] })
  images!: GalleryImage[]

  get gallery(): GalleryImage[] {
    return this.images.map(img => ({ ...img, msrc: img.src }))
  }

  openGallery(index: number) {
    this.$gallery.open(this.gallery, this.getThumbBounds, index)
  }

  private getThumbBounds(index: number): ThumbBounds {
    const imgDiv = this.$el.children[index] as HTMLElement
    const { x, y } = UiUtils.getElementPosition(imgDiv)
    const w = imgDiv.clientWidth
    // Add 2 for border width.
    return { x: x + 2, y: y + 2, w }
  }
}
