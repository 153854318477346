
import Vue from 'vue'
import Component from 'vue-class-component'
import FeedbackRepository from '@/data/repository/feedback.repository'
import AuthRepository from '@/data/repository/auth.repository'
import AppForm from '@/ui/components/form/AppForm.vue'

@Component
export default class FeedbackDialog extends Vue {
  name = ''
  email = ''
  message = ''

  isSubmitting = false

  mounted() {
    const user = AuthRepository.getUser()
    if (user) {
      this.name = user.name || ''
      this.email = user.email
    }
  }

  private getForm(): AppForm | null {
    return this.$refs.form as AppForm
  }

  async submit() {
    const form = this.getForm()
    if (form && form.validate()) {
      this.isSubmitting = true
      const result = await FeedbackRepository.submitFeedback({
        name: this.name,
        email: this.email,
        message: this.message,
      })
      this.isSubmitting = false

      if (result.isSuccessful) {
        this.$emit('dismiss')
        this.$snackbar.show({ message: this.$ts('message.thanksForFeedback') })
      } else {
        this.$snackbar.show({ message: result.errorMessage })
      }
    }
  }
}
