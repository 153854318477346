
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { NocGroup } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import QuestionnaireButton from '../component/QuestionnaireButton.vue'

interface Option {
  title: string
  subtitle: string
  examples: string
  nocGroup: NocGroup
}

@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireJobOffer extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  options: Option[] = [
    {
      title: 'Skill Type 00',
      subtitle: 'senior management jobs',
      examples:
        'Legislators; city administrators; bank presidents; CEO/CFO/COO/ vice ' +
        'president of sizable company',
      nocGroup: NocGroup.NOC_00,
    },
    {
      title: 'Skill type 0 (zero)',
      subtitle: 'management jobs',
      examples: 'Restaurant managers; mine managers; shore captains (fishing)',
      nocGroup: NocGroup.NOC_0,
    },
    {
      title: 'Skill level A',
      subtitle: 'professional jobs that usually call for a degree from a university',
      examples: 'Doctors; dentists; architects',
      nocGroup: NocGroup.NOC_A,
    },
    {
      title: 'Skill level B',
      subtitle: 'technical jobs and skilled trades',
      examples: 'Chefs; plumbers; electricians',
      nocGroup: NocGroup.NOC_B,
    },
    {
      title: 'Skill level C',
      subtitle: 'intermediate jobs that usually call for high school and/or job-specific training',
      examples: 'Industrial butchers; long-haul truck drivers; food and beverage servers',
      nocGroup: NocGroup.NOC_C,
    },
    {
      title: 'Skill level D',
      subtitle: 'labour jobs that usually give on-the-job training',
      examples: 'Fruit pickers; cleaning staff; oil field workers',
      nocGroup: NocGroup.NOC_D,
    },
  ]

  hasJobOffer: boolean = this.profile.jobOffer !== null

  setHasJobOffer(hasOffer: boolean) {
    this.hasJobOffer = hasOffer
    if (!hasOffer) {
      this.profile.jobOffer = null
      this.$emit('submit')
    }
  }

  onOptionSelected(option: Option) {
    this.profile.jobOffer = option.nocGroup
    this.$emit('submit')
  }
}
