
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import { Prop } from 'vue-property-decorator'

let lastUsedSuffix = 0

@Component
export default class AppRadioGroup extends Vue {
  @Prop({ type: [String, Number] })
  value!: string | number

  @Prop({ type: Array, required: true })
  options!: SelectOption[]

  @Prop({ type: [String, Number], default: 1 })
  columns!: number

  private suffix = ++lastUsedSuffix
  name = `app-radio-group-${this.suffix}`

  get itemStyle() {
    return {
      flexBasis: `${100 / (Number(this.columns) || 1)}%`,
    }
  }

  optionId(option: SelectOption): string {
    return `app-radio-group-${this.suffix}-${option.id}`
  }

  onChange(option: SelectOption) {
    this.$emit('input', option.id)
  }
}
