
import { AuthTypes } from '@/store/modules/auth.module'
import store from '@/store/store'
import RouteHelper from '@/ui/router/route-helper'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import UiUtils from '../../util/ui-utils'
import AuthForm from './AuthForm.vue'

@Component({
  head: {
    title: UiUtils.translateTitle('action.signIn'),
  },
  components: {
    AuthForm,
  },
})
export default class AuthScreen extends Vue {
  mounted() {
    if (this.isSignedIn) {
      RouteHelper.default()
    }
  }

  private get isSignedIn(): boolean {
    return store.getters[AuthTypes.getters.isSignedIn]
  }

  @Watch('isSignedIn')
  private onIsSignedInChanged() {
    if (this.isSignedIn) {
      RouteHelper.default()
    }
  }
}
