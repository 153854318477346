import breakpoints from '@/ui/styles/exports/breakpoints.scss'
import WindowManager from './window-manager'

export default class ResponsiveUtils {
  static isPhone(): boolean {
    return WindowManager.getWindowWidth() <= this.getBreakpoint('sm')
  }

  static isPhoneOrTablet(): boolean {
    return WindowManager.getWindowWidth() <= this.getBreakpoint('md')
  }

  static isDesktop(): boolean {
    return WindowManager.getWindowWidth() > this.getBreakpoint('md')
  }

  private static getBreakpoint(key: string): number {
    return parseInt(breakpoints[key], 10)
  }
}
