
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { LANGUAGE_TESTS_MAP } from '@/data/entity/profile/language-test'
import OfficialLanguage from '@/data/entity/profile/official-language'
import PointCalculator from '@/data/point-calculator/point-calculator'
import { LanguageTestScores } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import QuestionnaireButton from '../component/QuestionnaireButton.vue'

type GradeLevelName = 'Basic' | 'Intermediate' | 'Advanced'
type GradeName = 'Initial' | 'Developing' | 'Adequate' | 'Fluent'

interface GradeLevel {
  name: GradeLevelName
  grades: Grade[]
}

interface Grade {
  name: GradeName
  clbScore: number
  testScores?: {
    IELTS: number
    TEF: number
  }
}

@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireLanguage extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile
  /** The image src to be used for the top image */
  @Prop({ required: true })
  topImageSrc!: string
  /** The title to be rendered on top */
  @Prop({ required: true })
  title!: string
  /** The language test to be used to convert raw CLB scores */
  @Prop({ required: true })
  languageTestType!: 'IELTS' | 'TEF'

  gradeLevels: GradeLevel[] = [
    {
      name: 'Basic',
      grades: [
        { name: 'Initial', clbScore: 1 },
        { name: 'Developing', clbScore: 2 },
        { name: 'Adequate', clbScore: 3 },
        { name: 'Fluent', clbScore: 4 },
      ],
    },
    {
      name: 'Intermediate',
      grades: [
        { name: 'Initial', clbScore: 5 },
        { name: 'Developing', clbScore: 6 },
        { name: 'Adequate', clbScore: 7 },
        { name: 'Fluent', clbScore: 8 },
      ],
    },
    {
      name: 'Advanced',
      grades: [
        { name: 'Initial', clbScore: 9 },
        { name: 'Developing', clbScore: 10 },
        {
          name: 'Adequate',
          clbScore: 11,
          testScores: { IELTS: 8.5, TEF: 284 },
        },
        {
          name: 'Fluent',
          clbScore: 12,
          testScores: { IELTS: 9, TEF: 300 },
        },
      ],
    },
  ]

  getGradeTitle(grade: Grade): string {
    const languageTest = LANGUAGE_TESTS_MAP[this.languageTestType]
    let score: number
    let testType: string = languageTest.type
    if (languageTest.language === OfficialLanguage.ENGLISH) {
      if (grade.testScores) {
        score = grade.testScores[this.languageTestType]
      } else {
        score = languageTest.convertClbScore(grade.clbScore, 'r')
      }
    } else {
      // Use raw CLB scores for the French language.
      testType = 'CLB'
      score = grade.clbScore
    }

    return `${grade.name}<br><span class="q-lang__btn-subtitle">(${testType} ${score})</span>`
  }

  isGradeSelected(grade: Grade): boolean {
    const firstLanguageTest = this.profile.firstLanguageTest
    const secondLanguageTest = this.profile.secondLanguageTest
    if (firstLanguageTest !== null && firstLanguageTest.type === this.languageTestType) {
      const scores = this.profile.getFirstLanguageClbScores()
      return scores !== null && scores.r === grade.clbScore
    } else if (secondLanguageTest !== null && secondLanguageTest.type == this.languageTestType) {
      const scores = this.profile.getSecondLanguageClbScores()
      return scores !== null && scores.r == grade.clbScore
    } else {
      return false
    }
  }

  onGradeSelected(grade: Grade) {
    const languageTest = LANGUAGE_TESTS_MAP[this.languageTestType]
    const newScores = this.gradeToScores(grade)
    const isFirstLanguage = this.isFirstLanguageTest(languageTest.language)

    // Create two configurations of profiles, one with current language tests replaced,
    // and one with swapped languages.
    const profileWithReplacedTest = this.getProfileWithReplacedTest(newScores, isFirstLanguage)
    const profileWithSwappedTests = this.getProfileWithSwappedTests(newScores, isFirstLanguage)

    // Use the configuration that gives more points, but prefer to replace if points are equal.
    const pointsWithReplacedTest = PointCalculator.calculatePoints(profileWithReplacedTest)
    const pointsWithSwappedTests = PointCalculator.calculatePoints(profileWithSwappedTests)
    if (pointsWithReplacedTest.totalPoints >= pointsWithSwappedTests.totalPoints) {
      this.profile.applyProfile(profileWithReplacedTest)
    } else {
      this.profile.applyProfile(profileWithSwappedTests)
    }

    this.$emit('submit')
  }

  /** Convert a Grade object to LanguageTestScores. */
  private gradeToScores(grade: Grade): LanguageTestScores {
    return { r: grade.clbScore, w: grade.clbScore, l: grade.clbScore, s: grade.clbScore }
  }

  /**
   * Determine if the language of this test matches the first or second language
   * test in the profile.
   * @returns true if the test matches the first language.
   */
  private isFirstLanguageTest(language: OfficialLanguage): boolean {
    const firstLanguageTest = this.profile.firstLanguageTest
    const secondLanguageTest = this.profile.secondLanguageTest
    if (firstLanguageTest !== null && firstLanguageTest.language === language) {
      return true
    } else if (secondLanguageTest !== null && secondLanguageTest.language === language) {
      return false
    } else if (firstLanguageTest === null) {
      return true
    } else {
      return false
    }
  }

  /** Return a copy of the current profile with replaced language test scores */
  private getProfileWithReplacedTest(
    newScores: LanguageTestScores,
    isFirstLanguage: boolean
  ): EditableProfile {
    const result = this.profile.clone()
    if (isFirstLanguage) {
      result.firstLangTestType = this.languageTestType
      result.setFirstLanguageScores(newScores)
    } else {
      result.secondLangTestType = this.languageTestType
      result.setSecondLanguageScores(newScores)
    }

    return result
  }

  /**
   * Return a copy of the current profile with replaced language test scores,
   * such that the current language (first or second) is swapped with the other one.
   */
  private getProfileWithSwappedTests(
    newScores: LanguageTestScores,
    isFirstLanguage: boolean
  ): EditableProfile {
    const result = this.profile.clone()
    result.swapLanguageTests()
    if (isFirstLanguage) {
      result.secondLangTestType = this.languageTestType
      result.setSecondLanguageScores(newScores)
    } else {
      result.firstLangTestType = this.languageTestType
      result.setFirstLanguageScores(newScores)
    }

    return result
  }
}
