import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCategory from '@/data/point-calculator/point-category'

export default class AgePointCalculator {
  static calculate(profile: EditableProfile): PointCategory {
    return {
      title: 'label.age',
      points: this.points(profile),
      maxPoints: this.maxPoints(profile),
    }
  }

  private static points(profile: EditableProfile): number {
    if (profile.age) {
      let ageBracket: AgeBracket = AGE_BRACKETS[0]
      for (let i = 0; i < AGE_BRACKETS.length; i++) {
        if (profile.age <= AGE_BRACKETS[i].maxAge) {
          ageBracket = AGE_BRACKETS[i]
          break
        }
      }

      if (profile.hasSpouse) {
        return ageBracket.pointsWithSpouse
      } else {
        return ageBracket.pointsWithoutSpouse
      }
    } else {
      return 0
    }
  }

  private static maxPoints(profile: EditableProfile): number {
    return profile.hasSpouse ? 100 : 110
  }
}

interface AgeBracket {
  maxAge: number
  pointsWithSpouse: number
  pointsWithoutSpouse: number
}

export const AGE_BRACKETS: AgeBracket[] = [
  { maxAge: 17, pointsWithSpouse: 0, pointsWithoutSpouse: 0 },
  { maxAge: 18, pointsWithSpouse: 90, pointsWithoutSpouse: 99 },
  { maxAge: 19, pointsWithSpouse: 95, pointsWithoutSpouse: 105 },
  { maxAge: 29, pointsWithSpouse: 100, pointsWithoutSpouse: 110 },
  { maxAge: 30, pointsWithSpouse: 95, pointsWithoutSpouse: 105 },
  { maxAge: 31, pointsWithSpouse: 90, pointsWithoutSpouse: 99 },
  { maxAge: 32, pointsWithSpouse: 85, pointsWithoutSpouse: 94 },
  { maxAge: 33, pointsWithSpouse: 80, pointsWithoutSpouse: 88 },
  { maxAge: 34, pointsWithSpouse: 75, pointsWithoutSpouse: 83 },
  { maxAge: 35, pointsWithSpouse: 70, pointsWithoutSpouse: 77 },
  { maxAge: 36, pointsWithSpouse: 65, pointsWithoutSpouse: 72 },
  { maxAge: 37, pointsWithSpouse: 60, pointsWithoutSpouse: 66 },
  { maxAge: 38, pointsWithSpouse: 55, pointsWithoutSpouse: 61 },
  { maxAge: 39, pointsWithSpouse: 50, pointsWithoutSpouse: 55 },
  { maxAge: 40, pointsWithSpouse: 45, pointsWithoutSpouse: 50 },
  { maxAge: 41, pointsWithSpouse: 35, pointsWithoutSpouse: 39 },
  { maxAge: 42, pointsWithSpouse: 25, pointsWithoutSpouse: 28 },
  { maxAge: 43, pointsWithSpouse: 15, pointsWithoutSpouse: 17 },
  { maxAge: 44, pointsWithSpouse: 5, pointsWithoutSpouse: 6 },
  { maxAge: Infinity, pointsWithSpouse: 0, pointsWithoutSpouse: 0 },
]
