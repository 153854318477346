
import AuthRepository from '@/data/repository/auth.repository'
import { ProfileTypes } from '@/store/modules/profile.module'
import RouteNames from '@/ui/router/route-names'
import Vue from 'vue'
import Component from 'vue-class-component'
import QuestionnaireUtils from '../questionnaire/questionnaire-utils'
import ProfileSavingIndicator from './common/ProfileSavingIndicator.vue'
import GuideScreenHeader from './GuideScreenHeader.vue'
import GuideSteps from './GuideSteps.vue'

@Component({
  components: {
    ProfileSavingIndicator,
    GuideScreenHeader,
    GuideSteps,
  },
})
export default class GuideScreen extends Vue {
  mounted() {
    this.saveQuestionnaireProfile()
  }

  /**
   * Retrieve the locally saved profile generated by filling in the questionnaire,
   * and save it on the server. Also clear the locally saved profile.
   */
  private async saveQuestionnaireProfile() {
    console.debug('Saving questionnaire profile')
    const customer = await AuthRepository.asyncCustomer()
    console.debug('Customer:', customer)
    if (customer) {
      const oldProfile = customer.profile
      const newProfile = QuestionnaireUtils.getQuestionnaireProfile()
      console.debug('New profile:', newProfile)
      if (newProfile) {
        if (oldProfile) {
          newProfile.version = oldProfile.version + 1
        }

        this.$store.commit(ProfileTypes.mutations.setProfile, newProfile)
        QuestionnaireUtils.removeQuestionnaireProfile()
        QuestionnaireUtils.setQuestionnaireResultExpected(false)
      }
    }
  }

  get isOnPointCalculatorScreen(): boolean {
    return this.$route.name === RouteNames.GUIDE_ELIGIBILITY
  }
}
