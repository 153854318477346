
import PermissionChecker from '@/data/claims/permission-checker'
import AuthRepository from '@/data/repository/auth.repository'
import PaymentRepository from '@/data/repository/payment.repository'
import RouteHelper from '@/ui/router/route-helper'
import { Customer } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import RouteNames from '../../router/route-names'

@Component
export default class PaymentResultScreen extends Vue {
  hasPermissionToMainScreen = false
  result: string | null = null

  mounted() {
    // this.checkSessionId()
    this.result = this.$route.params.result
    this.checkPermissions()

    AuthRepository.addCustomerListener(this.onCustomerChanged)
  }

  beforeDestroy() {
    AuthRepository.removeCustomerListener(this.onCustomerChanged)
  }

  private checkSessionId() {
    const savedSessionId = PaymentRepository.getCheckoutSessionId()
    if (savedSessionId) {
      PaymentRepository.deleteCheckoutSessionId()
    } else {
      RouteHelper.default()
    }
  }

  get isSuccessful(): boolean {
    return this.result === 'successful'
  }

  get isCanceled(): boolean {
    return this.result === 'canceled'
  }

  private onCustomerChanged(customer: Customer | null) {
    this.checkPermissions()
  }

  private async checkPermissions() {
    this.hasPermissionToMainScreen = await PermissionChecker.check('statusCenter')
  }

  navigateHome() {
    RouteHelper.default()
  }

  navigateToProfile() {
    this.$router.replace({ name: RouteNames.PROFILE })
  }
}
