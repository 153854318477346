
import Vue from 'vue'
import Component from 'vue-class-component'
import SelectOption from '@/common/interfaces/select-option'
import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import { AuthTypes } from '@/store/modules/auth.module'
import AppUtils from '@/ui/util/app-utils'
import User from '@/data/entity/user.entity'

const OPTION_SIGN_OUT = 1
const OPTION_PROFILE = 2
const OPTION_LEAVE_FEEDBACK = 3

@Component
export default class HeaderProfileButton extends Vue {
  profileOptions: SelectOption[] = [
    { id: OPTION_PROFILE, title: 'label.profile' },
    { id: OPTION_LEAVE_FEEDBACK, title: 'action.leaveFeedback' },
    { id: OPTION_SIGN_OUT, title: 'action.signOut' },
  ]

  get isUserInitialized(): boolean {
    return store.state.auth.isUserInitialized
  }

  get userName(): string | null {
    const user: User | null = store.state.auth.user
    if (user) {
      return user.name || user.email
    }
    return null
  }

  get shouldShow(): boolean {
    return this.$router.currentRoute.name !== RouteNames.SIGN_IN
  }

  signIn() {
    this.$router.push({ name: RouteNames.SIGN_IN })
  }

  onProfileOptionSelected(optionId: number) {
    switch (optionId) {
      case OPTION_PROFILE:
        if (this.$route.name !== RouteNames.PROFILE) {
          this.$router.push({ name: RouteNames.PROFILE })
        }
        break
      case OPTION_LEAVE_FEEDBACK:
        AppUtils.showFeedbackDialog(this)
        break
      case OPTION_SIGN_OUT:
        AppUtils.signOut(this)
        break
    }
  }
}
