
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

let lastIdSuffix = 0

@Component
export default class AppSwitch extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean

  @Prop({ type: String })
  label?: string

  @Prop({ type: String })
  labelKey?: string

  @Prop(String)
  description?: string

  id: string = `app-switch-${++lastIdSuffix}`

  @Prop({ type: Boolean, default: false })
  largeLabel!: boolean

  @Prop({ type: Boolean, default: false })
  loading!: boolean

  @Prop({ type: Boolean, default: false })
  green!: boolean

  get labelValue(): string | undefined {
    return this.labelKey ? this.$ts(this.labelKey) : this.label
  }

  isChecked(): boolean {
    const checkbox = this.$refs.checkbox as HTMLInputElement
    return checkbox.checked
  }

  toggle() {
    this.$emit('input', !this.value)
  }
}
