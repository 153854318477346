
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import colors from '@/ui/styles/exports/colors.scss'

/**
 * A button component.
 *
 * Slots:
 * default - the content of the button.
 */
@Component
export default class AppButton extends Vue {
  /** If true, show a spinner on top of the button and disable it. */
  @Prop({ type: Boolean, default: false })
  loading!: boolean

  /** If true, the button will be disabled. */
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  /** HTML button "type" attribute */
  @Prop({ type: String, default: 'button' })
  type!: string

  /** Override the height of the button */
  @Prop({ type: [String, Number], default: 36 })
  height?: string | number
  /** Set a fixed width to the button. */
  @Prop({ type: [String, Number] })
  width?: string | number

  /**
   * Appearance of the button. Can be one of:
   * secondary, gradient, gradient-secondary, white
   */
  @Prop({ type: String })
  appearance?: string
  /** Use danger color scheme */
  @Prop({ type: Boolean, default: false })
  danger!: boolean

  @Prop({ type: String })
  fontSize?: string

  /** Render a circular Floating Action Button */
  @Prop({ type: Boolean, default: false })
  fab!: boolean
  /** Render a rectangular button */
  @Prop({ type: Boolean, default: false })
  rect!: boolean
  /** Render a rectangular button without border radius */
  @Prop({ type: Boolean, default: false })
  block!: boolean
  /** Render a full width button, without border radius. */
  @Prop({ type: Boolean, default: false })
  fullWidth!: boolean

  get heightPx(): string | null {
    return this.height ? `${this.height}px` : null
  }

  get widthPx(): string | null {
    return this.fab
      ? this.height
        ? `${this.height}px`
        : null
      : this.width
      ? `${this.width}px`
      : null
  }

  get classList(): string {
    const prefix = 'app-button__button'
    let classes = ''
    if (this.appearance) {
      classes += `${prefix}--${this.appearance}`
    }
    if (this.fab) {
      classes += ` ${prefix}--fab`
    }
    if (this.fullWidth) {
      classes += ` ${prefix}--full-width`
    }
    if (this.rect) {
      classes += ` ${prefix}--rect`
    }
    if (this.block) {
      classes += ` ${prefix}--block`
    }
    if (this.danger) {
      classes += ` ${prefix}--danger`
    }
    if (this.fullWidth) {
      classes += ` ${prefix}--full-width`
    }

    return classes
  }

  get spinnerColor(): string | null {
    if (this.danger) {
      return colors.danger
    }
    return null
  }
}
