
import Vue from 'vue'
import Component from 'vue-class-component'

import RouteNames from '@/ui/router/route-names'
import { Watch } from 'vue-property-decorator'
import store from '@/store/store'
import { LayoutTypes } from '@/store/modules/layout.module'
import RouteHelper from '@/ui/router/route-helper'

@Component
export default class HomeScreen extends Vue {
  mounted() {
    this.redirect()
  }

  isDrawerOpen = store.state.layout.isNavDrawerOpen

  private redirect() {
    if (this.$router.currentRoute.name === RouteNames.HOME) {
      RouteHelper.default()
    }
  }

  private get isNavDrawerOpen(): boolean {
    return store.state.layout.isNavDrawerOpen
  }

  @Watch('$route')
  private onRouteChange() {
    this.redirect()
  }

  @Watch('isDrawerOpen')
  private onIsDrawerOpenChange() {
    store.commit(LayoutTypes.mutations.setNavDrawerOpen, this.isDrawerOpen)
  }

  @Watch('isNavDrawerOpen')
  private onIsNavDrawerOpenChange() {
    this.isDrawerOpen = store.state.layout.isNavDrawerOpen
  }
}
