
import RootDialog from '@/common/plugins/dialog/RootDialog.vue'
import RootGallery from '@/common/plugins/gallery/RootGallery.vue'
import RootSnackbar from '@/common/plugins/snackbar/RootSnackbar.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { OperationResult } from './data/api/operation-result'
import AuthRepository, { AuthResult, AuthStatus } from './data/repository/auth.repository'
import I18n from './i18n/i18n'
import { AuthTypes } from './store/modules/auth.module'
import store from './store/store'
import Footer from './ui/misc/Footer.vue'
import RouteHelper from './ui/router/route-helper'
import RouteNames from './ui/router/route-names'
import QuestionnaireUtils from './ui/screens/questionnaire/questionnaire-utils'

@Component({
  components: {
    Footer,
    RootDialog,
    RootSnackbar,
    RootGallery,
  },
})
export default class App extends Vue {
  version = process.env.VUE_APP_VERSION

  isSigningIn = false
  isLoadingLocale = false

  mounted() {
    console.log(`APP VERSION = ${this.version}`)
    AuthRepository.init()
    this.loadLocale()
    this.signInIfNeeded()
    RouteHelper.redirectRootIfNeeded()
    this.checkRouteAuthorization()

    Vue.prototype.$ts = this.$t

    AuthRepository.addTokenChangedListener(this.onTokenChanged)

    if (window.AndroidBridge && window.AndroidBridge.onApplicationLoaded) {
      window.AndroidBridge.onApplicationLoaded()
    }
  }

  beforeDestroy() {
    AuthRepository.removeTokenChangedListener(this.onTokenChanged)
  }

  /**
   * Load locale files containing translations for the selected locale.
   */
  private async loadLocale() {
    this.isLoadingLocale = true
    try {
      await I18n.init(this.getLocaleFromUrl())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoadingLocale = false
    }
  }

  /**
   * Return the locale passed as a URL query parameter `lang`, or null.
   */
  private getLocaleFromUrl(): string | null {
    const query = window.location.search
    if (query) {
      const pairs = query.substring(1).split('&')
      for (let i = 0; i < pairs.length; i++) {
        const keyValue = pairs[i].split('=')
        if (keyValue[0] == 'lang') {
          return keyValue[1]
        }
      }
    }
    return null
  }

  /**
   * Sign the user in if the current location corresponds to a sign in link or if
   * an auth redirect is expected.
   * Correctly process error conditions.
   */
  private async signInIfNeeded() {
    this.isSigningIn = true
    const result: OperationResult<AuthResult> = await AuthRepository.signInIfNeeded()
    this.isSigningIn = false
    if (result.isSuccessful) {
      const authResult = result.value
      if (authResult.status === AuthStatus.ERROR_FB_EMAIL_MISSING) {
        this.$dialog.show({
          title: 'Missing e-mail address',
          message:
            'The Facebook account that you have signed in with does not have an e-mail ' +
            'address associated with it or you have denied access to it. The e-mail address ' +
            'is required for our service to function properly. Please update your Facebook ' +
            'settings and try again.',
          primaryBtnText: 'OK',
        })
      } else if (authResult.status === AuthStatus.ERROR_ACCOUNT_EXISTS_EMAIL_LINK) {
        this.$dialog.confirm({
          title: 'Account already exists',
          message:
            `An account with the provided e-mail address (${authResult.email}) is already ` +
            'registered. To confirm that you have access to the e-mail address, we will ' +
            'send a sign-in link to it',
          confirmBtnText: 'Continue',
          rejectBtnText: 'Cancel',
          cancelable: false,
          onConfirm: () => this.linkEmailAccount(authResult),
          onReject: () => AuthRepository.clearPendingCredential(),
        })
      } else if (authResult.status === AuthStatus.ERROR_ACCOUNT_EXISTS_GOOGLE) {
        this.$dialog.confirm({
          title: 'Account already exists',
          message:
            `An account with the provided e-mail address (${authResult.email}) is ` +
            'already registered. Please sign in with the associated Google account to continue',
          confirmBtnText: 'Continue',
          rejectBtnText: 'Cancel',
          cancelable: false,
          onConfirm: () => this.linkGoogleAccount(authResult),
          onReject: () => AuthRepository.clearPendingCredential(),
        })
      } else if (authResult.isSuccessful) {
        // NOTE: Very important to redirect navigation right away here, otherwise AuthScreen's
        // navigation logic kicks in.
        this.onSignInSuccess()
      }
    } else {
      this.$router.replace({ name: RouteNames.SIGN_IN })
      console.error('Sign in failed')
      console.error(result.errorMessage)

      setTimeout(() => {
        this.$dialog.alert({
          title: 'Error',
          message: result.errorMessage || 'Unknown error',
        })
      })
    }
  }

  /**
   * Called when the sign in flow was successful.
   */
  private async onSignInSuccess() {
    console.debug('Sign in success')
    if (QuestionnaireUtils.isQuestionnaireResultExpected()) {
      this.showQuestionnaireResults()
    } else {
      RouteHelper.default()
    }
  }

  /**
   * Show the questionnaire results page after saving the entered profile
   * in the cloud.
   */
  private showQuestionnaireResults() {
    this.$router.replace({ name: RouteNames.QUESTIONNAIRE_RESULTS })
  }

  private async linkEmailAccount(authResult: AuthResult): Promise<any> {
    const result = await AuthRepository.linkAccount(authResult)
    if (result.isSuccessful) {
      this.$snackbar.show({ message: `An e-mail link is sent to ${authResult.email}` })
    } else {
      this.$snackbar.show({ message: result.errorMessage })
      console.error(result.errorMessage)
    }
  }

  private async linkGoogleAccount(authResult: AuthResult) {
    const result = await AuthRepository.linkAccount(authResult)

    if (!result.isSuccessful) {
      this.$snackbar.show({ message: result.errorMessage })
      console.error(result.errorMessage)
    }
  }

  private async onTokenChanged() {
    const hasPermission = await RouteHelper.hasPermissionForCurrentRoute()
    if (!hasPermission) {
      RouteHelper.default()
    }
  }

  /** True if the current user is signed in */
  get isSignedIn(): boolean {
    return store.getters[AuthTypes.getters.isSignedIn]
  }

  /** True if the application is initializing */
  get isInitializing(): boolean {
    return (
      !store.state.auth.isUserInitialized || !store.state.auth.isInitialized || this.isLoadingLocale
    )
  }

  /** True if the current route is being authorized */
  get isAuthorizingRoute(): boolean {
    return store.state.auth.isAuthorizingRoute
  }

  get hadInitializationError(): boolean {
    return store.state.auth.hadInitializationError
  }

  @Watch('isInitializing')
  private onInitializingChanged(isInitializing: boolean) {
    this.checkRouteAuthorization()
    if (!isInitializing) {
      this.registerRouteAuthorizationGuard()

      // Add a delay so that home screen redirects have time to execute.
      setTimeout(() => {
        if (this.isSignedIn) {
          RouteHelper.returnToSavedRouteIfPossible()
        }
      })
    }
  }

  /**
   * Update navigation state when the user signs out.
   */
  @Watch('isSignedIn')
  private onSignedInStateChanged() {
    this.checkRouteAuthorization()
  }

  /**
   * Check whether the current user is authorized to access the current route,
   * and if not, then redirect to the root screen.
   */
  private async checkRouteAuthorization() {
    if (!this.isInitializing) {
      const meta = this.$router.currentRoute.meta
      if (!this.isSignedIn && meta && meta.authenticate) {
        RouteHelper.default()
      } else {
        const isPermitted = await RouteHelper.hasPermissionForCurrentRoute()
        if (!isPermitted) {
          RouteHelper.default()
        }
      }
    }
  }

  /**
   * Authenticate route changes. Redirect to login page if target route
   * requires authentication and the user is not currently logged in.
   */
  private registerRouteAuthorizationGuard() {
    this.$router.beforeEach((to, from, next) => {
      if (to.meta && to.meta.authenticate && !this.isSignedIn) {
        RouteHelper.navigateToAuth(to.name)
      } else {
        next()
      }
    })
  }
}
