
import Vue from 'vue'
import Component from 'vue-class-component'

import SignInButton from './SignInButton.vue'
import AuthRepository from '@/data/repository/auth.repository'
import RouteHelper from '@/ui/router/route-helper'

@Component({
  components: {
    SignInButton,
  },
})
export default class FacebookSignInButton extends Vue {
  async signIn() {
    const result = await AuthRepository.signInWithFacebook()
    if (result.isSuccessful) {
      RouteHelper.default()
    }
  }
}
