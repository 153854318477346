
import dayjs from 'dayjs'
import { OnboardingCouponConfig } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

/**
 * Displays questionnaire payment expiration timer.
 */
@Component
export default class QuestionnairePaymentTimerLabel extends Vue {
  @Prop({ default: null })
  private couponConfig: OnboardingCouponConfig | null = null

  private remainingMs: number = 0
  private intervalId: number | null = null

  private emittedTimerComplete = false

  mounted() {
    this.updateRemainingTime()
  }

  updated() {
    if (!this.emittedTimerComplete) {
      this.updateRemainingTime()
    }
  }

  private updateRemainingTime() {
    if (this.couponConfig && this.couponConfig.coupon) {
      const now = dayjs()
      const expirationTime = dayjs(this.couponConfig.expirationTime)
      if (now.isBefore(expirationTime)) {
        this.remainingMs = expirationTime.diff(now, 'millisecond')
      }

      this.handleCouponConfig()
    } else {
      this.remainingMs = 0
    }
  }

  private handleCouponConfig() {
    if (this.remainingMs < 1000) {
      this.emitTimerComplete()
    } else {
      this.startTimer()
    }
  }

  beforeDestroy() {
    this.stopTimer()
  }

  private startTimer() {
    this.stopTimer()

    this.intervalId = setInterval(() => {
      this.remainingMs = Math.max(0, this.remainingMs - 1000)
      if (this.remainingMs < 1000) {
        this.emitTimerComplete()
        this.stopTimer()
      }
    }, 1000)
  }

  private stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  private emitTimerComplete() {
    this.$emit('timer-complete')
    this.emittedTimerComplete = true
  }

  get timeLeftLabel(): string {
    const date = dayjs(this.remainingMs)
    return date.format('mm:ss')
  }

  get isExpired(): boolean {
    return this.remainingMs < 1000
  }
}
