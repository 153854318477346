/** Firebase collection names */
export enum Collection {
  /** All registered customers */
  CUSTOMERS = 'customers',
  CUSTOMER_PROFILES = 'profiles',
  CUSTOMER_GUIDE_PROGRESS = 'guideProgress',

  /** All products registered on Stripe */
  PRODUCTS = 'products',
  /** Subcollection for each product for deactivated/deleted plans. */
  PRODUCT_INACTIVE_PLANS = 'inactivePlans',
  /** Collection for customer feedback messages. */
  FEEDBACK = 'feedback',
  /** Pool data, with only one document, named `data`. */
  POOL = 'pool',
  /** Cached currency rates and the date of last refreshing. */
  CURRENCY_RATES = 'currencyRates',
  /** Documents related to the onboarding flow. */
  ONBOARDING = 'onboarding',
}
