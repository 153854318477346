import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import EligibilityCriterionResult from '@/data/entity/programs/eligibility-criterion-result.entity'
import EligibilityCriterion from '@/data/entity/programs/eligibility-criterion.entity'
import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'
import ProgramEligibility from '@/data/entity/programs/program-eligibility.entity'
import ProgramId from '@/data/entity/programs/program-id.entity'
import Program from '@/data/entity/programs/program.entity'
import { LanguageTestScoresUtils, CecWorkExpType, NocGroup } from 'shared-entities'
import EligibilityCalculatorUtils from '../utils/eligibility-calculator-utils'

const workExpAmountCriterion: EligibilityCriterion = {
  title: 'workExpAmount',
  description:
    'One year in Canada in the last 3 years (either combination of full-time or part-time work)',
}

const workExpTypeCriterion: EligibilityCriterion = {
  title: 'workExpType',
  description: 'Canadian work experience in 1 of these NOCs: 0, A or B',
}

const languageCriterion: EligibilityCriterion = {
  title: 'language',
  description:
    'English or French skills<ul><li>CLB 7 if your NOC is 0 or A</li><li>CLB 5 if your NOC is B</li></ul>',
}

export const CEC_PROGRAM: Program = {
  id: ProgramId.CEC,
  title: 'Canadian Experience Class',
  shortTitle: 'CEC',
  description:
    'The Canadian Experience Class is for skilled workers who have Canadian work experience and want to become permanent residents.',
  link:
    'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/canadian-experience-class.html',
  eligibilityCriteria: [workExpAmountCriterion, workExpTypeCriterion, languageCriterion],
}

export default class CecEligibility {
  static calculate(profile: EditableProfile): ProgramEligibility {
    const criteria: EligibilityCriterionResult[] = [
      this.workExpAmount(profile),
      this.workExpType(profile),
      this.language(profile),
    ]

    return {
      program: CEC_PROGRAM,
      status: EligibilityCalculatorUtils.calculateProgramEligibility(criteria),
      criteria,
    }
  }

  private static workExpAmount(profile: EditableProfile): EligibilityCriterionResult {
    return {
      criterion: workExpAmountCriterion,
      status: this.hasCanadianWorkExp(profile)
        ? EligibilityStatus.ELIGIBLE
        : EligibilityStatus.NOT_ELIGIBLE,
    }
  }

  private static hasCanadianWorkExp(profile: EditableProfile): boolean {
    return profile.workExp >= 1 && profile.eligibility.cec.hasWorkExp
  }

  private static workExpType(profile: EditableProfile): EligibilityCriterionResult {
    const cecEligibility = profile.eligibility.cec
    return {
      criterion: workExpTypeCriterion,
      status:
        this.hasCanadianWorkExp(profile) &&
        cecEligibility.workExpType !== null &&
        cecEligibility.workExpType >= NocGroup.NOC_B
          ? EligibilityStatus.ELIGIBLE
          : EligibilityStatus.NOT_ELIGIBLE,
    }
  }

  private static language(profile: EditableProfile): EligibilityCriterionResult {
    let status = EligibilityStatus.INFO_NEEDED
    const cecEligibility = profile.eligibility.cec
    if (cecEligibility.workExpType === CecWorkExpType.NOC_0_A) {
      status = this.satisfiesMinimumClb(profile, 7)
        ? EligibilityStatus.ELIGIBLE
        : EligibilityStatus.NOT_ELIGIBLE
    } else if (cecEligibility.workExpType === CecWorkExpType.NOC_B) {
      status = this.satisfiesMinimumClb(profile, 5)
        ? EligibilityStatus.ELIGIBLE
        : EligibilityStatus.NOT_ELIGIBLE
    }

    return {
      criterion: languageCriterion,
      status,
    }
  }

  private static satisfiesMinimumClb(profile: EditableProfile, minClb: number): boolean {
    const firstLangScores = profile.getFirstLanguageClbScores()
    if (
      firstLangScores &&
      LanguageTestScoresUtils.everySkill(firstLangScores, score => score >= minClb)
    ) {
      return true
    }

    const secondLangScores = profile.getSecondLanguageClbScores()
    if (secondLangScores) {
      return LanguageTestScoresUtils.everySkill(secondLangScores, score => score >= minClb)
    }

    return false
  }
}
