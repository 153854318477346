import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import Firestore = firebase.firestore.Firestore

let asyncFirestore: Promise<Firestore> | null = null

/**
 * Lazy load firestore with async import is important for performance
 */
export default (): Promise<Firestore> => {
  if (!asyncFirestore) {
    asyncFirestore = import(/* webpackChunkName: "chunk-firestore" */ 'firebase/firestore').then(
      () => {
        firebase.firestore().settings({
          merge: true,
        })
        firebase.firestore().enablePersistence({ synchronizeTabs: true })
        return firebase.firestore()
      }
    )
  }
  return asyncFirestore
}
