import { register } from 'register-service-worker'

import rootSnackbarController from '@/common/plugins/snackbar/root-snackbar-controller'

if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js', {
    ready() {
      console.log('Service worker is active.')
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(reg) {
      console.log('New content is available; please refresh.')
      rootSnackbarController.show({
        message: 'Update available',
        actionText: 'Update',
        persistent: true,
        action: () => {
          if (!reg || !reg.waiting) {
            return
          }

          reg.waiting.postMessage('skipWaiting')
          rootSnackbarController.show({
            message: 'Updating...',
            persistent: true,
          })
        },
      })
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}

if ('serviceWorker' in window.navigator) {
  let refreshing = false
  // This is triggered when a new service worker take over
  window.navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) return
    refreshing = true

    window.location.reload()
  })
}
