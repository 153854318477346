
import Vue from 'vue'
import Component from 'vue-class-component'
import { Provide } from 'vue-property-decorator'
import FormRegistrar from './mixins/form-registrar'
import ValidatableField from './mixins/validatable-field.mixin'

@Component
export default class AppForm extends Vue {
  @Provide()
  form: FormRegistrar = {
    register: this.register,
    unregister: this.unregister,
  }

  private fields: ValidatableField[] = []
  private _isSubmitted = false

  get isSubmitted(): boolean {
    return this._isSubmitted
  }

  register(component: ValidatableField) {
    this.fields.push(component)
  }

  unregister(component: ValidatableField) {
    const index = this.fields.indexOf(component)
    if (index >= 0) {
      this.fields.splice(index, 1)
    }
  }

  reset() {
    this._isSubmitted = false
    this.fields.forEach(field => field.reset())
  }

  submit(evt: any) {
    this._isSubmitted = true
    this.$emit('submit', evt)
  }

  validate(): boolean {
    const results = this.fields.map(field => field.validate())
    return results.indexOf(false) < 0
  }
}
