import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCalculatorCategoryA, {
  CategoryA,
} from '@/data/point-calculator/categories/point-calculator-category-a'
import PointCalculatorCategoryB, {
  CategoryB,
} from '@/data/point-calculator/categories/point-calculator-category-b'
import PointCalculatorCategoryC, {
  CategoryC,
} from '@/data/point-calculator/categories/point-calculator-category-c'
import PointCalculatorCategoryD, {
  CategoryD,
} from '@/data/point-calculator/categories/point-calculator-category-d'

interface IPointCalculatorResult {
  categoryA: CategoryA
  categoryB: CategoryB
  categoryC: CategoryC
  categoryD: CategoryD
}

export class PointCalculatorResult {
  readonly totalPoints: number
  readonly categoryA: CategoryA
  readonly categoryB: CategoryB
  readonly categoryC: CategoryC
  readonly categoryD: CategoryD

  constructor(values: IPointCalculatorResult) {
    this.categoryA = values.categoryA
    this.categoryB = values.categoryB
    this.categoryC = values.categoryC
    this.categoryD = values.categoryD
    this.totalPoints = this.calculateTotal()
  }

  private calculateTotal(): number {
    return (
      this.categoryA.points + this.categoryB.points + this.categoryC.points + this.categoryD.points
    )
  }
}

export default class PointCalculator {
  static MAX_CRS_POINTS = 1200

  static calculatePoints(profile: EditableProfile): PointCalculatorResult {
    return new PointCalculatorResult({
      categoryA: PointCalculatorCategoryA.calculate(profile),
      categoryB: PointCalculatorCategoryB.calculate(profile),
      categoryC: PointCalculatorCategoryC.calculate(profile),
      categoryD: PointCalculatorCategoryD.calculate(profile),
    })
  }
}
