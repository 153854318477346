import { SnackbarPlugin, RootSnackbarOptions, IRootSnackbar } from './root-snackbar-interface'

export class RootSnackbarController implements SnackbarPlugin {
  private component: IRootSnackbar | null = null

  bind(component: IRootSnackbar) {
    if (this.component && this.component !== component) {
      throw new Error('Root snackbar already bound')
    }

    this.component = component
  }

  unbind(component: IRootSnackbar) {
    if (this.component === component) {
      this.component = null
    } else {
      throw new Error('Invalid component supplied in unbind() call')
    }
  }

  show(options: RootSnackbarOptions): void {
    if (this.component) {
      this.component.show(options)
    } else {
      console.error('RootSnackbar component not bound to RootSnackbarController')
    }
  }

  error(error: Error): void {
    if (this.component) {
      this.component.show({
        message: error.message,
        persistent: false,
      })
      console.error(error)
    } else {
      console.error('RootSnackbar component not bound to RootSnackbarController')
    }
  }

  hide(): void {
    if (this.component) {
      this.component.hide()
    } else {
      console.error('RootSnackbar component not bound to RootSnackbarController')
    }
  }
}

export default new RootSnackbarController()
