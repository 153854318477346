import EditableProfile from '../entity/profile/editable-profile.entity'
import Eligibility from '../entity/programs/eligibility.entity'
import ProgramId from '../entity/programs/program-id.entity'
import CecEligibility from './programs/cec-eligibility'
import FstEligibility from './programs/fst-eligibility'
import FswEligibility from './programs/fsw-eligibility'
import PnpEligibility from './programs/pnp-eligibility'
import EligibilityStatus from '../entity/programs/eligibility-status.entity'

export default class EligibilityCalculator {
  static calculate(profile: EditableProfile): Eligibility {
    const pnp = PnpEligibility.calculate(profile)
    const cec = CecEligibility.calculate(profile)
    const fsw = FswEligibility.calculate(profile)
    const fst = FstEligibility.calculate(profile)

    if (pnp.status === EligibilityStatus.INFO_NEEDED) {
      const skilledWorkerEligibility = [cec, fsw, fst]

      if (skilledWorkerEligibility.some(it => it.status === EligibilityStatus.ELIGIBLE)) {
        pnp.status = EligibilityStatus.ELIGIBLE
      }
    }

    return {
      [ProgramId.CEC]: cec,
      [ProgramId.FSW]: fsw,
      [ProgramId.FST]: fst,
      [ProgramId.PNP]: pnp,
    }
  }
}
