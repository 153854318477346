import { Mutations, Actions, Getters, ModuleWrapper } from '../util/store-framework'
import StoreUtils from '../util/store-utils'

const types = {
  mutations: {
    setNavDrawerOpen: 'setNavDrawerOpen',
    setPointCalculatorNavOpen: 'setPointCalculatorNavOpen',
    togglePointCalculatorNav: 'togglePointCalculatorNav',
  },
  actions: {},
  getters: {},
}

export const LayoutTypes = StoreUtils.exportTypes('layout', types)
const { mutations, actions, getters } = types

export class LayoutState {
  isNavDrawerOpen = false
  isPointCalculatorNavOpen = false
}

class LayoutMutations extends Mutations<LayoutState> {
  [mutations.setNavDrawerOpen](state: LayoutState, isOpen: boolean) {
    state.isNavDrawerOpen = isOpen
  }

  [mutations.setPointCalculatorNavOpen](state: LayoutState, isOpen: boolean) {
    state.isPointCalculatorNavOpen = isOpen
  }

  [mutations.togglePointCalculatorNav](state: LayoutState) {
    state.isPointCalculatorNavOpen = !state.isPointCalculatorNavOpen
  }
}

class LayoutActions extends Actions<LayoutState> {}

class LayoutGetters extends Getters<LayoutState> {}

class LayoutModule extends ModuleWrapper<LayoutState> {
  state = new LayoutState()
  mutations = new LayoutMutations()
  actions = new LayoutActions()
  getters = new LayoutGetters()
}

export default new LayoutModule()
