import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCategory from '@/data/point-calculator/point-category'
import EducationPointCalculator from '@/data/point-calculator/categories/core/education-point-calculator'
import LanguagePointCalculator from '@/data/point-calculator/categories/core/language-point-calculator'
import WorkExperiencePointCalculator from '@/data/point-calculator/categories/core/work-experience-point-calculator'

interface ICategoryB {
  education: PointCategory
  language: PointCategory
  workExp: PointCategory
}

export class CategoryB implements PointCategory {
  readonly title = 'B'
  readonly description = 'category.b'

  readonly points: number
  readonly maxPoints: number

  readonly education: PointCategory
  readonly language: PointCategory
  readonly workExp: PointCategory

  constructor(values: ICategoryB) {
    this.education = values.education
    this.language = values.language
    this.workExp = values.workExp

    this.points = this.calculateTotal()
    this.maxPoints = this.calculateMaxPoints()
  }

  private calculateTotal(): number {
    return this.education.points + this.language.points + this.workExp.points
  }

  private calculateMaxPoints(): number {
    return this.education.maxPoints + this.language.maxPoints + this.workExp.maxPoints
  }
}

export default class PointCalculatorCategoryB {
  static calculate(profile: EditableProfile): CategoryB {
    return new CategoryB({
      education: EducationPointCalculator.calculateForSpouse(profile),
      language: LanguagePointCalculator.calculateForSpouse(profile),
      workExp: WorkExperiencePointCalculator.calculateForSpouse(profile),
    })
  }
}
