
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class AppSpinner extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  light!: boolean

  @Prop({ type: String })
  color?: string

  @Prop({ type: String })
  trackColor?: string

  @Prop({ type: String })
  thickness?: string

  @Prop({
    type: [String, Number],
    default: 25,
  })
  size!: string | number

  get style(): any {
    const thickness = this.thickness || (Number(this.size) / 10).toString()
    const style: any = {
      width: this.size + 'px',
      height: this.size + 'px',
      borderWidth: thickness + 'px',
      borderTopColor: this.trackColor,
      borderRightColor: this.trackColor,
      borderBottomColor: this.trackColor,
      borderLeftColor: this.color,
    }

    if (this.light) {
      const trackColor = 'rgba(255, 255, 255, 0.2)'
      style.borderTopColor = trackColor
      style.borderRightColor = trackColor
      style.borderBottomColor = trackColor
      style.borderLeftColor = '#ffffff'
    }

    return style
  }
}
