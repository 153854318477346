
import Vue from 'vue'
import Component from 'vue-class-component'

interface Review {
  authorPhotoUrl: string
  authorName: string
  message: string
}

/**
 * Displays a list of user reviews, shown on the questionnaire payment screen.
 */
@Component
export default class QuestionnairePaymentReviews extends Vue {
  reviews: Review[] = [
    {
      authorPhotoUrl: '',
      authorName: 'Prakash Gopal',
      message:
        'It was easy to follow the plan. Had to find out before searching ' +
        'and reading a lot forums and telegram channels. The Points Calculator rocks!',
    },
    {
      authorPhotoUrl: '',
      authorName: 'Leo Valteri',
      message:
        'This is what I needed! Step-by-step instructions with links to the official ' +
        'website. Otherwise you have to read a lot on the official website before planning ' +
        'what to do.',
    },
    {
      authorPhotoUrl: '',
      authorName: 'Daniel Park',
      message:
        'So smooth! All information very well organized in one place. Points improvement guide ' +
        'is really a game changer. I easily found out what exactly to improve.',
    },
  ]
}
