import EditableProfile from '../entity/profile/editable-profile.entity'
import EligibilityCriterion from '../entity/programs/eligibility-criterion.entity'
import EligibilityCriterionResult from '../entity/programs/eligibility-criterion-result.entity'
import EligibilityStatus from '../entity/programs/eligibility-status.entity'
import { NocGroup } from 'shared-entities'
import ProgramId from '../entity/programs/program-id.entity'

/**
 * Settlement funds requirement for each family member including the applicant themselves.
 */
const fundingRequirements: { [key: number]: number } = {
  1: 12960,
  2: 16135,
  3: 19836,
  4: 24083,
  5: 27315,
  6: 30806,
  7: 34299,
}

const fundingRequirementTableSize = 7

/** Funding requirements for each additional family member over 7 */
const additionalMemberCost = 3492

const proofOfFundsCriterion: EligibilityCriterion = {
  title: 'proofOfFunds',
  description: '',
}

export default class ProofOfFundsCalculator {
  static calculate({
    profile,
    programId,
  }: {
    profile: EditableProfile
    programId: ProgramId.FSW | ProgramId.FST
  }): EligibilityCriterionResult {
    let hasJobOffer = false
    if (programId === ProgramId.FSW) {
      hasJobOffer = profile.jobOffer !== null && profile.eligibility.fsw.hasJobOffer
    } else {
      hasJobOffer = profile.jobOffer === NocGroup.NOC_B && profile.eligibility.fst.hasJobOffer
    }

    if (hasJobOffer && profile.eligibility.workPermit) {
      return {
        criterion: proofOfFundsCriterion,
        status: EligibilityStatus.ELIGIBLE,
      }
    } else {
      const familySize = profile.eligibility.familySize || 1
      const funds = profile.eligibility.funds
      let requirement = fundingRequirements[familySize]
      if (!requirement) {
        const additionalMembers = familySize - fundingRequirementTableSize
        requirement =
          fundingRequirements[fundingRequirementTableSize] +
          additionalMembers * additionalMemberCost
      }

      return {
        criterion: proofOfFundsCriterion,
        status: funds >= requirement ? EligibilityStatus.ELIGIBLE : EligibilityStatus.NOT_ELIGIBLE,
      }
    }
  }
}
