
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import PremiumAdBanner from '@/ui/misc/PremiumAdBanner.vue'
import RouteNames from '@/ui/router/route-names'
import store from '@/store/store'
import { AuthTypes } from '@/store/modules/auth.module'

/**
 * Component to be used with Header, adds necessary paddings.
 */
@Component({
  components: {
    PremiumAdBanner,
  },
})
export default class Content extends Vue {
  /** If true, render the content at page width (12 / 12), instead of the normal 10 / 12 */
  @Prop({ type: Boolean, default: false })
  pageWidth!: boolean

  /** If true, render the content at full screen width. */
  @Prop({ type: Boolean, default: false })
  fullWidth!: boolean

  /** If true, then the premium ad banner will not be shown */
  @Prop({ type: Boolean, default: false })
  hidePremiumAd!: boolean

  get shouldShowPremium(): boolean {
    if (
      !store.getters[AuthTypes.getters.isSignedIn] ||
      !store.getters[AuthTypes.getters.hasSubscription]
    ) {
      const names: string[] = [
        RouteNames.SIGN_IN,
        RouteNames.PLANS,
        RouteNames.PREMIUM,
        RouteNames.PAYMENT_RESULT,
      ]
      const currentName = this.$route.name
      if (currentName) {
        return !names.includes(currentName)
      }
    }

    return false
  }
}
