/**
 * Defines unique document names
 */
export enum DocumentName {
  /**
   * The document containing information on user's progress in the 2nd, 3rd and 4th steps
   * of the guide (the ones with checkboxes). See [GuideProgress] entity.
   */
  CUSTOMER_GUIDE_PROGRESS = 'progress',
  /**
   * The document containing information on user's progress in the Status Center
   * portion of the guide.
   */
  CUSTOMER_STATUS_CENTER_PROGRESS = 'statusCenterProgress',

  /**
   * The document containing the user's archived profile, which is saved
   * when the user loses the permission to access his saved profile due to missing
   * payment.
   *
   * This document can then be used later after the customer pays for a plan
   * that gives access to the saved profile.
   */
  CUSTOMER_MAIN_PROFILE = 'main',

  /**
   * The document containing the most recently retrieved currency rates used to
   * calculate expenses in different currencies on the Expenses screen.
   */
  CURRENCY_RATES = 'currencyRates',

  /**
   * The document containing the pool data.
   */
  POOL_DATA = 'data',

  /**
   * The document containing onboarding configuration.
   */
  ONBOARDING_CONFIG = 'config',
}
