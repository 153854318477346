
import AuthRepository from '@/data/repository/auth.repository'
import OnboardingRepository from '@/data/repository/onboarding.repository'
import { AuthTypes } from '@/store/modules/auth.module'
import RouteHelper from '@/ui/router/route-helper'
import WindowManager from '@/ui/util/window-manager'
import { Customer, OnboardingCouponConfig } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import QuestionnaireUtils from '../questionnaire-utils'
import QuestionnairePaymentFaq from './component/QuestionnairePaymentFaq.vue'
import QuestionnairePaymentMain from './component/QuestionnairePaymentMain.vue'
import QuestionnairePaymentReviews from './component/QuestionnairePaymentReviews.vue'
import QuestionnairePaymentTimerLabel from './component/QuestionnairePaymentTimerLabel.vue'
import QuestionnairePaymentWarranty from './component/QuestionnairePaymentWarranty.vue'

/**
 * Screen shown to the user when they choose to purchase a plan.
 * Displays a list of features, price tag, a discount timer, etc.
 */
@Component({
  components: {
    QuestionnairePaymentTimerLabel,
    QuestionnairePaymentMain,
    QuestionnairePaymentReviews,
    QuestionnairePaymentFaq,
    QuestionnairePaymentWarranty,
  },
})
export default class QuestionnairePaymentScreen extends Vue {
  isInitialized = false
  isTimerComplete = false
  isPurchasingPlan = false
  isLoadingCouponConfig = false
  couponConfig: OnboardingCouponConfig | null = null

  mounted() {
    this.init()
  }

  private async init() {
    const user = await AuthRepository.asyncUser()
    if (user && user.customer) {
      await AuthRepository.asyncCustomer()
    }

    const questionnaireProfile = QuestionnaireUtils.getQuestionnaireProfile()
    if (!questionnaireProfile || this.$store.getters[AuthTypes.getters.hasSubscription]) {
      RouteHelper.default()
    } else {
      setTimeout(() => {
        this.activateOnboardingCoupon()
      })

      this.isInitialized = true
    }
  }

  private async activateOnboardingCoupon() {
    this.isLoadingCouponConfig = true
    const user = await AuthRepository.asyncUser()
    if (user) {
      const result = await OnboardingRepository.activateOnboardingCoupon()
      if (result.isSuccessful) {
        this.couponConfig = result.value
      }
      this.isLoadingCouponConfig = false
    } else {
      QuestionnaireUtils.setQuestionnaireResultExpected(true)
      RouteHelper.navigateToAuth()
      this.isLoadingCouponConfig = false
    }
  }

  onTimerComplete() {
    this.isTimerComplete = true
  }

  async purchasePlan() {
    this.isPurchasingPlan = true
    const result = await OnboardingRepository.purchasePlan()
    if (result.isUnsuccessful) {
      this.$snackbar.show({ message: result.errorMessage || 'Unknown error' })
    }
    this.isPurchasingPlan = false
    WindowManager.enableConfirmTabClose()
  }
}
