import { LanguageSkillScore } from '@/data/entity/profile/language-skill-score'
import { ALL_LANGUAGE_TESTS, LanguageTest } from '@/data/entity/profile/language-test'
import {
  LanguageTestScores,
  LanguageTestType,
  Profile,
  EducationLevel,
  LanguageLevel,
  LanguageLevelUtils,
  SpouseProfile,
  EligibilityFields,
  NocGroup,
  ImmigrationTime,
  LANGUAGE_SKILLS,
  ProvinceId,
} from 'shared-entities'
import { Expose, classToPlain } from 'class-transformer'
import cloneDeep from 'lodash.clonedeep'
import Utils from '@/common/util/utils'

export default class EditableProfile implements Profile {
  immigrationTime: ImmigrationTime | null = null
  /** Age in years */
  age: number | null = null

  /** Education level ID */
  education: EducationLevel | null = null

  langLevel: LanguageLevel = LanguageLevelUtils.create()

  @Expose({ name: 'firstLangTestType' })
  protected _firstLanguageTestType: LanguageTestType | null = null
  @Expose({ name: 'secondLangTestType' })
  protected _secondLanguageTestType: LanguageTestType | null = null

  /** Canadian work experience */
  workExp: number = 0

  /** Foreign work experience */
  foreignWorkExp: number = 0

  get totalWorkExp(): number {
    return this.workExp + this.foreignWorkExp
  }

  /** Certificate of qualification from a Canadian profince or territory (trade occupations) */
  hasCert: boolean = false

  @Expose({ name: 'hasSpouse' })
  _hasSpouse: boolean = false

  // We keep the spouse profile initialized even if the user doesn't have a spouse.
  // In case he toggles the hasSpouse flag, the data he entered won't be lost.
  spouse!: EditableProfile

  didSwitchSpouse = false

  /** Has a brother or sister living in Canada who is a permanent resident or citizen of Canada */
  @Expose({ name: 'hasCanadianSibling' })
  _hasCanadianSibling: boolean = false

  get hasCanadianSibling(): boolean {
    return this._hasCanadianSibling
  }

  set hasCanadianSibling(value: boolean) {
    this._hasCanadianSibling = value
    this.spouse._hasCanadianSibling = value
  }

  /** Number of years studied in a post-secondary institution in Canada */
  canadianEducation: number = 0

  /** If applicant has an arranged employment, this is the NOC group it the job belongs to */
  jobOffer: NocGroup | null = null

  /** Whether applicant has a provincial or territorial nomination */
  hasProvNomination: boolean = false

  eligibility: EligibilityFields = {
    cec: {
      hasWorkExp: false,
      workExpType: null,
    },
    fsw: {
      hasWorkExp: false,
      hasJobOffer: false,
      hasRelativesInCanada: false,
      canadianEducation: false,
      spouseCanadianEducation: false,
    },
    fst: {
      hasCert: false,
      hasJobOffer: false,
      hasWorkExp: false,
    },
    pnp: {},
    familySize: 1,
    funds: 0,
    workPermit: false,
  }

  desiredProvinces = {
    [ProvinceId.Ontario]: true,
    [ProvinceId.BritishColumbia]: true,
    [ProvinceId.Alberta]: true,
    [ProvinceId.Manitoba]: true,
    [ProvinceId.Saskachewan]: true,
    [ProvinceId.Winnipeg]: true,
    [ProvinceId.NovaScotia]: true,
    [ProvinceId.NewfoundlandAndLabrador]: true,
    [ProvinceId.NewBrunswick]: true,
    [ProvinceId.PrinceEdwardIsland]: true,
  }

  version = 2

  private constructor() {}

  static create(): EditableProfile {
    const profile = new EditableProfile()
    profile.spouse = new EditableProfile()
    return profile
  }

  static fromPlainProfile(profile: Profile): EditableProfile {
    const editableProfile = this.create()
    editableProfile.applyProfile(profile)
    return editableProfile
  }

  /**
   * Copy the fields from the provided profile object into this object.
   * @param profile The previously saved profile.
   */
  applyProfile(profile: Profile) {
    this.applyProfileFields(profile)
    if (this.spouse && profile.spouse) {
      this.spouse.applyProfileFields(profile.spouse)
    }
  }

  private applyProfileFields(profile: SpouseProfile) {
    this.assignObjectField(profile, 'immigrationTime')
    this.assignNumberField(profile, 'age', true)
    this.assignNumberField(profile, 'education', true)
    this.assignObjectField(profile, 'langLevel')
    this.assignStringField(profile, 'firstLangTestType', true, '_firstLanguageTestType')
    this.assignStringField(profile, 'secondLangTestType', true, '_secondLanguageTestType')
    this.assignNumberField(profile, 'workExp')
    this.assignNumberField(profile, 'foreignWorkExp')
    this.assignBooleanField(profile, 'hasCert')
    this.assignBooleanField(profile, 'hasSpouse', '_hasSpouse')
    this.assignBooleanField(profile, 'hasCanadianSibling', '_hasCanadianSibling')
    this.assignBooleanField(profile, 'didSwitchSpouse')
    this.assignBooleanField(profile, 'hasProvNomination')
    this.assignNumberField(profile, 'canadianEducation')
    this.assignNumberField(profile, 'version')
    this.assignObjectField(profile, 'eligibility')
    this.assignNumberField(profile, 'jobOffer', true)
  }

  private assignObjectField(profile: SpouseProfile, fieldName: keyof SpouseProfile) {
    const _this = this as any
    if (Utils.isObject(profile[fieldName])) {
      _this[fieldName] = cloneDeep(profile[fieldName])
    }
  }

  private assignBooleanField(
    profile: SpouseProfile,
    fieldName: keyof SpouseProfile,
    localFieldName?: string
  ) {
    const _this = this as any
    const value = profile[fieldName]
    if (typeof value === 'boolean') {
      if (localFieldName) {
        _this[localFieldName] = value
      } else {
        _this[fieldName] = value
      }
    }
  }

  private assignNumberField(
    profile: SpouseProfile,
    fieldName: keyof SpouseProfile,
    nullable?: boolean
  ) {
    const _this = this as any
    const value = profile[fieldName]
    if (
      (typeof value === 'number' && !isNaN(value) && isFinite(value)) ||
      (nullable && value === null)
    ) {
      _this[fieldName] = value
    }
  }

  private assignStringField(
    profile: SpouseProfile,
    fieldName: keyof SpouseProfile,
    nullable?: boolean,
    localFieldName?: string
  ) {
    const _this = this as any
    const value = profile[fieldName]
    if (typeof value === 'string' || (nullable && value === null)) {
      if (localFieldName) {
        _this[localFieldName] = value
      } else {
        _this[fieldName] = value
      }
    }
  }

  get hasSpouse(): boolean {
    return this._hasSpouse
  }

  set hasSpouse(value: boolean) {
    this._hasSpouse = value
    this.spouse._hasSpouse = value
  }

  get firstLangTestType(): LanguageTestType | null {
    return this._firstLanguageTestType
  }

  set firstLangTestType(value: LanguageTestType | null) {
    const originalValue = this._firstLanguageTestType
    this._firstLanguageTestType = value

    // If new value is a test for the same language as the secondLanguageTestType,
    // then we set the current value as the secondLanguageTestType.
    if (value && this.secondLangTestType) {
      if (this.firstLanguageTest!.language === this.secondLanguageTest!.language) {
        this._secondLanguageTestType = originalValue
      }
    }
  }

  get secondLangTestType(): LanguageTestType | null {
    return this._secondLanguageTestType
  }

  set secondLangTestType(value: LanguageTestType | null) {
    const originalValue = this._secondLanguageTestType
    this._secondLanguageTestType = value

    if (value && this.firstLangTestType) {
      if (this.firstLanguageTest!.language === this.secondLanguageTest!.language) {
        if (originalValue !== 'NONE') {
          this._firstLanguageTestType = originalValue
        } else {
          this._firstLanguageTestType = null
        }
      }
    }
  }

  get firstLanguageTest(): LanguageTest | null {
    if (this._firstLanguageTestType) {
      return ALL_LANGUAGE_TESTS.find(it => it.type == this._firstLanguageTestType) || null
    }
    return null
  }

  get secondLanguageTest(): LanguageTest | null {
    if (this._secondLanguageTestType) {
      return ALL_LANGUAGE_TESTS.find(it => it.type == this._secondLanguageTestType) || null
    }
    return null
  }

  setFirstLanguageSkillScore(score: LanguageSkillScore) {
    const level = this.langLevel[this.firstLangTestType!]
    if (level) {
      level[score.skill] = score.value
    }
  }

  setFirstLanguageScores(scores: LanguageTestScores) {
    const level = this.langLevel[this.firstLangTestType!]
    if (level) {
      LANGUAGE_SKILLS.forEach(skill => {
        level[skill] = scores[skill]
      })
    }
  }

  setSecondLanguageScores(scores: LanguageTestScores) {
    const level = this.langLevel[this.secondLangTestType!]
    if (level) {
      LANGUAGE_SKILLS.forEach(skill => {
        level[skill] = scores[skill]
      })
    }
  }

  setSecondLanguageSkillScore(score: LanguageSkillScore) {
    const level = this.langLevel[this.secondLangTestType!]
    if (level) {
      level[score.skill] = score.value
    }
  }

  getFirstLanguageClbScores(): LanguageTestScores | null {
    if (this.firstLangTestType) {
      return this.langLevel[this.firstLangTestType]
    }
    return null
  }

  getSecondLanguageClbScores(): LanguageTestScores | null {
    if (this.secondLangTestType) {
      return this.langLevel[this.secondLangTestType]
    }
    return null
  }

  swapLanguageTests() {
    const firstLanguageTestType = this.firstLangTestType
    this.firstLangTestType = this.secondLangTestType
    this.secondLangTestType = firstLanguageTestType
  }

  serialize(): any {
    const plain = classToPlain(this) as any
    delete plain.timestamp
    return plain
  }

  clone(): EditableProfile {
    const copy = EditableProfile.create()
    copy.applyProfile(this.serialize())
    return copy
  }

  copyWith<T extends keyof Profile>(field: T, value: Profile[T]): EditableProfile {
    const copy = EditableProfile.create()
    const plainProfile = this.serialize() as Profile
    plainProfile[field] = value
    copy.applyProfile(plainProfile)

    return copy
  }
}
