export default class Environment {
  static isProduction(): boolean {
    return !this.isTest()
  }

  static isTest(): boolean {
    const host = window.location.hostname
    return host === 'localhost' || host.startsWith('192') || host.startsWith('test')
  }
}
