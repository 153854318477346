enum RouteNames {
  QUESTIONNAIRE = 'questionnaire',
  QUESTIONNAIRE_RESULTS = 'questionnaireResults',
  QUESTIONNAIRE_PAYMENT = 'questionnairePayment',

  SIGN_IN = 'sign-in',
  PLANS = 'plans',
  PREMIUM = 'premium',
  PAYMENT_RESULT = 'payment-result',
  PROFILE = 'profile',
  HOME = 'home',

  GUIDE = 'guide',
  GUIDE_STATUS_CENTER = 'guideStatusCenter',
  GUIDE_ELIGIBILITY = 'eligibilityGuide',
  GUIDE_POOL = 'poolGuide',
  GUIDE_ITA = 'itaGuide',
  GUIDE_PR = 'prGuide',

  ROUNDS = 'rounds',
  EXPENSES = 'expenses',
  PROGRAMS = 'programs',
}

export default RouteNames
