
import { LayoutTypes } from '@/store/modules/layout.module'
import store from '@/store/store'
import RouteHelper from '@/ui/router/route-helper'
import RouteNames from '@/ui/router/route-names'
import AppUtils from '@/ui/util/app-utils'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import HeaderProfileButton from './HeaderProfileButton.vue'
import Navigation from './Navigation.vue'

/**
 * Display app header.
 *
 * Custom slot allowed, which replaces navigation.
 */
@Component({
  components: {
    HeaderProfileButton,
    Navigation,
  },
})
export default class Header extends Vue {
  /**
   * If true, then logo will not be displayed
   * If "phone", then then the logo will not be displayed on mobile devices.
   */
  @Prop({ type: [Boolean, String], default: false })
  noLogo!: boolean | string

  /** If true, then the Premium upgrade button will not be displayed. */
  @Prop({ type: Boolean, default: false })
  noUpgradeButton!: boolean

  /** If true, then the navigation will not be displayed. */
  @Prop({ type: Boolean, default: false })
  noNavigation!: boolean

  get shouldShowUpgradeBtn(): boolean {
    return AppUtils.shouldShowUpgradeButton() && !this.noUpgradeButton
  }

  get shouldShowLogo(): boolean {
    if (typeof this.noLogo === 'boolean') {
      return !this.noLogo
    } else {
      return true
    }
  }

  get shouldShowNavigation(): boolean {
    return !this.noNavigation
  }

  get hideLogoIfPhone(): boolean {
    return this.noLogo === 'phone'
  }

  navigateHome() {
    RouteHelper.default()
  }

  openNavDrawer() {
    store.commit(LayoutTypes.mutations.setNavDrawerOpen, true)
  }

  onUpgradePlanClick() {
    this.$router.push({ name: RouteNames.PREMIUM })
  }
}
