import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import User from '@/data/entity/user.entity'
import { classToPlain } from 'class-transformer'
import deepEqual from 'fast-deep-equal'
import firebase from 'firebase/compat/app'
import {
  Collection,
  DocumentName,
  GuideProgress,
  SpouseProfile,
  StatusCenterProgress,
} from 'shared-entities'
import { OperationError, OperationResult, OperationSuccess } from '../api/operation-result'
import asyncFirestore from '../firebase/async-firestore'
import AuthRepository from './auth.repository'
import store from '@/store/store'
import { ProfileTypes } from '@/store/modules/profile.module'
import FirestoreUtils from '../firebase/firestore-utils'
import PermissionChecker from '../claims/permission-checker'

export default class ProfileRepository {
  /**
   * Save the passed profile object to the current user's profile.
   */
  static async saveProfile(profile: EditableProfile): Promise<OperationResult<any>> {
    try {
      const user = AuthRepository.getUser()
      if (user && user.customer) {
        const plainProfile = profile.serialize()
        const plainOldProfile = user.customer.profile
          ? (classToPlain(user.customer.profile) as any)
          : void 0

        if (plainOldProfile) {
          delete plainOldProfile.timestamp
        }

        if (this.didProfileChange(plainProfile, plainOldProfile)) {
          await this.doSaveProfile(user, plainProfile)
        }

        return new OperationSuccess(null)
      } else {
        return new OperationError({ errorMessage: 'User not logged in' })
      }
    } catch (error) {
      console.error(error)
      let message = 'Unknown error'
      if (error instanceof Error) {
        message = error.message
      }
      return new OperationError({ errorMessage: message })
    }
  }

  /**
   * Return true if newProfile is different from oldProfile.
   */
  private static didProfileChange(newProfile: Object, oldProfile?: Object) {
    if (oldProfile) {
      return !deepEqual(newProfile, oldProfile)
    } else {
      return true
    }
  }

  /**
   * Switch the main and spouse profiles in the passed profile object.
   */
  static switchMainAndSpouseProfiles(profile: EditableProfile): EditableProfile {
    try {
      const user = AuthRepository.getUser()
      if (user) {
        const newMainProfile = profile.spouse
        newMainProfile.spouse = profile
        const spouseProfile = profile as SpouseProfile
        delete (spouseProfile as any).spouse

        profile.didSwitchSpouse = !profile.didSwitchSpouse
        newMainProfile.didSwitchSpouse = !newMainProfile.didSwitchSpouse

        return newMainProfile
      } else {
        console.error('User not logged in')
      }
    } catch (error) {
      console.error(error)
    }

    return profile
  }

  private static async doSaveProfile(user: User, profile: any): Promise<any> {
    const firestore = await asyncFirestore()
    profile.timestamp = firebase.firestore.FieldValue.serverTimestamp()
    await firestore
      .collection(Collection.CUSTOMERS)
      .doc(user.id)
      .update({ profile })
  }

  private static guideProgressSubscriptionUserId: string | null = null
  // Function returned by firebase after subscribing to guide progress, used to unsubscribe.
  private static unsubscribeFromGuideProgressFn: (() => void) | null = null

  /**
   * Subscribe to the guideProgress object associated with the current user if
   * they have access to this feature.
   */
  static updateGuideProgressSubscription() {
    const user = AuthRepository.getUser()
    if (!user) {
      console.error('User is not signed in')
      return
    }

    // Return if subscription already exists.
    if (this.guideProgressSubscriptionUserId && this.guideProgressSubscriptionUserId === user.id) {
      return
    }

    this.unsubscribeFromGuideProgress()

    PermissionChecker.check('guideProgress').then(permitted => {
      if (permitted) {
        asyncFirestore().then(firestore => {
          const ref = this.guideProgressRef(user, firestore)
          this.unsubscribeFromGuideProgressFn = ref.onSnapshot(
            snapshot => {
              const guideProgress = FirestoreUtils.convertTimestamps(
                snapshot.data()
              ) as GuideProgress
              if (guideProgress) {
                store.commit(ProfileTypes.mutations.updateGuideProgress, guideProgress)
              }
            },
            error => {
              console.error(error)
              this.unsubscribeFromGuideProgressFn = null
              this.guideProgressSubscriptionUserId = null
            }
          )
          this.guideProgressSubscriptionUserId = user.id
        })
      } else {
        this.unsubscribeFromGuideProgress()
      }
    })
  }

  /**
   * Unsubscribe from guide progress object.
   */
  static unsubscribeFromGuideProgress() {
    if (this.unsubscribeFromGuideProgressFn) {
      this.unsubscribeFromGuideProgressFn()
      this.unsubscribeFromGuideProgressFn = null
      this.guideProgressSubscriptionUserId = null
    }
  }

  /**
   * Update guide progress for the current user.
   */
  static async updateGuideProgress(guideProgress: GuideProgress): Promise<OperationResult<any>> {
    try {
      const user = AuthRepository.getUser()
      if (!user) {
        return new OperationError({ errorMessage: 'User not signed in' })
      }

      const firestore = await asyncFirestore()
      const ref = this.guideProgressRef(user, firestore)
      await ref.set(guideProgress)

      return new OperationSuccess(null)
    } catch (error) {
      console.error(error)
      return OperationError.fromError(error)
    }
  }

  /**
   * Return a document reference for a guide progress object.
   */
  private static guideProgressRef(
    user: User,
    firestore: firebase.firestore.Firestore
  ): firebase.firestore.DocumentReference {
    return firestore
      .collection(Collection.CUSTOMERS + '/' + user.id + '/' + Collection.CUSTOMER_GUIDE_PROGRESS)
      .doc(DocumentName.CUSTOMER_GUIDE_PROGRESS)
  }

  private static statusCenterProgressSubscriptionUserId: string | null = null
  private static unsubscribeFromStatusCenterProgressFn: (() => void) | null = null

  /**
   * Subscribe to the status center data if the current user has access
   * to this feature.
   */
  static updateStatusCenterSubscription() {
    const user = AuthRepository.getUser()
    if (!user) {
      console.error('User is not signed in')
      return
    }

    // Return if subscription already exists.
    if (
      this.statusCenterProgressSubscriptionUserId &&
      this.statusCenterProgressSubscriptionUserId === user.id
    ) {
      return
    }

    this.unsubscribeFromStatusCenterProgress()

    PermissionChecker.check('statusCenter').then(permitted => {
      if (permitted) {
        asyncFirestore().then(firestore => {
          const ref = this.statusCenterProgressRef(user, firestore)
          this.unsubscribeFromStatusCenterProgressFn = ref.onSnapshot(
            snapshot => {
              const statusCenterProgress = snapshot.data() as StatusCenterProgress
              if (statusCenterProgress) {
                store.commit(
                  ProfileTypes.mutations.updateStatusCenterProgress,
                  statusCenterProgress
                )
              }
            },
            error => {
              console.error(error)
              this.unsubscribeFromGuideProgressFn = null
              this.statusCenterProgressSubscriptionUserId = null
            }
          )
          this.statusCenterProgressSubscriptionUserId = user.id
        })
      } else {
        this.unsubscribeFromStatusCenterProgress()
      }
    })
  }

  static unsubscribeFromStatusCenterProgress() {
    if (this.unsubscribeFromStatusCenterProgressFn) {
      this.unsubscribeFromStatusCenterProgressFn()
      this.unsubscribeFromStatusCenterProgressFn = null
      this.statusCenterProgressSubscriptionUserId = null
    }
  }

  static async updateStatusCenterProgress(
    statusCenterProgress: StatusCenterProgress
  ): Promise<OperationResult<any>> {
    try {
      const user = AuthRepository.getUser()
      if (!user) {
        return new OperationError({ errorMessage: 'User not signed in' })
      }

      const firestore = await asyncFirestore()
      const ref = this.statusCenterProgressRef(user, firestore)
      await ref.set(statusCenterProgress)

      return new OperationSuccess(null)
    } catch (error) {
      console.error(error)
      return OperationError.fromError(error)
    }
  }

  private static statusCenterProgressRef(user: User, firestore: firebase.firestore.Firestore) {
    return firestore
      .collection(Collection.CUSTOMERS + '/' + user.id + '/' + Collection.CUSTOMER_GUIDE_PROGRESS)
      .doc(DocumentName.CUSTOMER_STATUS_CENTER_PROGRESS)
  }
}
