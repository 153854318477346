
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Utils from '@/common/util/utils'

/**
 * Animates the content provided in the default slot when the [expand] flag
 * changes value
 */
@Component
export default class AppTransitionExpand extends Vue {
  /** If truthy, then the content is expanded, otherwise collapsed */
  @Prop({ type: [Object, Boolean, Array, String, Number], default: false })
  expand!: any

  /**
   * If true, then the computed height is cached to be reused later.
   * This can help avoid janky transitions, but may result in incorrect height being used.
   */
  @Prop({ type: Boolean, default: false })
  useCache!: boolean

  private cachedHeight: string | null = null

  enter(element: HTMLElement) {
    this.calculateHeight(element).then(height => {
      if (height) {
        const style = element.style
        style.height = '0'
        style.overflow = 'hidden'

        getComputedStyle(element).height

        style.height = height
      }
    })
  }

  private calculateHeight(element: HTMLElement): Promise<string | null> {
    if (this.useCache && this.cachedHeight) {
      return Promise.resolve(this.cachedHeight)
    } else {
      const width = getComputedStyle(element).width
      const style = element.style
      style.width = width
      style.position = 'absolute'
      style.visibility = 'hidden'
      style.height = 'auto'

      return Utils.delay(0).then(() => {
        this.cachedHeight = getComputedStyle(element).height

        style.removeProperty('width')
        style.removeProperty('position')
        style.removeProperty('visibility')

        return this.cachedHeight
      })
    }
  }

  afterEnter(element: HTMLElement) {
    element.style.height = 'auto'
    element.style.overflow = 'visible'
    this.cachedHeight = getComputedStyle(element).height
  }

  leave(element: HTMLElement) {
    const height = getComputedStyle(element).height
    this.cachedHeight = height

    element.style.height = height
    element.style.overflow = 'hidden'

    getComputedStyle(element).height

    setTimeout(() => {
      element.style.height = '0'
    })
  }

  afterLeave(element: HTMLElement) {
    element.style.overflow = 'visible'
  }
}
