
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import QuestionnaireButton from '../component/QuestionnaireButton.vue'

@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireCertificate extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  get hasCertificate(): boolean {
    return this.profile.hasCert
  }

  setHasCertificate(hasCertificate: boolean) {
    this.profile.hasCert = hasCertificate
    this.$emit('submit')
  }
}
