
import Vue from 'vue'
import Component from 'vue-class-component'

import WindowManager from '@/ui/util/window-manager'
import dimensions from '@/ui/styles/exports/dimensions.scss'
import ResponsiveUtils from '@/ui/util/responsive-utils'

import PointCalculatorTotalPoints from './point-calculator/common/PointCalculatorTotalPoints.vue'
import { LayoutTypes } from '@/store/modules/layout.module'
import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import { Watch } from 'vue-property-decorator'

const headerHeight = parseInt(dimensions.headerHeight, 10)

enum ScrollDirection {
  UP = 1,
  DOWN = 2,
}

interface StickyElement {
  element: HTMLElement
  top: number
}

@Component({
  components: {
    PointCalculatorTotalPoints,
  },
})
export default class GuideScreenHeader extends Vue {
  // Offset of the header relative to document
  headerOffset = 0
  headerTransition: string | null = null

  private stickyElements: StickyElement[] = []

  private mode: 'mobile' | 'desktop' | null = null

  mounted() {
    WindowManager.addResizeListener(this.onResize)
    this.onResize()
  }

  beforeDestroy() {
    WindowManager.removeScrollListener(this.onScroll)
  }

  private onResize() {
    const mode = ResponsiveUtils.isPhoneOrTablet() ? 'mobile' : 'desktop'
    if (this.mode !== mode) {
      this.updateListeners()
      this.mode = mode
    }
  }

  private updateListeners() {
    if (ResponsiveUtils.isPhoneOrTablet() && this.isOnPointCalculatorScreen) {
      this.queryStickyElements()
      WindowManager.addScrollListener(this.onScroll)
    } else {
      WindowManager.removeScrollListener(this.onScroll)
      this.resetStickyElements()
      this.headerOffset = 0
    }
  }

  private queryStickyElements() {
    this.$nextTick(() => {
      const stickyElements = document.querySelectorAll('.p-sticky')
      for (let i = 0; i < stickyElements.length; i++) {
        const top = getComputedStyle(stickyElements[i]).top || '0'
        this.stickyElements.push({
          element: stickyElements[i] as HTMLElement,
          top: parseInt(top, 10),
        })
      }
    })
  }

  // Whether the header is currently being moved (headerOffset is not fixed yet)
  private isMoving = false
  // The Y scroll position at which the scroll direction has changed previously
  private scrollStart = 0
  // The header offset at the last scroll direction change
  private startHeaderOffset = 0
  // Previous reading of scrollY (from previous scroll event)
  private lastScrollY = 0
  // Direction of scrolling determined from previous scroll event.
  private lastScrollDirection: ScrollDirection = ScrollDirection.UP

  private onScroll(scrollY: number) {
    // Fix for iOS Safari's elastic scrolling.
    if (scrollY < 0) {
      return
    }

    const direction: ScrollDirection =
      this.lastScrollY < scrollY ? ScrollDirection.DOWN : ScrollDirection.UP
    this.lastScrollY = scrollY
    if (direction !== this.lastScrollDirection) {
      // We start moving the header.
      this.isMoving = true
      // Add a fudge factor so that the current scroll event counts.
      this.scrollStart = scrollY + (direction === ScrollDirection.DOWN ? 1 : 2)
      this.startHeaderOffset = this.headerOffset
      this.lastScrollDirection = direction
    }

    if (this.isMoving) {
      // Clamp the offset value so that it doesn't jump on fast scrolling.
      this.headerOffset = Math.min(
        0,
        Math.max(this.startHeaderOffset - scrollY + this.scrollStart, -headerHeight)
      )
      if (Math.abs(this.headerOffset - this.startHeaderOffset) > headerHeight) {
        this.isMoving = false
      }
      this.updateStickyElements()
    }
  }

  private updateStickyElements() {
    for (let i = 0; i < this.stickyElements.length; i++) {
      const element = this.stickyElements[i].element
      const originalTop = this.stickyElements[i].top
      element.style.top = `${originalTop + this.headerOffset + headerHeight}px`
    }
  }

  private resetStickyElements() {
    for (let i = 0; i < this.stickyElements.length; i++) {
      const { element, top } = this.stickyElements[i]
      element.style.top = top + 'px'
    }
  }

  get headerStyle() {
    return {
      top: this.headerOffset + 'px',
      transition: this.headerTransition,
    }
  }

  get pointsStyle() {
    return {
      top: this.headerOffset + headerHeight - 1 + 'px',
    }
  }

  get isOnPointCalculatorScreen(): boolean {
    return this.$route.name === RouteNames.GUIDE_ELIGIBILITY
  }

  get isMobile(): boolean {
    return this.mode === 'mobile'
  }

  openPointCalculatorNav() {
    store.commit(LayoutTypes.mutations.togglePointCalculatorNav)
  }

  @Watch('isOnPointCalculatorScreen')
  private onRouteChanged() {
    if (!this.isOnPointCalculatorScreen) {
      this.headerOffset = 0
      this.headerTransition = 'top 0.2s ease-out'
    } else {
      this.headerTransition = null
    }
    this.updateListeners()
  }
}
