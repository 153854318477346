import { render, staticRenderFns } from "./GuideHelp.vue?vue&type=template&id=d38f89a2&"
import script from "./GuideHelp.vue?vue&type=script&lang=ts&"
export * from "./GuideHelp.vue?vue&type=script&lang=ts&"
import style0 from "./GuideHelp.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports