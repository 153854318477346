
import Vue from 'vue'
import Component from 'vue-class-component'
import store from '@/store/store'
import { ProfileTypes } from '@/store/modules/profile.module'
import { Prop } from 'vue-property-decorator'

@Component
export default class PointCalculatorTotalPoints extends Vue {
  get points() {
    return store.getters[ProfileTypes.getters.totalPoints]
  }
}
