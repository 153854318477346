import Vue from 'vue'
import Component from 'vue-class-component'
import Validators from './validators'
import { Prop, Inject } from 'vue-property-decorator'
import FormRegistrar from './form-registrar'

@Component
export default class ValidatableField extends Vue {
  @Inject({
    default: {
      register: () => {},
      unregister: () => {},
    },
  })
  form?: FormRegistrar

  @Prop()
  value!: any
  @Prop({ type: Array, default: () => [] })
  rules!: any[]
  @Prop({ type: Boolean, default: false })
  required!: boolean

  /** If true, then the field is validated immediately on first change */
  @Prop({ type: Boolean, default: false })
  autovalidate!: boolean

  errors: string[] = []
  pristine = true
  valid = true

  get shouldValidate(): boolean {
    return !this.pristine || this.autovalidate
  }

  get hasError(): boolean {
    return this.shouldValidate && !this.valid
  }

  get hasRules(): boolean {
    return !!this.rules.length || this.required
  }

  get errorMessage(): string | null {
    return this.errors && this.errors.length ? this.errors[0] : null
  }

  created() {
    this.form && this.form.register(this)
  }

  beforeDestroy() {
    this.form && this.form.unregister(this)
  }

  reset() {
    this.pristine = true
    this.valid = true
  }

  validate(): boolean {
    const errors = []
    const value = this.value
    this.pristine = false

    if (this.required) {
      const valid = Validators.required(value)
      if (valid === false || typeof valid === 'string') {
        errors.push(valid)
      }
    }

    for (let i = 0, len = this.rules.length; i < len; i++) {
      const rule = this.rules[i]
      let valid = null

      if (typeof rule === 'function') {
        valid = rule(value)
      } else if (typeof rule === 'string') {
        const validatorFn = Validators[rule]
        if (!validatorFn) {
          console.error(`Invalid rule: ${rule}`)
        } else {
          valid = validatorFn(value)
        }
      } else {
        console.error(`Invalid rule type: ${typeof rule}, must be either a string or a function`)
      }

      if (valid !== null) {
        if (valid === false || typeof valid === 'string') {
          errors.push(valid)
        } else if (valid !== true) {
          console.error(`Rules should return a string or boolean, received ${typeof valid} instead`)
        }
      }
    }

    this.errors = errors
    this.valid = errors.length === 0

    return this.valid
  }
}
