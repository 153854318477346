
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ColorUtils from '../util/color-utils'

let lastUsedIdSuffix = 0

@Component
export default class AppCheckbox extends Vue {
  @Prop({ type: Boolean, default: false })
  value!: boolean

  @Prop({ type: String, default: 'accent' })
  color!: string

  id: string = `app-checkbox-${++lastUsedIdSuffix}`

  get indicatorStyle() {
    if (this.value) {
      const color = this.getColor()
      return {
        backgroundColor: color,
        borderColor: color,
      }
    } else {
      return null
    }
  }

  private getColor(): string {
    return this.color.startsWith('#') ? this.color : ColorUtils.namedColor(this.color)
  }

  get indicatorHighlightStyle() {
    const color = this.getColor()
    return {
      backgroundColor: color,
    }
  }

  toggle() {
    this.$emit('input', !this.value)
  }
}
