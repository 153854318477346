
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class AppCurrency extends Vue {
  @Prop() amount!: number
  @Prop({ type: [String, Number], default: 16 })
  size!: string | number

  get fontSize(): string {
    return `${this.size}px`
  }

  get symbolSize(): string {
    const size = Number(this.size)
    return `${size - 2}px`
  }
}
