import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import LocalStorage from '@/data/util/local-storage'
import { LANGUAGE_SKILLS, Profile } from 'shared-entities'

/** True if the user is expecting the result of the questionnaire */
const LS_KEY_QUESTIONNAIRE_RESULT_EXPECTED = 'app_questionnaireResultExpected'
/** The Profile created by answering the questions. */
const LS_KEY_QUESTIONNAIRE_PROFILE = 'app_questionnaireProfile'
/** True if the QuestionnaireResultProgress was shown to the user */
const LS_KEY_DID_SHOW_QUESTIONNAIRE_RESULT_PROGRESS = 'app_didShowQuestionnaireResultProgress'

export default class QuestionnaireUtils {
  /** Must be called when the user completes the questionnaire. */
  static setQuestionnaireCompleted(profile: EditableProfile) {
    this.adjustProfile(profile)
    LocalStorage.putItem(LS_KEY_QUESTIONNAIRE_RESULT_EXPECTED, true)
    LocalStorage.putItem(LS_KEY_QUESTIONNAIRE_PROFILE, profile.serialize())
  }

  /** Set the flag indicating that the user expects to see the result of the questionnaire. */
  static setQuestionnaireResultExpected(value: boolean) {
    LocalStorage.putItem(LS_KEY_QUESTIONNAIRE_RESULT_EXPECTED, value)
  }

  /** Return true if the use is expecting to see the result of the questionnaire. */
  static isQuestionnaireResultExpected(): boolean {
    return LocalStorage.getBoolean(LS_KEY_QUESTIONNAIRE_RESULT_EXPECTED, false)
  }

  /** Get the profile generated after the user filled out the questionnaire. */
  static getQuestionnaireProfile(): Profile | null {
    return LocalStorage.getItem(LS_KEY_QUESTIONNAIRE_PROFILE, null)
  }

  static removeQuestionnaireProfile(): void {
    LocalStorage.removeItem(LS_KEY_QUESTIONNAIRE_PROFILE)
  }

  static didShowQuestionnaireResultProgress(): boolean {
    return LocalStorage.getBoolean(LS_KEY_DID_SHOW_QUESTIONNAIRE_RESULT_PROGRESS, false)
  }

  static setDidShowQuestionnaireResultProgress(value: boolean): void {
    LocalStorage.putItem(LS_KEY_DID_SHOW_QUESTIONNAIRE_RESULT_PROGRESS, value)
  }

  /**
   * Clean up the state when signing out.
   */
  static cleanup() {
    this.setQuestionnaireResultExpected(false)
    this.removeQuestionnaireProfile()
    this.setDidShowQuestionnaireResultProgress(false)
  }

  /**
   * Adjust the given profile so that it is consistent with the rest of the application.
   */
  private static adjustProfile(profile: EditableProfile): void {
    this.adjustProfileLanguageScores(profile)
  }

  /**
   * Adjust the language scores of the given profile. In particular, cap CLB scores to 10,
   * since scores of 11 and 12 are equivalent to 10 when used in CRS score calculation.
   */
  private static adjustProfileLanguageScores(profile: EditableProfile): void {
    const ieltsScores = profile.langLevel.IELTS
    const tefScores = profile.langLevel.TEF

    if (ieltsScores || tefScores) {
      for (let i = 0; i < LANGUAGE_SKILLS.length; i++) {
        const skill = LANGUAGE_SKILLS[i]
        if (ieltsScores) {
          ieltsScores[skill] = Math.min(ieltsScores[skill], 10)
        }
        if (tefScores) {
          tefScores[skill] = Math.min(tefScores[skill], 10)
        }
      }
    }
  }
}
