import Vue from 'vue'
import FormattingUtils from '@/common/util/formatting-utils'
import { Dayjs } from 'dayjs'

Vue.filter('phoneNumber', (value: string) => {
  if (value) {
    const result = FormattingUtils.formatPhoneNumber(value)
    return result
  }
  return value
})

Vue.filter('date', (value: Dayjs | Date | string) => {
  if (typeof value === 'string') {
    return FormattingUtils.formatDate(new Date(value))
  } else if (value) {
    return FormattingUtils.formatDate(value)
  }
  return ''
})

Vue.filter('dateTime', (value: Dayjs | Date) => {
  if (value) {
    return FormattingUtils.formatDateTime(value)
  }
  return ''
})

Vue.filter('dateTimeZone', (value: Dayjs | Date) => {
  if (value) {
    return FormattingUtils.formatDateWithTimeZone(value)
  }
  return ''
})
