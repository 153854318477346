
import Vue from 'vue'
import Component from 'vue-class-component'
import RouteHelper from './route-helper'

/** Screen shown if the route is invalid */
@Component
export default class PageNotFound extends Vue {
  mounted() {
    console.log('Page not found: ', this.$route.path)
  }

  goHome() {
    RouteHelper.default()
  }
}
