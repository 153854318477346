
import Vue from 'vue'
import Component from 'vue-class-component'
import FeedbackDialog from './FeedbackDialog.vue'
import AppUtils from '../util/app-utils'

@Component
export default class Footer extends Vue {
  leaveFeedback() {
    AppUtils.showFeedbackDialog(this)
  }
}
