
import Vue from 'vue'
import Component from 'vue-class-component'
import AppUtils from '../../util/app-utils'
import store from '@/store/store'
import { LayoutTypes } from '@/store/modules/layout.module'
import { AuthTypes } from '@/store/modules/auth.module'
import RouteNames from '../../router/route-names'
import User from '@/data/entity/user.entity'

@Component
export default class MobileNavigation extends Vue {
  get isSignedIn(): boolean {
    return store.getters[AuthTypes.getters.isSignedIn]
  }

  get userName(): string | null {
    const user: User | null = store.state.auth.user
    if (user) {
      return user.name || user.email
    }
    return null
  }

  get shouldShowUpgradeBtn(): boolean {
    return AppUtils.shouldShowUpgradeButton()
  }

  onProfileButtonClick() {
    this.closeMenu()
    if (this.isSignedIn) {
      this.goToProfile()
    } else {
      this.$router.push({ name: RouteNames.SIGN_IN })
    }
  }

  private goToProfile() {
    this.$router.push({ name: RouteNames.PROFILE })
    this.closeMenu()
  }

  onUpgradePlanClick() {
    this.$router.push({ name: RouteNames.PREMIUM })
    this.closeMenu()
  }

  async signOut() {
    const didSignOut = await AppUtils.signOut(this)
    if (didSignOut) {
      this.closeMenu()
    }
  }

  private closeMenu() {
    store.commit(LayoutTypes.mutations.setNavDrawerOpen, false)
  }

  leaveFeedback() {
    AppUtils.showFeedbackDialog(this)
  }
}
