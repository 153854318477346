
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import SelectOption from '@/common/interfaces/select-option'

/**
 * Renders a select element.
 *
 * Events:
 *  input: when the selected value changes
 *  show-help: if [showHelp] was true, and the help button was clicked.
 */
@Component
export default class AppSelect extends Vue {
  /**
   * Select's label
   */
  @Prop({ type: String })
  label?: string

  /**
   * If true, then the given label is only rendered in the popup dialog on mobile.
   */
  @Prop({ type: Boolean, default: false })
  labelHidden!: boolean

  /**
   * If true, then a help icon is shown next to the label, which emits
   * a 'show-help' event when clicked.
   */
  @Prop({ type: Boolean, default: false })
  showHelp!: boolean

  /**
   * If true, the label is rendered with a smaller font.
   */
  @Prop({ type: Boolean, default: false })
  smallLabel!: boolean

  /**
   * Hint string shown within the select element when nothing is selected.
   */
  @Prop({ type: String, default: 'label.notSelected' })
  hint!: string

  /**
   * List of select options.
   */
  @Prop({ type: Array, required: true })
  options!: SelectOption[]

  /**
   * Currently selected value - the ID of the selected option.
   */
  @Prop({ type: [Number, String] })
  value?: number | string

  /**
   * If true then the select is shown as disabled and cannot be interacted with.
   */
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  /**
   * If true, then a special clear button shown in the dropdown.
   */
  @Prop({ type: Boolean, default: false })
  nullable!: boolean

  @Prop({ type: [String, Number] })
  minHeight?: string | number

  isOpen = false

  onSelect(optionId: number) {
    this.$emit('input', optionId)
  }

  get buttonStyle(): any {
    return this.minHeight ? { minHeight: `${this.minHeight}px` } : null
  }

  get selectedOption(): SelectOption | null {
    if (typeof this.value !== 'undefined') {
      return this.options.find(it => it.id === this.value) || null
    }
    return null
  }
}
