
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

/**
 * A button styled to conform to the design of the questionnaire.
 * @slot contains the button contents.
 */
@Component
export default class QuestionnaireButton extends Vue {
  /** Render the button text as bold */
  @Prop({ type: Boolean, default: false })
  bold!: boolean

  /** Render the button as selected */
  @Prop({ type: Boolean, default: false })
  selected!: boolean
}
