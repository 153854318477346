
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import ColorUtils from '@/ui/util/color-utils'

@Component
export default class AppProgressBar extends Vue {
  /**
   * Progress value as a percentage.
   */
  @Prop({ type: Number, required: true })
  value!: number

  @Prop({ type: String, default: 'green' })
  color!: string

  @Prop({ type: Number, default: 8 })
  height!: number

  isIncreasing = false
  isDecreasing = false

  get containerStyle() {
    return {
      height: `${this.height}px`,
    }
  }

  get indicatorStyle() {
    return {
      width: `${this.value}%`,
      backgroundColor: ColorUtils.namedColor(this.color),
    }
  }

  @Watch('value')
  private onValueChanged(newValue: number, oldValue: number) {
    this.isIncreasing = newValue > oldValue
    this.isDecreasing = newValue < oldValue
    setTimeout(() => {
      this.isIncreasing = false
      this.isDecreasing = false
    }, 200)
  }
}
