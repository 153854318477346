import firebase from 'firebase/compat/app'
import { Feedback, Collection } from 'shared-entities'
import asyncFirestore from '../firebase/async-firestore'
import { OperationResult, OperationError, OperationSuccess } from '../api/operation-result'

export default class FeedbackRepository {
  static async submitFeedback(feedback: Feedback): Promise<OperationResult<any>> {
    try {
      const firestore = await asyncFirestore()
      const data = feedback as any
      data.date = firebase.firestore.FieldValue.serverTimestamp()
      await firestore.collection(Collection.FEEDBACK).add(feedback)
      return new OperationSuccess(null)
    } catch (error) {
      return OperationError.fromError(error)
    }
  }
}
