export class DiscountUtils {
  /**
   * Calculate the price with the discountPercent applied.
   * @param price The price to modify
   * @param discountPercent The discount percent, between 0 and 100 inclusive
   * @returns The updated price
   */
  static calculateDiscountedPrice(price: number, discountPercent: number): number {
    return price * (discountPercent / 100.0)
  }
}
