import { render, staticRenderFns } from "./GuideScreenHeader.vue?vue&type=template&id=380d27d6&"
import script from "./GuideScreenHeader.vue?vue&type=script&lang=ts&"
export * from "./GuideScreenHeader.vue?vue&type=script&lang=ts&"
import style0 from "./GuideScreenHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports