import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import SelectOption from '@/common/interfaces/select-option'
import PointCategory from '@/data/point-calculator/point-category'
import { EducationLevel } from 'shared-entities'

export default class EducationPointCalculator {
  static calculate(profile: EditableProfile): PointCategory {
    return {
      title: 'label.education',
      points: this.points(profile),
      maxPoints: this.maxPoints(profile),
    }
  }

  static calculateForSpouse(profile: EditableProfile): PointCategory {
    return {
      title: 'label.education',
      points: this.pointsForSpouse(profile),
      maxPoints: this.maxPointsForSpouse(profile),
    }
  }

  private static points(profile: EditableProfile): number {
    const educationLevelOption = this.findEducationLevelOption(profile.education)
    if (educationLevelOption) {
      return profile.hasSpouse
        ? educationLevelOption.pointsWithSpouse
        : educationLevelOption.pointsWithoutSpouse
    } else {
      return 0
    }
  }

  private static maxPoints(profile: EditableProfile): number {
    return profile.hasSpouse ? 140 : 150
  }

  private static pointsForSpouse(profile: EditableProfile): number {
    if (profile.hasSpouse) {
      const educationLevelOption = this.findEducationLevelOption(profile.spouse.education)
      return educationLevelOption ? educationLevelOption.pointsForSpouse : 0
    }
    return 0
  }

  private static maxPointsForSpouse(profile: EditableProfile): number {
    return profile.hasSpouse ? 10 : 0
  }

  private static findEducationLevelOption(
    educationLevelId: number | null
  ): EducationLevelOption | undefined {
    if (educationLevelId) {
      return EDUCATION_LEVEL_OPTIONS.find(it => it.id == educationLevelId)
    } else {
      return void 0
    }
  }
}

interface EducationLevelOption extends SelectOption {
  id: EducationLevel
  title: string
  shortTitle: string
  tinyTitle: string
  // Points earned if the applicant has a spouse
  pointsWithSpouse: number
  // Points earned if the applicant does not have a spouse
  pointsWithoutSpouse: number
  // Points earned by the applicant's spouse if there is one.
  pointsForSpouse: number
}

export const EDUCATION_LEVEL_OPTIONS: EducationLevelOption[] = [
  {
    id: EducationLevel.LESS_THAN_SECONDARY,
    title: 'profile.educationLevels.lessThanSecondary',
    shortTitle: 'profile.shortEducationLevels.lessThanSecondary',
    tinyTitle: 'profile.tinyEducationLevels.lessThanSecondary',
    pointsWithSpouse: 0,
    pointsWithoutSpouse: 0,
    pointsForSpouse: 0,
  },
  {
    id: EducationLevel.SECONDARY,
    title: 'profile.educationLevels.secondary',
    shortTitle: 'profile.shortEducationLevels.secondary',
    tinyTitle: 'profile.tinyEducationLevels.secondary',
    pointsWithSpouse: 28,
    pointsWithoutSpouse: 30,
    pointsForSpouse: 2,
  },
  {
    id: EducationLevel.ONE_YEAR_DEGREE,
    title: 'profile.educationLevels.oneYearDegree',
    shortTitle: 'profile.shortEducationLevels.oneYearDegree',
    tinyTitle: 'profile.tinyEducationLevels.oneYearDegree',
    pointsWithSpouse: 84,
    pointsWithoutSpouse: 90,
    pointsForSpouse: 6,
  },
  {
    id: EducationLevel.TWO_YEAR_DEGREE,
    title: 'profile.educationLevels.twoYearDegree',
    shortTitle: 'profile.shortEducationLevels.twoYearDegree',
    tinyTitle: 'profile.tinyEducationLevels.twoYearDegree',
    pointsWithSpouse: 91,
    pointsWithoutSpouse: 98,
    pointsForSpouse: 7,
  },
  {
    id: EducationLevel.BACHELORS_DEGREE,
    title: 'profile.educationLevels.bachelorsDegree',
    shortTitle: 'profile.shortEducationLevels.bachelorsDegree',
    tinyTitle: 'profile.tinyEducationLevels.bachelorsDegree',
    pointsWithSpouse: 112,
    pointsWithoutSpouse: 120,
    pointsForSpouse: 8,
  },
  {
    id: EducationLevel.TWO_DEGREES,
    title: 'profile.educationLevels.twoDegrees',
    shortTitle: 'profile.shortEducationLevels.twoDegrees',
    tinyTitle: 'profile.tinyEducationLevels.twoDegrees',
    pointsWithSpouse: 119,
    pointsWithoutSpouse: 128,
    pointsForSpouse: 9,
  },
  {
    id: EducationLevel.MASTERS_DEGREE,
    title: 'profile.educationLevels.mastersDegree',
    shortTitle: 'profile.shortEducationLevels.mastersDegree',
    tinyTitle: 'profile.tinyEducationLevels.mastersDegree',
    pointsWithSpouse: 126,
    pointsWithoutSpouse: 135,
    pointsForSpouse: 10,
  },
  {
    id: EducationLevel.PHD,
    title: 'profile.educationLevels.phd',
    shortTitle: 'profile.shortEducationLevels.phd',
    tinyTitle: 'profile.tinyEducationLevels.phd',
    pointsWithSpouse: 140,
    pointsWithoutSpouse: 150,
    pointsForSpouse: 10,
  },
]
