import { Coupon } from './coupon.entity'
import { Plan, PlanInterval } from './plan.entity'

export class PlanUtils {
  /**
   * Return the number of months represented by the given interval.
   */
  static getMonthCount(interval: PlanInterval): number {
    switch (interval) {
      case 'year':
        return 12
      case 'month':
        return 1
      case 'week':
        return 0.25
      case 'day':
        return 1 / 30
    }
  }

  /**
   * Get the price of the given plan per month.
   */
  static getPricePerMonth(plan: Plan): number {
    const monthCount = this.getMonthCount(plan.interval)
    return Math.round(plan.price / plan.intervalCount / monthCount)
  }

  /**
   * Get the interval title for the given plan.
   */
  static getIntervalTitle(plan: Plan): string {
    if (plan.interval === 'month' && plan.intervalCount === 2) {
      return 'intervalAlt.twoMonths'
    } else if (plan.interval === 'month' && plan.intervalCount === 4) {
      return 'intervalAlt.fourMonths'
    } else if (plan.interval === 'month' && plan.intervalCount === 6) {
      return 'intervalAlt.sixMonths'
    } else {
      return 'intervalAlt.' + plan.interval
    }
  }

  /**
   * Get the short title for the interval of this plan.
   */
  static getIntervalShortTitle(plan: Plan): string {
    if (plan.interval === 'month' && plan.intervalCount === 2) {
      return 'intervalShort.twoMonths'
    } else if (plan.interval === 'month' && plan.intervalCount === 4) {
      return 'intervalShort.fourMonths'
    } else if (plan.interval === 'month' && plan.intervalCount === 6) {
      return 'intervalShort.sixMonths'
    } else {
      return `intervalShort.${plan.interval}`
    }
  }

  /** Apply the given coupon to the price if the coupon is valid */
  static applyCouponToPrice(price: number, coupon: Coupon | null): number {
    if (coupon && coupon.isValid) {
      return Math.round(price * ((100 - coupon.percentOff) / 100))
    }
    return price
  }

  /**
   * Return the coupon associated with the given plan, if it exists, or null.
   */
  static getCoupon(plan: Plan): Coupon | null {
    if (plan.coupons && plan.coupons.length) {
      return plan.coupons[0]
    }
    return null
  }

  /**
   * Add the given coupon to the given plan. If a coupon with the same ID
   * already exists, then replace that coupon with the new one.
   *
   * @param {Plan} plan The plan to update.
   * @param {Coupon} coupon The new coupon.
   */
  static addCoupon(plan: Plan, coupon: Coupon): void {
    if (!plan.coupons) {
      plan.coupons = []
    }

    const existingCouponIndex = plan.coupons.findIndex(c => c.id === coupon.id)
    if (existingCouponIndex >= 0) {
      plan.coupons.splice(existingCouponIndex, 1, coupon)
    } else {
      plan.coupons.push(coupon)
    }
  }

  /**
   * Remove the given coupon from the given plan, if the coupon is applied to the plan.
   *
   * @param {Plan} plan The plan from which the coupon should be removed.
   * @param {Coupon} coupon The coupon to remove.
   *
   * @returns True if the coupon was removed from the plan, false otherwise.
   */
  static removeCoupon(plan: Plan, coupon: Coupon): boolean {
    if (plan.coupons) {
      const index = plan.coupons.findIndex(c => c.id === coupon.id)
      if (index >= 0) {
        plan.coupons.splice(index, 1)

        if (!plan.coupons.length) {
          delete plan.coupons
        }

        return true
      }
    }
    return false
  }

  /**
   * Sort the given array of plans such in ascending order of total duration.
   */
  static sortByDuration(plans: Plan[]): void {
    plans.sort((a, b) => {
      const aMonths = this.getMonthCount(a.interval) * a.intervalCount
      const bMonths = this.getMonthCount(b.interval) * b.intervalCount
      return aMonths - bMonths
    })
  }
}
