import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import Environment from '@/config/environment'

// The configuration below is not sensitive data. You can serenely add your config here
const config = Environment.isProduction()
  ? {
      apiKey: 'AIzaSyBsGs7C6xmvXdd3m01EKwxgoQUjwqTy1r8',
      authDomain: 'app.expresscanada.me',
      databaseURL: 'https://prod-immigo.firebaseio.com',
      projectId: 'prod-immigo',
      storageBucket: 'prod-immigo.appspot.com',
      messagingSenderId: '194481867224',
      appId: '1:194481867224:web:521f581d4f8506dd8b8aad',
      measurementId: 'G-YS56988GS2',
    }
  : {
      apiKey: 'AIzaSyBqaPt7KLQlD29oo9GjfppuFTB4QBWNMcU',
      authDomain: 'testapp.expresscanada.me',
      databaseURL: 'https://test-immigo.firebaseio.com',
      projectId: 'test-immigo',
      storageBucket: 'test-immigo.appspot.com',
      messagingSenderId: '442431504133',
      appId: '1:442431504133:web:5962f8d6924610f38e816c',
      measurementId: 'G-R9Y7M5EDYM',
    }

firebase.initializeApp(config)
firebase.analytics()
