/**
 * A subscription claim is a claim provided when a user purchases a subscription.
 *
 * These are various claims whose integer values should match the 'claim'
 * metadata field of the Product object in Stripe dashboard.
 *
 * In addition, a claim with a larger integer value will include all the features provided
 * by claims with smaller integer values.
 */
export enum SubscriptionClaim {
  STANDARD = 1,
  PLUS = 2,
  PREMIUM = 3,
}
