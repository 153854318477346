
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class GuideHelp extends Vue {
  items = [
    {
      title: 'ELIGIBILITY: points and eligibility',
      content: this.list([
        'Answer eligibility questions',
        'Calculate CRS points',
        'Check programs eligibility',
      ]),
    },
    {
      title: 'POOL: improve points and submit profile',
      content: this.list([
        'Improve CRS points',
        'Create IRCC account',
        'Submit Express Entry profile',
      ]),
    },
    {
      title: 'ITA: gather documents and apply',
      content: this.list([
        'Get Invitation To Apply (ITA)',
        'Prepare documents',
        'Submit PR application',
      ]),
    },
    {
      title: 'PR: visa, landing, pr card',
      content: this.list([
        'Acknowledgement of Receipt (AOR)',
        'Review of application',
        'Ready for visa email, passport request if required (PPR)',
        'Confirmation of Permanent Residence (CoPR)',
        'Visa if required',
        'PR Landing',
        'PR card',
      ]),
    },
  ]

  private list(items: string[]): string {
    return '<ul><li>' + items.join('</li><li>') + '</li></ul>'
  }

  isDisclaimerOpen = false
}
