import RootDialogController from '@/common/plugins/dialog/root-dialog-controller'
import AuthRepository from '@/data/repository/auth.repository'
import { AuthTypes } from '@/store/modules/auth.module'
import store from '@/store/store'
import FeedbackDialog from '@/ui/misc/FeedbackDialog.vue'
import GuideHelp from '@/ui/screens/guide/GuideHelp.vue'
import { SubscriptionUtils } from 'shared-entities'
import Vue from 'vue'
import RouteNames from '../router/route-names'
import router from '../router/router'
import WindowManager from './window-manager'

export default class AppUtils {
  static signOut(instance: Vue): Promise<boolean> {
    return instance.$dialog.confirm({
      title: instance.$ts('action.signOut'),
      message: instance.$ts('confirm.signOut'),
      confirmBtnText: 'action.signOut',
      onConfirm: () => {
        store.dispatch(AuthTypes.actions.signOut)
        if (window.AndroidBridge && window.AndroidBridge.clearCache) {
          window.AndroidBridge.clearCache()
        }
        setTimeout(() => {
          WindowManager.reloadPage()
        }, 300)
      },
    })
  }

  private static routesRestrictingUpgradeButton: { [routeName: string]: number } = {
    [RouteNames.PLANS]: 1,
    [RouteNames.PREMIUM]: 1,
    [RouteNames.QUESTIONNAIRE]: 1,
    [RouteNames.QUESTIONNAIRE_RESULTS]: 1,
    [RouteNames.QUESTIONNAIRE_PAYMENT]: 1,
    [RouteNames.SIGN_IN]: 1,
  }

  static shouldShowUpgradeButton(): boolean {
    const isSignedIn = store.getters[AuthTypes.getters.isSignedIn] as boolean
    const hasSubscription = store.getters[AuthTypes.getters.hasSubscription] as boolean

    const currentRouteName = router.currentRoute.name
    const isRestrictedByRoute = currentRouteName
      ? this.routesRestrictingUpgradeButton[currentRouteName]
      : false

    return (!isSignedIn || !hasSubscription) && !isRestrictedByRoute
  }

  static showComingSoonDialog(instance: Vue) {
    const title = 'Coming soon'
    let message =
      '<p>This feature is under development. Subscribe to our newsletter ' +
      'in your profile to be notified about new features and updates to ' +
      'the immigration process.</p>'

    const user = AuthRepository.getUser()
    const hasSubscription =
      user && user.customer && SubscriptionUtils.isSubscriptionActive(user.customer.subscription)
    if (!hasSubscription) {
      message +=
        '<p>You may also wish to upgrade to the Standard plan to be able to save your profile ' +
        "or switch to the spouse's profile.</p>"
    }

    if (hasSubscription) {
      instance.$dialog.alert({
        title,
        message,
      })
    } else {
      instance.$dialog.confirm({
        title: 'Coming soon',
        message,
        rejectBtnText: 'Close',
        confirmBtnText: 'Upgrade',
        onConfirm: () => {
          setTimeout(() => {
            router.push({ name: RouteNames.PREMIUM })
          })
        },
      })
    }
  }

  /** Show a dialog prompting the user to upgrade their plan. */
  static showUpgradeDialog() {
    RootDialogController.confirm({
      title: 'Upgrade required',
      message: 'Please upgrade your plan to continue.',
      confirmBtnText: 'Upgrade',
      rejectBtnText: 'Cancel',
      onConfirm: () => {
        setTimeout(() => {
          router.push({ name: RouteNames.PREMIUM })
        })
      },
    })
  }

  static showHelp(instance: Vue) {
    instance.$dialog.show({
      component: GuideHelp,
      large: true,
    })
  }

  static showFeedbackDialog(instance: Vue) {
    instance.$dialog.show({
      component: FeedbackDialog,
      large: true,
    })
  }
}
