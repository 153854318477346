interface SvgLoadingOptions {
  path: string
  filename?: string
  name: string
}

/**
 * Load svg via ajax
 * @generator: webpack-svgstore-plugin
 * @see: https://www.npmjs.com/package/webpack-svgstore-plugin
 */
const svgXHR = function(options: SvgLoadingOptions) {
  return new Promise<void>((resolve, reject) => {
    let url: string | undefined = void 0
    let baseUrl: string | undefined = void 0

    options && options.filename ? (url = options.filename) : null

    if (!url) return false
    let _ajax = new XMLHttpRequest()

    if (typeof window.XDomainRequest !== 'undefined') {
      _ajax = new window.XDomainRequest()
    }

    if (typeof baseUrl === 'undefined') {
      baseUrl =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    }

    const _fullPath = (baseUrl + '/' + url).replace(/([^:]\/)\/+/g, '$1')
    _ajax.open('GET', _fullPath, true)
    _ajax.onprogress = function() {}
    _ajax.onload = function() {
      if (!_ajax.responseText || _ajax.responseText.substr(0, 4) !== '<svg') {
        throw Error('Invalid SVG Response')
      }
      if (_ajax.status < 200 || _ajax.status >= 300) {
        return
      }
      const div = document.createElement('div')
      div.innerHTML = _ajax.responseText

      domready(function() {
        document.body.insertBefore(div, document.body.childNodes[0])
        resolve()
      })
    }

    _ajax.onerror = function(error) {
      reject(error)
    }

    _ajax.send()
  })
}

/**
 * jQuery like $.ready function.
 *
 * @param {Function} callback
 */
function domready(callback: () => void) {
  const documentElement: any = document.documentElement
  if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !documentElement.doScroll)
  ) {
    callback()
  } else {
    document.addEventListener('DOMContentLoaded', callback)
  }
}

/**
 * This configuration is for an SVG sprite file, which is collected by
 * webpack-svgstore-plugin.
 *
 * `path` below specifies the svg icons to be included into the sprite.
 * `name` indicates the name of the sprite file.
 *
 * The name of the variable `__svg__` is important becaused it is parsed and modified
 * during build. Doesn't work if you remove it.
 */
const __svg__ = {
  path: '../assets/icons/*.svg',
  name: 'public/icons-[hash].svg',
}

const promise = svgXHR(__svg__)

export default {
  ready: promise,
}
