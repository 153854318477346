
import { Coupon, Plan, PlanUtils } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class QuestionnaireResultPlanCard extends Vue {
  /** If true, then this plan card will be highlighted */
  @Prop({ type: Boolean, default: false })
  highlighted!: boolean

  /** The plan to display */
  @Prop({ required: true })
  plan!: Plan

  /** If true, then a spinner is shown on the Subscribe button */
  @Prop({ type: Boolean, default: false })
  submitting!: boolean

  /** If true, then the Subscribe button is disabled */
  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  /** Label for the Subscribe button. By default, 'Subscribe' is rendered. */
  @Prop({ type: String })
  buttonText?: string

  get coupon(): Coupon | null {
    return PlanUtils.getCoupon(this.plan)
  }

  get pricePerMonth(): string {
    const price =
      PlanUtils.applyCouponToPrice(PlanUtils.getPricePerMonth(this.plan), this.coupon) / 100
    return `${price} $`
  }

  get pricePerPeriod(): string {
    const price = PlanUtils.applyCouponToPrice(this.plan.price, this.coupon) / 100
    return `${price} $`
  }

  get oldPrice(): string {
    const price = this.plan.price / 100
    return `${price} $`
  }

  get duration(): string {
    return PlanUtils.getIntervalTitle(this.plan)
  }

  get descriptionHtml(): string {
    return this.plan.descriptionHtml || ''
  }

  subscribe() {
    this.$emit('subscribe')
  }
}
