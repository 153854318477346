
import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ValidatableField from './mixins/validatable-field.mixin'

let lastInputIdSuffix = 0

@Component
export default class AppTextarea extends mixins(ValidatableField) {
  @Prop({ type: String, required: true })
  value!: string
  @Prop(String)
  label?: string
  @Prop(String)
  hint?: string
  @Prop([Number, String])
  maxlength?: number | string
  @Prop({ type: Boolean, default: false })
  disabled!: boolean
  @Prop({ type: [String, Number], default: 4 })
  rows!: string | number

  inputId: string = `app-textarea-${++lastInputIdSuffix}`

  get length(): number {
    return this.value ? this.value.length : 0
  }

  get lengthHelpText(): string {
    return `${this.length} / ${this.maxlength}`
  }

  onInput() {
    this.$emit('input', this.textarea.value)
    this.$nextTick(() => {
      if (this.shouldValidate) {
        this.validate()
      }
    })
  }

  private get textarea(): HTMLTextAreaElement {
    return this.$refs.textarea as HTMLTextAreaElement
  }
}
