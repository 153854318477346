import I18n from '@/i18n/i18n'
import dimensions from '@/ui/styles/exports/dimensions.scss'
import WindowManager from './window-manager'

type VueRefs = { [key: string]: Vue | Element | (Vue | Element)[] | undefined }

export default class UiUtils {
  static getElementPosition(element: HTMLElement): { x: number; y: number } {
    const rect = element.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    return { x: rect.left + scrollLeft, y: rect.top + scrollTop }
  }

  static translateTitle(key: string) {
    return function() {
      return {
        inner: I18n.$t(key),
      }
    }
  }

  static scrollToElement(container: HTMLElement, index: number) {
    if (container.scrollHeight > container.clientHeight) {
      const child = container.children[index] as HTMLElement
      if (child) {
        const top = child.offsetTop - container.clientHeight / 2 + child.clientHeight / 2
        container.scrollTop = top
      }
    }
  }

  static getScrollTop(): number {
    return window.pageYOffset || document.documentElement.scrollTop
  }

  /**
   * Scroll to an element with an ID specified in the current route params
   * of the passed Vue instance. Used when navigating from another route to
   * the current one with the intent to scroll to a specific element.
   *
   * This method should be called in the target route's 'mounted' lifecycle hook.
   */
  static scrollByRouteParams(instance: Vue, smooth?: boolean) {
    const elementId = instance.$route.params.elementId
    if (elementId) {
      instance.$nextTick(() => {
        const element = instance.$el.querySelector(`#${elementId}`) as HTMLElement
        if (element) {
          const y = this.getElementY(element, true) - 16
          WindowManager.scrollTo(y, null, { smooth: smooth || false })
        }
      })
    }
  }

  /**
   * Scroll the window to the specified ref ID.
   * @param refs The Vue instance's $refs object
   * @param refId The ID of the ref
   * @param params.accountForHeader If true, then headerHeight from dimensions will be added
   *    to the final scroll offset
   * @param params.smooth If true, then the window will be scrolled smoothly
   */
  static scrollToRef(
    refs: VueRefs,
    refId: string,
    params: {
      offset?: number
      accountForHeader?: boolean
      smooth?: boolean
      callback?: () => void
    } = {}
  ) {
    const { accountForHeader = true, smooth = false, callback, offset = 0 } = params
    const element = this.getElementForRef(refs, refId)
    if (element) {
      const y = this.getElementY(element, accountForHeader) + offset
      WindowManager.scrollTo(y, callback, { smooth: smooth })
    }
  }

  static getElementY(element: HTMLElement, accountForHeader: boolean): number {
    const scrollY = window.scrollY || window.pageYOffset
    return (
      element.getBoundingClientRect().top + scrollY - (accountForHeader ? this.headerHeight : 0)
    )
  }

  private static get headerHeight(): number {
    return parseInt(dimensions.headerHeight, 10)
  }

  static getElementForRef(refs: VueRefs, refId: string): HTMLElement | null {
    let element: HTMLElement | null = null
    const ref = refs[refId]
    if (ref) {
      if (ref instanceof Array) {
        const item = ref[0]
        if (item) {
          if (item instanceof HTMLElement) {
            element = item
          } else {
            element = (item as any).$el
          }
        }
      } else {
        element = (ref as any).$el
      }
    }
    return element
  }
}
