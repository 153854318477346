
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { ALL_PROVINCES, Province } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

interface Option {
  province: Province
  isSelected: boolean
}

@Component
export default class QuestionnaireDesiredProvince extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  options: Option[] = ALL_PROVINCES.map(province => ({
    province,
    isSelected: this.profile.desiredProvinces[province.id],
  }))

  toggleOption(option: Option) {
    option.isSelected = !option.isSelected
  }

  @Watch('options', { deep: true })
  onOptionsChanged() {
    this.options.forEach(option => {
      this.profile.desiredProvinces[option.province.id] = option.isSelected
    })
  }

  submit() {
    this.$emit('submit')
  }
}
