
import Vue from 'vue'
import Component from 'vue-class-component'
import raf from 'raf'

import Utils from '@/common/util/utils'
import styles from './_questionnaire-processing-results.scss'
import ResponsiveUtils from '@/ui/util/responsive-utils'
import WindowManager from '@/ui/util/window-manager'

// Total progress duration
const PROGRESS_DURATION_MS = 3000

/**
 * A circular progress indicator shown when the user's results are being processed.
 */
@Component
export default class QuestionnaireResultProgress extends Vue {
  // The size of the circular progress, defined in SCSS
  progressSize = 0
  // Current progress value that is displayed
  progress = 0
  // The progress value to which we are currently animating
  nextProgress = 0

  private animationHandle: number | null = null
  private pauseTimeoutHandle: number | null = null

  private startTime = -1
  private previousUpdateTime = -1
  private incrementEndTime = -1

  mounted() {
    this.initializeProgress()
    this.updateProgressBarSize()
    WindowManager.addResizeListener(this.onWindowResize)
  }

  beforeDestroy() {
    if (this.animationHandle) {
      raf.cancel(this.animationHandle)
      this.animationHandle = null
    }
    if (this.pauseTimeoutHandle) {
      clearTimeout(this.pauseTimeoutHandle)
      this.pauseTimeoutHandle = null
    }
    WindowManager.removeResizeListener(this.onWindowResize)
  }

  private onWindowResize() {
    this.updateProgressBarSize()
  }

  private updateProgressBarSize() {
    if (ResponsiveUtils.isPhone()) {
      this.progressSize = parseInt(styles.sizeSmall, 10)
    } else {
      this.progressSize = parseInt(styles.size, 10)
    }
  }

  private initializeProgress() {
    this.startTime = Date.now()
    this.progress = 0
    this.nextProgress = 0
    this.incrementProgress()
  }

  private incrementProgress() {
    if (this.progress >= 1) {
      return
    }

    const progressIncrement = Utils.random(0.05, 0.3)
    const now = Date.now()
    this.previousUpdateTime = now
    const remainingTime = PROGRESS_DURATION_MS - (now - this.startTime)
    this.incrementEndTime =
      now + Math.max(remainingTime * Math.min(progressIncrement, 1 - this.progress), 10)

    this.nextProgress = Math.min(1, this.nextProgress + progressIncrement)
    this.startAnimation()
  }

  private startAnimation() {
    this.animationHandle = raf(this.updateProgress)
  }

  private updateProgress() {
    const now = Date.now()
    const t = Utils.mapInterval(now, [this.previousUpdateTime, this.incrementEndTime], [0, 1])
    const newProgress = Utils.lerp(this.progress, this.nextProgress, t)
    this.previousUpdateTime = now

    this.progress = Math.min(1, newProgress)
    if (this.progress < this.nextProgress) {
      this.animationHandle = raf(this.updateProgress)
    } else if (this.progress < 1) {
      // Schedule next update
      this.pauseThenContinue()
    } else {
      // We're done
      this.$emit('done')
    }
  }

  private pauseThenContinue() {
    this.animationHandle = null
    this.pauseTimeoutHandle = setTimeout(() => {
      this.incrementProgress()
    }, Utils.random(60, 150))
  }

  get progressPercent(): number {
    return Math.round(this.progress * 100)
  }

  get progressPath(): string {
    return this.getPath(this.progress)
  }

  get backgroundPath(): string {
    return this.getPath(1)
  }

  private getPath(progress: number): string {
    const s = this.progressSize
    const R = s / 2
    const arc = progress * 2 * Math.PI - 1e-5
    const x = R + R * Math.sin(arc)
    const y = R - R * Math.cos(arc)
    const largeArc = arc > Math.PI ? 1 : 0
    return `M${R} ${R}L${R} 0A${R} ${R} 0 ${largeArc} 1 ${x} ${y}Z`
  }
}
