import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCategory from '@/data/point-calculator/point-category'
import { EducationLevel, LanguageTestScoresUtils } from 'shared-entities'

interface ICategoryC {
  educationPlusLanguage: PointCategory
  educationPlusCanadianWorkExp: PointCategory
  foreignWorkExpPlusLanguage: PointCategory
  foreignWorkExpPlusCanadianWorkExp: PointCategory
  certificatePlusLanguage: PointCategory
}

export class CategoryC implements PointCategory {
  readonly title = 'C'
  readonly description = 'category.c'

  readonly points: number
  readonly maxPoints: number = 100 // Hard cap

  readonly educationPlusLanguage: PointCategory
  readonly educationPlusCanadianWorkExp: PointCategory
  readonly foreignWorkExpPlusLanguage: PointCategory
  readonly foreignWorkExpPlusCanadianWorkExp: PointCategory
  readonly certificatePlusLanguage: PointCategory

  constructor(values: ICategoryC) {
    this.educationPlusLanguage = values.educationPlusLanguage
    this.educationPlusCanadianWorkExp = values.educationPlusCanadianWorkExp
    this.foreignWorkExpPlusLanguage = values.foreignWorkExpPlusLanguage
    this.foreignWorkExpPlusCanadianWorkExp = values.foreignWorkExpPlusCanadianWorkExp
    this.certificatePlusLanguage = values.certificatePlusLanguage
    this.points = this.calculateTotal()
  }

  private calculateTotal(): number {
    return Math.min(
      this.maxPoints,
      this.educationPlusLanguage.points +
        this.educationPlusCanadianWorkExp.points +
        this.foreignWorkExpPlusLanguage.points +
        this.foreignWorkExpPlusCanadianWorkExp.points +
        this.certificatePlusLanguage.points
    )
  }
}

export default class PointCalculatorCategoryC {
  static calculate(profile: EditableProfile): CategoryC {
    return new CategoryC({
      educationPlusLanguage: this.educationPlusLanguage(profile),
      educationPlusCanadianWorkExp: this.educationPlusCanadianWorkExp(profile),
      foreignWorkExpPlusLanguage: this.foreignWorkExpPlusLanguage(profile),
      foreignWorkExpPlusCanadianWorkExp: this.foreignWorkExpPlusCanadianWorkExp(profile),
      certificatePlusLanguage: this.certificatePlusLanguage(profile),
    })
  }

  private static educationPlusLanguage(profile: EditableProfile): PointCategory {
    let points = 0
    const educationLevel = profile.education

    if (educationLevel && educationLevel > EducationLevel.SECONDARY) {
      const clbScores = profile.getFirstLanguageClbScores()
      if (clbScores) {
        if (LanguageTestScoresUtils.everySkill(clbScores, score => score >= 9)) {
          points = 25
          if (educationLevel >= EducationLevel.TWO_DEGREES) {
            points = 50
          }
        } else if (LanguageTestScoresUtils.everySkill(clbScores, score => score >= 7)) {
          points = 13
          if (educationLevel >= EducationLevel.TWO_DEGREES) {
            points = 25
          }
        }
      }
    }

    return { points, maxPoints: 50 }
  }

  private static educationPlusCanadianWorkExp(profile: EditableProfile): PointCategory {
    let points = 0
    const educationLevel = profile.education
    const workExperience = profile.workExp

    if (educationLevel && educationLevel > EducationLevel.SECONDARY && workExperience >= 1) {
      if (workExperience >= 2) {
        points = 25
        if (educationLevel > EducationLevel.TWO_DEGREES) {
          points = 50
        }
      } else if (workExperience >= 1) {
        points = 13
        if (educationLevel > EducationLevel.TWO_DEGREES) {
          points = 25
        }
      }
    }

    return { points, maxPoints: 50 }
  }

  private static foreignWorkExpPlusLanguage(profile: EditableProfile): PointCategory {
    let points = 0

    const foreignWorkExp = profile.foreignWorkExp

    const clbScores = profile.getFirstLanguageClbScores()
    if (clbScores) {
      if (LanguageTestScoresUtils.everySkill(clbScores, score => score >= 9)) {
        points = foreignWorkExp >= 3 ? 50 : foreignWorkExp >= 1 ? 25 : 0
      } else if (LanguageTestScoresUtils.everySkill(clbScores, score => score >= 7)) {
        points = foreignWorkExp >= 3 ? 25 : foreignWorkExp >= 1 ? 13 : 0
      }
    }

    return { points, maxPoints: 50 }
  }

  private static foreignWorkExpPlusCanadianWorkExp(profile: EditableProfile): PointCategory {
    let points = 0

    const workExp = profile.workExp
    const foreignWorkExp = profile.foreignWorkExp

    if (workExp >= 2) {
      points = foreignWorkExp >= 3 ? 50 : foreignWorkExp >= 1 ? 25 : 0
    } else if (workExp >= 1) {
      points = foreignWorkExp >= 3 ? 25 : foreignWorkExp >= 1 ? 13 : 0
    }

    return { points, maxPoints: 50 }
  }

  private static certificatePlusLanguage(profile: EditableProfile): PointCategory {
    let points = 0

    const clbScores = profile.getFirstLanguageClbScores()
    if (clbScores) {
      if (LanguageTestScoresUtils.everySkill(clbScores, score => score >= 7)) {
        points = profile.hasCert ? 50 : 0
      } else if (LanguageTestScoresUtils.everySkill(clbScores, score => score >= 5)) {
        points = profile.hasCert ? 25 : 0
      }
    }

    return { points, maxPoints: 50 }
  }
}
