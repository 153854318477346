export default class ListUtils {
  static maxBy<T>(list: T[], fn: (item: T) => number): T {
    let max = list[0]
    let maxValue = fn(max)
    for (let i = 1; i < list.length; i++) {
      const value = fn(list[i])
      if (maxValue < value) {
        maxValue = value
        max = list[i]
      }
    }
    return max
  }

  static minBy<T>(list: T[], fn: (item: T) => number): T {
    let min = list[0]
    let minValue = fn(min)
    for (let i = 1; i < list.length; i++) {
      const value = fn(list[i])
      if (minValue > value) {
        minValue = value
        min = list[i]
      }
    }

    return min
  }

  static range(start: number, end: number): number[] {
    const result: number[] = []
    for (let i = start; i <= end; i++) {
      result.push(i)
    }
    return result
  }
}
