import PermissionChecker from '@/data/claims/permission-checker'
import AuthRepository from '@/data/repository/auth.repository'
import { AuthTypes } from '@/store/modules/auth.module'
import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import Vue from 'vue'
import VueHead from 'vue-head'
import VueRouter, { NavigationGuardNext, Route } from 'vue-router'
import AppUtils from '../util/app-utils'
import routes, { RouteMeta } from './route-configs'

// Set default browser title parameters.
// Actual page can be set in a screen component.
// Separator and complement will form the suffix of the title.
Vue.use(VueHead, {
  separator: '|',
  complement: process.env.VUE_APP_NAME,
})

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    // Restore scroll position when route changes.
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

// Disallow '/'
router.beforeEach((to, from, next) => {
  if (to.path !== '/') {
    next()
  }
})

/**
 * Return true if the current user is permitted access to the given route.
 */
async function hasPermissionForRoute(route: Route): Promise<boolean> {
  const meta = route.meta as RouteMeta
  if (meta && meta.unpaidOnly) {
    const user = await AuthRepository.asyncUser()
    if (user) {
      await AuthRepository.asyncCustomer()
      if (store.getters[AuthTypes.getters.hasSubscription]) {
        return false
      }
    }
  }

  const allPermissions = route.matched
    .map(config => (config.meta ? config.meta.permissions : void 0))
    .filter(permissions => !!permissions)
    .reduce((collection, list) => {
      Array.prototype.push.apply(collection, list)
      return collection
    }, [])

  if (allPermissions.length) {
    return await PermissionChecker.checkEvery(...allPermissions)
  } else {
    return true
  }
}

router.beforeEach(async (to, from, next) => {
  const meta = to.meta as RouteMeta
  console.debug('NAV:', from, to)
  if (meta && meta.noDirectAccess && !from.name) {
    return redirect(from, next)
  }

  if (!from.name) {
    store.commit(AuthTypes.mutations.setAuthorizingRoute, true)
  }
  const isPermitted = await hasPermissionForRoute(to)
  store.commit(AuthTypes.mutations.setAuthorizingRoute, false)

  console.debug('Permitted:', isPermitted)

  if (isPermitted) {
    next()
  } else {
    setTimeout(() => {
      redirect(from, next)
    }, 100)
  }
})

/**
 * Perform default redirection in case if the navigation guard wants to restrict access to
 * navigation target.
 * @param from The previous route.
 * @param next The function to be called to execute the redirection.
 * @param showUpgradeDialog
 *    If true and [from.name] is defined (that is, from is an actual route, and not just '/',
 *    meaning the user initiated this navigation by pressing a button in the UI rather than
 *    entering the URL directly), then the upgrade dialog will be shown.
 */
async function redirect(from: Route, next: NavigationGuardNext, showUpgradeDialog: boolean = true) {
  if (!from || !from.name) {
    let routeName: string
    await AuthRepository.asyncUser()
    if (store.getters[AuthTypes.getters.isSignedIn]) {
      if (store.getters[AuthTypes.getters.hasSubscription]) {
        routeName = RouteNames.GUIDE
      } else {
        routeName = RouteNames.PROFILE
      }
    } else {
      routeName = RouteNames.SIGN_IN
    }
    console.debug('Redirecting to', routeName)
    next({ name: routeName })
  } else if (showUpgradeDialog) {
    AppUtils.showUpgradeDialog()
  }
}

export default router
export { hasPermissionForRoute }
