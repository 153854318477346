import firebase from 'firebase/compat/app'

export default class FirestoreUtils {
  static convertTimestamps<T extends any>(obj: T): T {
    if (Array.isArray(obj)) {
      return obj.map((item: any) => this.convertTimestamps(item)) as T
    } else if (this.isObject(obj)) {
      if ((obj as any) instanceof firebase.firestore.Timestamp) {
        const unknownObj = obj as unknown
        return ((unknownObj as firebase.firestore.Timestamp).toDate() as unknown) as T
      } else {
        const newObj: any = {}
        for (const prop in obj) {
          const value = (obj as any)[prop]
          if (prop.startsWith('__')) {
            newObj[prop] = value
          } else {
            newObj[prop] = this.convertTimestamps(value)
          }
        }
        return newObj
      }
    } else {
      return obj
    }
  }

  private static isObject(val: any): boolean {
    return typeof val === 'object' && !!val
  }
}
