export default class Utils {
  static assert(expr: any, msg: string) {
    if (!expr) {
      throw new Error('Assertion failed: ' + msg)
    }
  }

  /**
   * Extract digit characters from the given string.
   *
   * @param {String} str The source string.
   * @return {String} String containing only digits from the source string.
   */
  static extractDigits(input: string): string {
    const result = []
    for (let i = 0; i < input.length; i++) {
      const char = input.charAt(i)
      if (char >= '0' && char <= '9') {
        result.push(char)
      }
    }

    return result.join('')
  }

  static delay(duration: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, duration)
    })
  }

  static clamp(value: number, min: number, max: number): number {
    return value < min ? min : value > max ? max : value
  }

  /**
   * Map a number [n] from [source] interval to [target] interval.
   */
  static mapInterval(n: number, source: [number, number], target: [number, number]): number {
    return ((n - source[0]) * (target[1] - target[0])) / (source[1] - source[0]) + target[0]
  }

  /**
   * Linearly interpolate between a and b, where t is between 0 and 1.
   */
  static lerp(a: number, b: number, t: number): number {
    return a + (b - a) * t
  }

  /**
   * Return a random number between min and max, inclusive.
   */
  static random(min: number, max: number): number {
    return Math.random() * (max - min) + min
  }

  static isObject(val: any): val is Object {
    return typeof val === 'object' && !!val
  }

  static typedKeys<T>(obj: T): (keyof T)[] {
    return Object.keys(obj) as (keyof T)[]
  }

  static typedValues<T>(obj: T): T[keyof T][] {
    return Object.values(obj)
  }

  static typedEntries<T>(obj: T): [keyof T, T[keyof T]][] {
    return Object.entries(obj) as [keyof T, T[keyof T]][]
  }

  /**
   * Typescript-specific check that a value is not null, so that the type system can be convinced
   * when filtering out null values from an array.
   */
  static hasValue<T>(value: T | null | undefined): value is T {
    return value !== null && typeof value !== 'undefined'
  }

  static isObjectEmpty(obj?: Object): boolean {
    return !!obj && !Object.keys(obj).length
  }
}
