import RouteNames from '@/ui/router/route-names'
import router from '@/ui/router/router'
import { OnboardingCouponConfig, PaymentSession } from 'shared-entities'
import http from '@/data/api/http'
import { OperationError, OperationResult } from '../api/operation-result'
import ResponseParser from '../api/response-parser'
import PaymentRepository from './payment.repository'
import WindowManager from '@/ui/util/window-manager'

export default class OnboardingRepository {
  /**
   * Activate an onboarding coupon or retrieve a previously activated coupon when the user
   * visits the final screen of the onboarding flow.
   *
   * @returns The onboarding coupon configuration.
   */
  static async activateOnboardingCoupon(): Promise<OperationResult<OnboardingCouponConfig>> {
    try {
      const response = await http.post('/onboarding/coupon')
      const result = ResponseParser.parse<any>(response)
      return result.convertToType(value => {
        return {
          expirationTime: new Date(value.expirationTime),
          resetTime: new Date(value.resetTime),
          plan: value.plan,
          coupon: value.coupon,
        }
      })
    } catch (error) {
      return OperationError.fromError(error)
    }
  }

  /**
   * Initiate the payment flow for the onboarding plan.
   * Does not return in case of success. Browser is redirected to a
   * Stripe page for payment instead.
   */
  static async purchasePlan() {
    try {
      const origin = window.location.origin
      const { href: successHref } = router.resolve({
        name: RouteNames.PAYMENT_RESULT,
        params: { result: 'successful' },
      })
      const { href: cancelHref } = router.resolve({ name: RouteNames.QUESTIONNAIRE_PAYMENT })
      const response = await http.post('/onboarding/purchase', {
        successUrl: `${origin}${successHref}?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${origin}${cancelHref}`,
      })

      const result = ResponseParser.parse<PaymentSession>(response)
      if (result.isSuccessful) {
        return PaymentRepository.handlePaymentSession(result.value)
      }

      return result
    } catch (error) {
      return OperationError.fromError(error)
    }
  }
}
