import debounce from 'lodash/debounce'

const bodyStyle = document.body.style
const docEl = document.documentElement
const body = document.body

export type ScrollListener = (scrollY: number) => void
export type ResizeListener = () => void

interface ScrollToParams {
  smooth: boolean
}

export default class WindowManager {
  private static previousScrollTop: number
  private static scrollListeners: ScrollListener[] = []
  private static resizeListeners: ResizeListener[] = []
  private static debouncedOnResize = debounce(WindowManager.onResize, 100)

  private static disableScrollingCount = 0

  static disableScrolling() {
    if (this.disableScrollingCount === 0) {
      this.previousScrollTop = docEl.scrollTop || body.scrollTop

      this.setScrollbarPadding(window.innerWidth - docEl.clientWidth)

      // See setup.scss for .noscroll class definition
      body.classList.add('noscroll')
      bodyStyle.top = `-${this.previousScrollTop}px`
    }
    this.disableScrollingCount++
  }

  static enableScrolling() {
    this.disableScrollingCount = Math.max(0, this.disableScrollingCount - 1)
    if (this.disableScrollingCount === 0) {
      this.setScrollbarPadding(0)
      body.classList.remove('noscroll')
      body.scrollTop = this.previousScrollTop
      docEl.scrollTop = this.previousScrollTop
      bodyStyle.top = '0'
    }
  }

  private static setScrollbarPadding(value: number) {
    const app = document.querySelector('.app') as HTMLElement
    const header = document.querySelector('.header') as HTMLElement
    const pxValue = value + 'px'
    if (app) {
      app.style.paddingRight = pxValue
    }
    if (header) {
      header.style.paddingRight = pxValue
    }
  }

  static addScrollListener(listener: ScrollListener) {
    const index = this.scrollListeners.indexOf(listener)
    if (index < 0) {
      this.scrollListeners.push(listener)
      if (this.scrollListeners.length === 1) {
        window.addEventListener('scroll', this.onScroll)
      }
    }
  }

  static removeScrollListener(listener: ScrollListener) {
    const index = this.scrollListeners.indexOf(listener)
    if (index >= 0) {
      this.scrollListeners.splice(index, 1)
      if (this.scrollListeners.length === 0) {
        window.removeEventListener('scroll', this.onScroll)
      }
    }
  }

  static scrollToTop(params?: ScrollToParams) {
    this.scrollTo(0, null, params)
  }

  /**
   * Smoothly scroll to the given offset, and invoke callback when the scroll animation is
   * complete.
   * @param y The end scroll offset.
   * @param callback The callback to invoke.
   */
  static scrollTo(y: number, callback?: (() => void) | null, params?: ScrollToParams) {
    const maxY = Math.min(
      y,
      document.documentElement.scrollHeight - document.documentElement.clientHeight
    )

    const windowScrollY = window.scrollY || window.pageYOffset

    if (Math.abs(windowScrollY - maxY) < 1) {
      if (callback) {
        callback()
      }
    } else {
      if (callback) {
        const listenToScrollEnd = (scrollY: number) => {
          if (Math.abs(scrollY - maxY) < 1) {
            callback()
            this.removeScrollListener(listenToScrollEnd)
          }
        }

        this.addScrollListener(listenToScrollEnd)
      }

      if (params && params.smooth == false) {
        window.scroll({ top: maxY })
      } else {
        window.scroll({ top: maxY, behavior: 'smooth' })
      }
    }
  }

  static addResizeListener(listener: ResizeListener) {
    this.resizeListeners.push(listener)
    if (this.resizeListeners.length === 1) {
      window.addEventListener('resize', this.debouncedOnResize)
    }
  }

  static removeResizeListener(listener: ResizeListener) {
    const index = this.resizeListeners.indexOf(listener)
    if (index >= 0) {
      this.resizeListeners.splice(index, 1)
      if (this.resizeListeners.length === 0) {
        window.removeEventListener('resize', this.debouncedOnResize)
      }
    }
  }

  static get scrollY(): number {
    return window.scrollY
  }

  private static onScroll(evt: any) {
    WindowManager.scrollListeners.forEach(listener => listener(window.scrollY))
  }

  private static onResize(evt: any) {
    WindowManager.resizeListeners.forEach(listener => listener())
  }

  static getWindowHeight(): number {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  }

  static getWindowWidth(): number {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  }

  /**
   * Enable the prompt that asks the user whether they really want to refresh or
   * close the browser tab.
   */
  static enableConfirmTabClose() {
    if (window.onbeforeunload) {
      return
    }

    window.onbeforeunload = function(evt) {
      evt = evt || window.event

      const message = 'Your changes may not be saved. Proceed?'
      if (evt) {
        evt.returnValue = message
      }

      return message
    }
  }

  /**
   * Disable the prompt that asks the user whether they really want to refresh or
   * close the browser tab.
   */
  static disableConfirmTabClose() {
    window.onbeforeunload = null
  }

  /**
   * Reload the current browser page.
   */
  static reloadPage() {
    this.disableConfirmTabClose()
    window.location.reload()
  }
}
