/**
 * Represents the currency rates used to calculate expenses in different currencies.
 */
export interface CurrencyRates {
  /** The ID of the base currency. */
  base: string
  /** The date specified by the data provider. */
  date: Date
  /** The date at which the data was retrieved on our end. */
  retrievedOn: Date
  /** The mapping currency code -> ratio relative to the base currency */
  rates: { [code: string]: number }
}

export class CurrencyRatesUtils {
  /**
   * Convert the object retrieved from the data provider into [CurrencyRates].
   */
  static fromObject(object: any, retrievedOn: Date = new Date()): CurrencyRates {
    return {
      base: object.base,
      date: new Date(object.date),
      retrievedOn,
      rates: object.rates,
    }
  }

  /**
   * Get the milliseconds since Epoch of the time when the passed [currencyRates] were
   * retrieved from the data provider.
   *
   * Return 0 if the required field does not exist.
   */
  static getRetrievedTime(currencyRates: CurrencyRates): number {
    if (currencyRates.retrievedOn) {
      const retrievedOn = currencyRates.retrievedOn as any
      if (retrievedOn.toMillis) {
        return retrievedOn.toMillis()
      } else if (retrievedOn.getTime) {
        return retrievedOn.getTime()
      }
    }
    return 0
  }
}
