import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCategory from '@/data/point-calculator/point-category'

export default class WorkExperiencePointCalculator {
  static calculate(profile: EditableProfile): PointCategory {
    return {
      title: 'label.workExp',
      points: this.points(profile),
      maxPoints: this.maxPoints(profile),
    }
  }

  static calculateForSpouse(profile: EditableProfile): PointCategory {
    return {
      title: 'label.workExp',
      points: this.pointsForSpouse(profile),
      maxPoints: this.maxPointsForSpouse(profile),
    }
  }

  private static points(profile: EditableProfile): number {
    if (profile.workExp) {
      const score = this.findWorkExperienceScore(profile.workExp)
      if (score) {
        return profile.hasSpouse ? score.pointsWithSpouse : score.pointsWithoutSpouse
      }
    }

    return 0
  }

  private static maxPoints(profile: EditableProfile): number {
    return profile.hasSpouse ? 70 : 80
  }

  private static pointsForSpouse(profile: EditableProfile): number {
    if (profile.hasSpouse) {
      const score = this.findWorkExperienceScore(profile.spouse.workExp)
      if (score) {
        return score.pointsForSpouse
      }
    }
    return 0
  }

  private static maxPointsForSpouse(profile: EditableProfile): number {
    return profile.hasSpouse ? 10 : 0
  }

  private static findWorkExperienceScore(workExperience: number): WorkExperienceScore | null {
    let score: WorkExperienceScore | null = null
    for (let i = 0; i < WORK_EXPERIENCE_SCORES.length; i++) {
      const current = WORK_EXPERIENCE_SCORES[i]
      if (workExperience >= current.minYears) {
        score = current
      }
    }
    return score
  }
}

export interface WorkExperienceScore {
  minYears: number
  pointsWithSpouse: number
  pointsWithoutSpouse: number
  pointsForSpouse: number
}

export const WORK_EXPERIENCE_SCORES: WorkExperienceScore[] = [
  { minYears: 1, pointsWithSpouse: 35, pointsWithoutSpouse: 40, pointsForSpouse: 5 },
  { minYears: 2, pointsWithSpouse: 46, pointsWithoutSpouse: 53, pointsForSpouse: 7 },
  { minYears: 3, pointsWithSpouse: 56, pointsWithoutSpouse: 64, pointsForSpouse: 8 },
  { minYears: 4, pointsWithSpouse: 63, pointsWithoutSpouse: 72, pointsForSpouse: 9 },
  { minYears: 5, pointsWithSpouse: 70, pointsWithoutSpouse: 80, pointsForSpouse: 10 },
]
