import { plainToClass } from 'class-transformer'
import { ClassType } from 'class-transformer/ClassTransformer'
import isNil from 'lodash/isNil'

import { Response } from './http'
import { OperationResult, OperationError, OperationSuccess } from './operation-result'

/**
 * Handles HTTP response parsing.
 */
export default class ResponseParser {
  /**
   * Parse an HTTP response and return either an OperationSuccess with parsed value,
   * or an OperationError with parsed error title and message.
   * @param response The HTTP response
   * @param cls The typescript class to which the response should be mapped.
   */
  static parse<T>(response: Response, cls?: ClassType<T>): OperationResult<T> {
    return this.doParse(response, cls) as OperationResult<T>
  }

  static parseArray<T>(response: Response, cls?: ClassType<T>): OperationResult<T[]> {
    return this.doParse(response, cls) as OperationResult<T[]>
  }

  private static doParse<T>(response: Response, cls?: ClassType<T>): OperationResult<T | T[]> {
    const data = response.data

    if (isNil(data) || typeof data !== 'object') {
      console.log(response)
      return new OperationError({ errorMessage: 'Invalid response' })
    } else {
      if (response.status >= 200 && response.status < 300) {
        const content = data.content
        if (cls && !isNil(content)) {
          const value = plainToClass(cls, content)
          return new OperationSuccess(value)
        } else {
          return new OperationSuccess(content as T)
        }
      }

      return this.parseError(response)
    }
  }

  private static parseError<T>(response: Response): OperationError<T> {
    const data = response.data
    const errorTitle = data.errorTitle
    const errors = data.errors
    const errorMessage = errors != null ? errors.join('\n') : null

    return new OperationError({
      errorTitle: errorTitle,
      errorMessage: errorMessage,
      statusCode: response.status,
    })
  }
}
