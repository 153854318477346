
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { StepViewItem, StepViewSubItem, StepViewUpdate } from './step-view-item'

interface SubItem extends StepViewSubItem {
  isExpanded: boolean
}

@Component
export default class AppStepView extends Vue {
  @Prop({ type: Object, required: true })
  step!: StepViewItem

  expandedStates: { [key: number]: boolean } = {}

  private get subItems(): SubItem[] {
    return this.step.subItems as SubItem[]
  }

  get progress(): number {
    let count = 0
    for (let i = 0; i < this.subItems.length; i++) {
      if (this.subItems[i].isChecked) {
        count++
      }
    }
    return Math.round((count / this.subItems.length) * 100)
  }

  beforeMount() {
    this.onStepChanged()
  }

  @Watch('step')
  private onStepChanged() {
    this.step.subItems.forEach(subItem => {
      if (!this.expandedStates[subItem.id]) {
        Vue.set(this.expandedStates, subItem.id, false)
      }
    })
  }

  onSubItemToggled(subItem: StepViewSubItem, value: boolean) {
    const update: StepViewUpdate = {
      stepId: this.step.id,
      subItemId: subItem.id,
      value: value,
    }
    this.$emit('toggle-sub-item', update)
  }

  toggleSubItem(subItem: SubItem) {
    this.expandedStates[subItem.id] = !this.expandedStates[subItem.id]
  }
}
