
import FormattingUtils from '@/common/util/formatting-utils'
import PaymentRepository from '@/data/repository/payment.repository'
import { Plan, PlanUpdatePreview, PlanUtils } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class PlanUpgradePreviewDialog extends Vue {
  @Prop({ required: true })
  productTitle!: string
  @Prop({ required: true })
  plan!: Plan
  @Prop({ required: true })
  preview!: PlanUpdatePreview

  isLoading = false

  get planTitle(): string {
    return `${this.productTitle} (${this.$ts(PlanUtils.getIntervalTitle(this.plan))})`
  }

  get cost(): string {
    return FormattingUtils.formatAmount(this.preview.prorationCost)
  }

  get cardBrandName(): string {
    const cardDetails = this.preview.cardDetails
    if (cardDetails) {
      return FormattingUtils.capitalize(cardDetails.brand)
    } else {
      return ''
    }
  }

  async upgradePlan(): Promise<any> {
    this.isLoading = true
    this.$dialog.setCancelable(false)
    const result = await PaymentRepository.updateSubscription(
      this.plan.id,
      this.preview.prorationDate
    )
    this.isLoading = false
    this.$dialog.setCancelable(true)

    if (result.isSuccessful) {
      this.$snackbar.show({ message: `You have upgraded your plan to ${this.planTitle}` })
      this.dismiss()
    } else {
      this.$snackbar.show({ message: result.errorMessage })
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
