import AuthRepository from '../repository/auth.repository'
import { AppPermissions, Claims, SubscriptionClaim, Permission } from 'shared-entities'

/**
 * Utilities for checking whether various application features are available to the current user.
 */
export default class PermissionChecker {
  /** Return true if the current user has the given permission */
  static async check(permission: Permission): Promise<boolean> {
    const subscriptionClaim = await this.getSubscriptionClaim()
    return subscriptionClaim === 'notAuthenticated'
      ? false
      : AppPermissions.check(subscriptionClaim, permission)
  }

  /** Return true if the current user has AT LEAST ONE of the given permissions. */
  static async checkSome(...permissions: Permission[]): Promise<boolean> {
    const subscriptionClaim = await this.getSubscriptionClaim()
    return subscriptionClaim === 'notAuthenticated'
      ? false
      : permissions.some(it => AppPermissions.check(subscriptionClaim, it))
  }

  /** Return true if the current user has ALL of the given permissions. */
  static async checkEvery(...permissions: Permission[]): Promise<boolean> {
    const subscriptionClaim = await this.getSubscriptionClaim()
    return subscriptionClaim === 'notAuthenticated'
      ? false
      : permissions.every(it => AppPermissions.check(subscriptionClaim, it))
  }

  /**
   * For each permission in the given list, check if the current user
   * has that permission, and return a list of boolean results.
   */
  static checkList(permissions: Permission[]): Promise<{ [key: string]: boolean }> {
    return this.getSubscriptionClaim().then(claim => {
      const result: { [key: string]: boolean } = {}
      if (claim !== 'notAuthenticated') {
        permissions.forEach(it => {
          result[it] = AppPermissions.check(claim, it)
        })
      }
      return result
    })
  }

  private static async getClaims(): Promise<Claims | null> {
    const user = await AuthRepository.asyncUser()
    if (user) {
      return user.getClaims()
    }
    return null
  }

  private static async getSubscriptionClaim(): Promise<
    SubscriptionClaim | 'notAuthenticated' | null
  > {
    const claims = await this.getClaims()
    if (claims) {
      return claims.subscription || null
    }
    return 'notAuthenticated'
  }
}
