import { Store } from 'vuex'
import { Mutations, Actions, Getters, ModuleWrapper } from '../util/store-framework'
import StoreUtils from '../util/store-utils'
import LocalStorage from '@/data/util/local-storage'
import PoolDataRepository from '@/data/repository/pool-data.repository'
import { ProfileTypes } from './profile.module'

const types = {
  mutations: {
    setTargetPoints: 'setTargetPoints',
  },
  actions: {},
  getters: {},
}

export const PointImprovementGuideTypes = StoreUtils.exportTypes('pointImprovementGuide', types)
const { mutations, actions, getters } = types

export class PointImprovementGuideState {
  targetPoints = 0
}

class PointImprovementGuideMutations extends Mutations<PointImprovementGuideState> {
  [mutations.setTargetPoints](state: PointImprovementGuideState, targetPoints: number) {
    state.targetPoints = targetPoints
    saveTargetPoints(state)
  }
}

class PointImprovementGuideActions extends Actions<PointImprovementGuideState> {}

class PointImprovementGuideGetters extends Getters<PointImprovementGuideState> {}

class PointImprovementGuideModule extends ModuleWrapper<PointImprovementGuideState> {
  state = new PointImprovementGuideState()
  mutations = new PointImprovementGuideMutations()
  actions = new PointImprovementGuideActions()
  getters = new PointImprovementGuideGetters()

  async init(store: Store<any>) {
    const savedTargetPoints = LocalStorage.getItem(LS_KEY_IMPROVEMENT_GUIDE_TARGET_POINTS) as number
    if (typeof savedTargetPoints === 'number') {
      store.commit(PointImprovementGuideTypes.mutations.setTargetPoints, savedTargetPoints)
    } else {
      try {
        const latestRound = await PoolDataRepository.getLatestRound()
        const targetPoints = Math.max(
          latestRound.lowestScore,
          store.getters[ProfileTypes.getters.totalPoints]
        )

        store.commit(PointImprovementGuideTypes.mutations.setTargetPoints, targetPoints)
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export default new PointImprovementGuideModule()

///////////////////////////////////////////////////////////////////////////////////////////////////

const LS_KEY_IMPROVEMENT_GUIDE_TARGET_POINTS = 'app_improvementGuideTargetPoints'

function saveTargetPoints(state: PointImprovementGuideState) {
  LocalStorage.putItem(LS_KEY_IMPROVEMENT_GUIDE_TARGET_POINTS, state.targetPoints)
}
