
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PointCalculator, { PointCalculatorResult } from '@/data/point-calculator/point-calculator'
import PointCategory from '@/data/point-calculator/point-category'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import QuestionnaireResultPointsCard from './QuestionnaireResultPointsCard.vue'
import QuestionnaireResultPlans from './QuestionnaireResultPlans.vue'

/**
 * The main content of the questionnaire results screen.
 */
@Component({
  components: {
    QuestionnaireResultPointsCard,
    QuestionnaireResultPlans,
  },
})
export default class QuestionnaireResultContainer extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  totalPoints: number = 0
  maxPoints: number = 1200

  categoryA!: PointCategory
  categoryB!: PointCategory
  categoryC!: PointCategory
  categoryD!: PointCategory

  beforeMount() {
    const points = PointCalculator.calculatePoints(this.profile)
    this.totalPoints = points.totalPoints
    this.categoryA = points.categoryA
    this.categoryB = points.categoryB
    this.categoryC = points.categoryC
    this.categoryD = points.categoryD
  }
}
