
import ResponsiveUtils from '@/ui/util/responsive-utils'
import WindowManager from '@/ui/util/window-manager'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

export const DIALOG_ANIMATION_DURATION = 200

/**
 * A bottom-sheet-like dialog component.
 *
 * @param {Boolean} value Whether the dialog is shown or not.
 *
 * Slots:
 *    default - The contents to be rendered.
 */
@Component
export default class AppDialog extends Vue {
  @Prop(Boolean)
  value!: boolean
  @Prop({ type: Boolean, default: true })
  cancelable!: boolean
  @Prop({ type: Boolean, default: false })
  large!: boolean
  @Prop({ type: [String, Number] })
  width?: string | number
  @Prop({ type: Boolean, default: false })
  noPadding!: boolean

  containerStyle = {
    display: 'none',
  }

  dialogStyle = {
    height: 'auto',
  }

  isPhone = false

  private scrollingDisabled = false

  created() {
    WindowManager.addResizeListener(this.onResize)
    this.isPhone = ResponsiveUtils.isPhone()
  }

  beforeDestroy() {
    this.enableScrolling()
    WindowManager.removeResizeListener(this.onResize)
  }

  get transitionName(): string {
    return this.isPhone && this.large ? 'bottom-sheet' : 'dialog'
  }

  get bodyStyle() {
    const style: any = {}
    if (this.width) {
      const width = `${this.width}px`
      style.minWidth = width
      style.maxWidth = width
    }

    if (this.noPadding) {
      style.padding = 0
    }

    return style
  }

  onResize() {
    this.isPhone = ResponsiveUtils.isPhone()
  }

  @Watch('value')
  private onValueChanged(val: boolean) {
    if (val) {
      this.onShow()
    } else {
      this.hideDialog()
    }
  }

  private onShow() {
    this.disableScrolling()
    this.containerStyle.display = 'block'
    this.$nextTick(() => {
      const body = this.$refs.body as HTMLElement
      const heightString = getComputedStyle(body).height
      if (heightString) {
        const height = parseInt(heightString, 10)
        this.dialogStyle.height = `${height + 3}px`
      }
    })
  }

  dismiss(evt: Event) {
    evt.stopImmediatePropagation()
    if (this.cancelable) {
      this.forceDismiss()
    }
  }

  forceDismiss() {
    this.$emit('input', false)
    this.hideDialog()
  }

  private hideDialog() {
    setTimeout(() => {
      this.containerStyle.display = 'none'
      this.dialogStyle.height = 'auto'
      this.enableScrolling()
    }, DIALOG_ANIMATION_DURATION)
  }

  private enableScrolling() {
    if (this.scrollingDisabled) {
      WindowManager.enableScrolling()
      this.scrollingDisabled = false
    }
  }

  private disableScrolling() {
    if (!this.scrollingDisabled) {
      WindowManager.disableScrolling()
      this.scrollingDisabled = true
    }
  }
}
