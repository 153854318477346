
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import QuestionnaireButton from '../component/QuestionnaireButton.vue'

interface Option {
  years: number
  label: string
}

@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireCanadianEducation extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile

  options: Option[] = [
    { years: 0, label: 'None' },
    { years: 2, label: 'Credential of one or two year' },
    {
      years: 3,
      label:
        "Credential of three years or longer (Bachelor's, Master's, Professional, or Doctoral (PhD) degree)",
    },
  ]

  isOptionSelected(option: Option): boolean {
    return this.profile.canadianEducation === option.years
  }

  onOptionSelected(option: Option) {
    this.profile.canadianEducation = option.years
    this.$emit('submit')
  }
}
