import { Subscription, SubscriptionStatus } from './subscription.entity'

export class SubscriptionUtils {
  /**
   * Return true if the subscription status is terminal and the corresponding
   * subscription cannot be recovered.
   */
  static isStatusTerminal(status: SubscriptionStatus): boolean {
    return status === 'canceled' || status === 'unpaid'
  }

  /**
   * Return true if updating the given subscription is allowed.
   */
  static canUpdateSubscription(sub: Subscription | null): boolean {
    return sub
      ? (sub.status === 'active' || sub.status === 'trialing') && !sub.cancelAtPeriodEnd
      : false
  }

  /**
   * Return true if the customer can cancel the given subscription.
   * Return false if the passed subscription is null.
   */
  static canCancelSubscription(sub: Subscription | null): boolean {
    return sub ? sub.status === 'active' && !sub.cancelAtPeriodEnd : false
  }

  /**
   * Return true if the customer can restore the given subscription.
   * Return false if the passed subscription is null.
   */
  static canRestoreSubscription(sub: Subscription | null): boolean {
    return sub ? sub.status === 'active' && sub.cancelAtPeriodEnd : false
  }

  /**
   * Return true if the subscription is active and we should give the user
   * access to features that the subscription's product provides.
   */
  static isSubscriptionActive(sub: Subscription | null): boolean {
    return sub
      ? sub.status && !this.isStatusTerminal(sub.status) && sub.status !== 'past_due'
      : false
  }
}
