
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import { EDUCATION_LEVEL_OPTIONS } from '@/data/point-calculator/categories/core/education-point-calculator'
import { EducationLevel } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import QuestionnaireButton from '../component/QuestionnaireButton.vue'

@Component({
  components: {
    QuestionnaireButton,
  },
})
export default class QuestionnaireEducation extends Vue {
  @Prop({ required: true })
  profile!: EditableProfile
  @Prop({ required: true })
  title!: string

  educationLevels = EDUCATION_LEVEL_OPTIONS

  isOptionSelected(option: EducationLevel): boolean {
    const educationLevel = this.profile.education
    return educationLevel === option
  }

  onEducationLevelSelected(educationLevel: EducationLevel) {
    this.profile.education = educationLevel
    this.$emit('submit')
  }
}
