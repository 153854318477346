export class ImmigrationTime {
  readonly maxYears: number

  private constructor(years: number) {
    this.maxYears = years
  }

  /** Rendered as 1-2 years in the questionnaire */
  static TWO = new ImmigrationTime(2)
  /** Rendered as 3-4 years in the questionnaire */
  static FOUR = new ImmigrationTime(4)
}
