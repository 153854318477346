import { ModuleTypes } from './store-framework'

export default class StoreUtils {
  static exportTypes<T extends ModuleTypes>(prefix: string, types: T): T {
    const clone = this.cloneTypes(types)
    this.addPrefix(prefix, clone)
    return clone
  }

  static cloneTypes<T extends ModuleTypes>(types: T): T {
    return {
      mutations: Object.assign({}, types.mutations),
      actions: Object.assign({}, types.actions),
      getters: Object.assign({}, types.getters),
    } as T
  }

  private static addPrefix(prefix: string, types: ModuleTypes) {
    const { mutations, actions, getters } = types
    this.addPrefixToRecord(prefix, mutations)
    this.addPrefixToRecord(prefix, actions)
    this.addPrefixToRecord(prefix, getters)
  }

  private static addPrefixToRecord(prefix: string, record?: Record<string, string>) {
    if (record) {
      for (const key in record) {
        record[key] = `${prefix}/${record[key]}`
      }
    }
  }
}
