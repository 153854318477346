import dayjs, { Dayjs } from 'dayjs'
import PointCategory from '@/data/point-calculator/point-category'

export default class FormattingUtils {
  /**
   * Format the given phone number to be displayed to the user.
   * @param {String} phoneNumber The phone number to format
   */
  static formatPhoneNumber(phoneNumber: string): string {
    if (phoneNumber && phoneNumber.length === 11) {
      const first = phoneNumber[0]
      const group1 = phoneNumber.substring(1, 4)
      const group2 = phoneNumber.substring(4, 7)
      const group3 = phoneNumber.substring(7)

      return `+${first} (${group1}) ${group2} ${group3}`
    }
    return phoneNumber
  }

  static formatDate(date: Dayjs | Date | string): string {
    date = date instanceof Date || typeof date === 'string' ? dayjs(date) : date
    return date.format('DD MMMM YYYY')
  }

  static formatDateTime(date: Dayjs | Date | string): string {
    date = date instanceof Date || typeof date === 'string' ? dayjs(date) : date
    return date.format('DD MMMM YYYY, HH:mm')
  }

  static formatDateWithTimeZone(date: Dayjs | Date | string): string {
    date = date instanceof Date || typeof date === 'string' ? dayjs(date) : date
    return date.format('DD MMMM YYYY [at] HH:mm, UTCZ')
  }

  static formatCategoryPoints(category: PointCategory): string {
    return `${category.points} of ${category.maxPoints}`
  }

  static formatAmount(amount: number): string {
    return `$${(amount / 100).toFixed(2)}`
  }

  private static trailingZerosPattern = /(0+$)|(\.0+$)/g

  static formatNumeric(value: number | undefined | null): string {
    if (value === null || typeof value === 'undefined') {
      return '0'
    }

    const fractionalPart = value * 100 - Math.floor(value) * 100
    const absAmount = Math.abs(value)

    let formatted: string
    if (fractionalPart > 0) {
      formatted = value.toFixed(2)
    } else {
      formatted = value.toFixed(0)
    }

    const sign = value < 0 ? '-' : ''

    if (absAmount > 9999) {
      const separatorChar = ' '
      const result = formatted.split('')
      const startIndex = fractionalPart > 0 ? formatted.length - 3 : formatted.length
      for (let i = startIndex - 3; i >= 0; i -= 3) {
        if (i > 0) {
          result.splice(i, 0, separatorChar)
        }
      }

      formatted = result.join('')
    }

    if (formatted.indexOf('.') >= 0) {
      formatted = formatted.replace(this.trailingZerosPattern, '')
    }

    return sign + formatted
  }

  static capitalize(str: string): string {
    if (str.length > 1) {
      return str[0].toUpperCase() + str.substring(1)
    } else if (str.length > 0) {
      return str.toUpperCase()
    } else {
      return str
    }
  }
}
