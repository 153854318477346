import { LanguageLevel } from './language-level'
import { LanguageTestScoresUtils } from './language-test-scores.utils'

export class LanguageLevelUtils {
  static create(): LanguageLevel {
    return {
      NONE: null,
      CELPIP: LanguageTestScoresUtils.create(),
      IELTS: LanguageTestScoresUtils.create(),
      TEF: LanguageTestScoresUtils.create(),
      TCF: LanguageTestScoresUtils.create(),
    }
  }
}
