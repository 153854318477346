import GalleryImage from './gallery-image'
import { GalleryPlugin } from './root-gallery-interface'

class RootGalleryController implements GalleryPlugin {
  private component: GalleryPlugin | null = null

  bind(component: GalleryPlugin) {
    if (this.component && this.component !== component) {
      throw new Error('Root gallery already bound')
    }
    this.component = component
  }

  unbind(component: GalleryPlugin) {
    if (this.component === component) {
      this.component = null
    } else {
      throw new Error('Invalid component supplied in unbind() call')
    }
  }

  open(
    images: GalleryImage[],
    getThumbBoundsFn: (index: number) => { x: number; y: number; w: number },
    index?: number
  ) {
    if (this.component) {
      this.component.open(images, getThumbBoundsFn, index)
    }
  }
}

export default new RootGalleryController()
