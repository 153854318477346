import colors from '@/ui/styles/exports/colors.scss'

export default class ColorUtils {
  /**
   * Return a hex color string from a generic color string, which can be
   * already in hex format or a named color string.
   */
  static hexColor(color: string): string {
    if (color.startsWith('#')) {
      return color
    } else {
      return this.namedColor(color)
    }
  }

  static namedColor(name: string): string {
    return colors[name]
  }
}
