/** Canadian provinces */

export enum ProvinceId {
  Ontario = 'Ontario',
  BritishColumbia = 'BritishColumbia',
  Alberta = 'Alberta',
  Manitoba = 'Manitoba',
  Saskachewan = 'Saskachewan',
  Winnipeg = 'Winnipeg',
  NovaScotia = 'NovaScotia',
  NewfoundlandAndLabrador = 'NewfoundlandAndLabrador',
  NewBrunswick = 'NewBrunswick',
  PrinceEdwardIsland = 'PrinceEdwardIsland',
}

export interface Province {
  id: ProvinceId
  name: string
}

export const ALL_PROVINCES: Province[] = [
  { id: ProvinceId.Ontario, name: 'Ontario' },
  { id: ProvinceId.BritishColumbia, name: 'British Columbia' },
  { id: ProvinceId.Alberta, name: 'Alberta' },
  { id: ProvinceId.Manitoba, name: 'Manitoba' },
  { id: ProvinceId.Saskachewan, name: 'Saskachewan' },
  { id: ProvinceId.Winnipeg, name: 'Winnipeg' },
  { id: ProvinceId.NovaScotia, name: 'Nova Scotia' },
  { id: ProvinceId.NewfoundlandAndLabrador, name: 'Newfoundland and Labrador' },
  { id: ProvinceId.NewBrunswick, name: 'New Brunswick' },
  { id: ProvinceId.PrinceEdwardIsland, name: 'Prince Edward Island' },
]
