import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import Program from '@/data/entity/programs/program.entity'
import ProgramEligibility from '@/data/entity/programs/program-eligibility.entity'
import ProgramId from '@/data/entity/programs/program-id.entity'
import EligibilityCriterion from '@/data/entity/programs/eligibility-criterion.entity'
import EligibilityCriterionResult from '@/data/entity/programs/eligibility-criterion-result.entity'
import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'
import { LanguageTestScores, NocGroup } from 'shared-entities'
import ProofOfFundsCalculator from '../proof-of-funds-calculator'
import EligibilityCalculatorUtils from '../utils/eligibility-calculator-utils'

const languageCriterion: EligibilityCriterion = {
  title: 'language',
  description:
    'English or French skills' +
    '<ul><li>CLB 5 for speaking and listening</li>' +
    '<li>CLB 4 for reading and writing</li></ul>',
}

const workExpTypeCriterion: EligibilityCriterion = {
  title: 'workExpType',
  description: 'Canadian or foreign experience in a skilled trade under key groups of NOC B',
}

const workExpAmountCriterion: EligibilityCriterion = {
  title: 'workExpAmount',
  description: 'Two years within last 5 years (either combination of full-time or part-time work)',
}

const jobOfferCriterion: EligibilityCriterion = {
  title: 'jobOfferOrCert',
  description:
    'Required:' +
    '<ul><li>A valid job offer of full-time employment for a total period of at least 1 year or</li>' +
    '<li>a certificate of qualification in that skilled trade issued by a Canadian provincial, territorial or federal authority</li></ul>',
}

export const FST_PROGRAM: Program = {
  id: ProgramId.FST,
  title: 'Federal Skilled Trades Program',
  shortTitle: 'FST',
  description:
    'The Federal Skilled Trades Program is for skilled workers who want to become permanent residents based on being qualified in a skilled trade.',
  link:
    'https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/skilled-trades.html',
  eligibilityCriteria: [
    languageCriterion,
    workExpTypeCriterion,
    workExpAmountCriterion,
    { ...jobOfferCriterion, title: 'jobOffer' },
  ],
}

export default class FstEligibility {
  static calculate(profile: EditableProfile): ProgramEligibility {
    const criteria: EligibilityCriterionResult[] = [
      this.workExpAmount(profile),
      this.jobOfferOrCertificate(profile),
      this.language(profile),
      ProofOfFundsCalculator.calculate({ profile, programId: ProgramId.FST }),
    ]

    return {
      program: FST_PROGRAM,
      status: EligibilityCalculatorUtils.calculateProgramEligibility(criteria),
      criteria,
    }
  }

  private static workExpAmount(profile: EditableProfile): EligibilityCriterionResult {
    return {
      criterion: workExpAmountCriterion,
      status:
        profile.totalWorkExp >= 2 && profile.eligibility.fst.hasWorkExp
          ? EligibilityStatus.ELIGIBLE
          : EligibilityStatus.NOT_ELIGIBLE,
    }
  }

  private static jobOfferOrCertificate(profile: EditableProfile): EligibilityCriterionResult {
    return {
      criterion: jobOfferCriterion,
      status:
        this.isJobOfferValid(profile) || profile.hasCert
          ? EligibilityStatus.ELIGIBLE
          : EligibilityStatus.NOT_ELIGIBLE,
    }
  }

  private static isJobOfferValid(profile: EditableProfile): boolean {
    return profile.jobOffer === NocGroup.NOC_B && profile.eligibility.fst.hasJobOffer
  }

  private static language(profile: EditableProfile): EligibilityCriterionResult {
    let status = EligibilityStatus.NOT_ELIGIBLE
    const firstLangScores = profile.getFirstLanguageClbScores()
    if (firstLangScores && this.checkLanguageScores(firstLangScores)) {
      status = EligibilityStatus.ELIGIBLE
    } else {
      const secondLangScores = profile.getSecondLanguageClbScores()
      if (secondLangScores && this.checkLanguageScores(secondLangScores)) {
        status = EligibilityStatus.ELIGIBLE
      }
    }
    return {
      criterion: languageCriterion,
      status,
    }
  }

  private static checkLanguageScores(scores: LanguageTestScores): boolean {
    return scores.s >= 5 && scores.l >= 5 && scores.w >= 4 && scores.r >= 4
  }
}
