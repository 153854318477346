import Vue from 'vue'

import dialog from './dialog/dialog-plugin'
Vue.use(dialog)

import snackbar from './snackbar/snackbar-plugin'
Vue.use(snackbar)

import gallery from './gallery/gallery-plugin'
Vue.use(gallery)

import i18nExtension from './i18n-extension-plugin'
Vue.use(i18nExtension)
