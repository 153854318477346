import { DirectiveOptions } from 'vue'

const directive = {
  // When the bound element is inserted into the DOM...
  inserted(el, binding) {
    // If directive has bound value
    if (typeof binding.value !== 'undefined' && !binding.value) {
      return
    }
    // Focus the element
    el.focus()
  },
} as DirectiveOptions

export default directive
